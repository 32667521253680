@use 'mobile_header_commons' as *;

@include desktopStyles {
  .main-header.burgermenu-sticky-head {
    .mobile-user {
      display: none;
    }
  }
}

@include tabletStyles {
  $nav-height: 83px;

  #vue-mobile-menu {
    top: 25px;
    height: calc(100vh - 25px);
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    height: 65px;
    background: var(--c_white);

    &.nav-up {
      transform: translate3d(0, -$nav-height, 0);
    }

    .mobile-call-center {
      color: var(--c_primary);
    }
  }
}

@include commonTabletDesktop {
  .mobile-header {
    display: none;

    .mobile-menu-toggle {
      &__text {
        color: var(--c_primary);
      }
    }
  }
}
