@use '../../../base_css/abstracts/abstracts' as *;

.reb-header {
  .ihg-button,
  .ihg-button.logged {
    @include fontSize(12);
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 8px;
    border: none;
    color: var(--c_black_grey_700);
    font-weight: 600;

    .s-icon-ihg {
      margin-right: 8px;

      &::before {
        @include fontSize(24);
        content: '\f2da';
        display: inline-block;
        color: inherit;
        font-weight: 400;
        font-family: iberostar-symbols;
      }
    }

    .before-login,
    .after-login {
      cursor: pointer;
      width: max-content;
    }
  }
}
