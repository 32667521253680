@use '../../base_css/abstracts/abstracts' as *;

@include commonStyles {
  .drop-down-links {
    position: absolute;
    top: calc(100% + 12px);
    left: -7px;
    z-index: 9000;
    display: none;
    padding: 8px 0;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(117, 124, 133, 23%);
    background: var(--c_white);

    &::before {
      position: absolute;
      top: -12px;
      left: 20px;
      z-index: 0;
      content: '';
      width: 35px;
      height: 12px;
      background: url('../../images/icons/fastbooking_select_arrow.svg') no-repeat;
    }

    &.to-top {
      &::before {
        top: auto;
        bottom: -12px;
        transform: rotate(180deg);
        filter: drop-shadow(-1px -2px 1px var(--c_grey_200));
      }
    }

    .drop-down-item {
      position: relative;
      z-index: 1;
      display: block;
      white-space: nowrap;

      &:first-child {
        border-top: none;
      }

      a {
        @include link-base;
        display: block;
        padding: 8px 30px;

        &:hover {
          background: var(--c_blue_green_100);
          text-decoration: none;
        }

        &::before {
          display: none;
        }
      }
    }
  }
}

@include mobileStyles {
  .drop-down-links {
    &::before {
      top: auto;
      bottom: -12px;
      transform: rotate(180deg);
    }
  }
}
