@use 'ihg_modal_commons' as *;

.content {
  &__subtitle-modal-ihg {
    font-size: 18px;
    line-height: 26px;
  }

  &__title-modal-ihg {
    font-size: 32px;
    line-height: 36px;
  }
}
