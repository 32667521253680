@keyframes ring2 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-30deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
