@use '../abstracts/abstracts' as *;
@forward '../../themes/themes.scss';

/* Font Title */

// Theme-masterbrand & theme-beacfront -> SELECTION
@font-face {
  font-family: noe-display-medium;
  src: url('../../../../vendor/assets/fonts/NoeDisplay-Medium.woff2') format('woff2');
  font-display: swap;
}

// Theme-beachfront -> WAVES
@font-face {
  font-family: woodland-bold;
  src: url('../../../../vendor/assets/fonts/PPWoodland-Bold.woff2') format('woff2');
  font-display: swap;
}

// Theme-beachfront -> JOIA
@font-face {
  font-family: bergman-regular;
  src: url('../../../../vendor/assets/fonts/F37Bergman-Regular.woff2') format('woff2');
  font-display: swap;
}

/* Font Body */

// All Themes
@font-face {
  font-family: inter-regular;
  src: url('../../../../vendor/assets/fonts/Inter-Regular.woff2') format('woff2');
  font-display: swap;
}
