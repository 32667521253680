@use 'hotel_big_card_commons' as *;

@include commonTabletDesktop {
  .hotel-big-card {
    display: flex;
    flex: 0 0 100%;
    width: 100%; // IE fix

    .hotel-image {
      flex: 0 0 35%;
      width: 35%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .text-container {
      flex: 0 0 65%;
      width: 65%;
      padding: 24px 0;
      text-align: center;
    }

    .text {
      // this is for IE11
      width: calc(100% - 48px);
      height: 100%;
      padding: 24px;
      margin: 0 auto;
    }

    .t-p {
      margin-bottom: 12px;
    }

    .info-links {
      margin-bottom: 16px;

      .info-link {
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include desktopStyles {
  .hotel-big-card {
    .stars {
      margin-bottom: 22px;
    }
  }
}
