@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';
@use '../../elements/drop_down_links/drop_down_links';

.destination-breadcrumb {
  position: relative;
  z-index: 12;
  background: var(--c_blue_green_100);

  .wrapper {
    position: relative;
  }

  .destination-list {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    overflow: auto hidden;
  }

  .has-children {
    position: static;
    padding: 0 24px;
    border-left: 1px solid var(--c_grey_200);
    line-height: 28px;
    white-space: nowrap;

    .wrapper {
      position: absolute;
      width: auto;
      max-width: none;
      padding: 0;
      margin: 0;
    }

    .d-link {
      display: flex;
    }

    &:first-child {
      border-left: 0;
    }

    .drop-down-links {
      left: auto;
      right: -38px;

      &::before {
        left: auto;
        right: 25px;
      }
    }
  }

  .filter {
    padding: 0 24px;
    border-left: 1px solid var(--c_grey_200);

    &:first-child {
      border-left: 0;
    }
  }

  .b-hotels-services-filter & {
    z-index: 1;
    background: var(--c_white);

    .wrapper {
      max-width: 1200px;
    }

    .sticky {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
