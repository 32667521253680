@use '../../base_css/abstracts/abstracts' as *;
@use '../../shared/internal_nav/internal_nav';
@use '../../shared/hotel_breadcrumb/hotel_breadcrumb';
@use '../../shared/header_sticky_hotel/header_sticky_hotel';
@use '../../shared/hotel_header_intro/hotel_header_intro';

.hotel-header-lite-wrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  margin-top: -110px;

  // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
  &--test-reb-header {
    margin-top: -76px;
  }

  .vue-hotel-header-lite {
    position: absolute;
    top: 0;
    left: 0;
  }

  .vue-hotel-header-lite,
  .hotel-header-lite {
    width: 100%;
    height: 100%;
  }

  .hotel-name {
    display: flex;
    padding: 0 20px;
    margin-bottom: 12px;
    color: var(--c_white);
    text-shadow: 0 2px 2px rgba(0,0,0,22%);

    .t-h1 {
      text-shadow: 1px 1px 10px var(--c_black),1px 1px 10px var(--c_dark_grey);
    }

    .rating-stars {
      &::before {
        color: var(--c_white);
      }
    }
  }
}

.hotel-header-lite {
  &__fastbooking {
    position: relative;
    z-index: 99;
    margin-bottom: 70px;
  }
}

@include tabletStyles {
  .hotel-header-lite-wrapper {
    // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
    &--test-reb-header {
      margin-top: -123px;
    }
  }
}
