@use '../../base_css/abstracts/abstracts' as *;

.vue-submenu,
.reb-main-nav {
  .my-iberostar {
    .panel-content {
      display: flex;
      flex-direction: row;
      padding: 0;

      &--left {
        flex: 0 0 75%;
        padding: 25px 30px 40px;
      }

      &--right {
        flex: 0 0 25%;
        padding: 25px 30px 40px;
        border-left: 1px solid var(--c_grey_200);
      }
    }

    .info-user-container {
      display: flex;
      flex-direction: row;

      .user-image {
        width: 67px;
        height: 67px;
      }

      .user-info-in-navbar {
        width: 100%;
        margin-left: 30px;

        .title {
          margin-bottom: 30px;
        }
      }

      .info-level {
        flex-direction: column-reverse;
        padding: 0;
        border-left: none;

        &--title {
          display: none;
        }

        .description {
          margin-top: 16px;
          font-size: 14px;
        }
      }
    }

    .user-options-menu {
      a {
        &.t-link {
          color: var(--c_black_grey_700);
          line-height: 34px;

          &:hover,
          &:focus {
            color: var(--c_primary);

            &::before {
              transform: translateY(4px);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
