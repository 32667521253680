@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.mobile-panel {
  display: none;

  &.ready {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9020;
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: var(--c_white);
    transition: transform 0.4s ease-out 0.2s;

    &.right {
      left: auto;
      right: -100%;
    }

    &.left {
      left: -100%;
    }

    &.bottom {
      top: auto;
      bottom: -100%;
    }

    &.top {
      top: -100%;
    }

    &.opened,
    &.opened-picker {
      z-index: 89002;

      &.right {
        transform: translate3d(-100%, 0, 0);
      }

      &.left {
        transform: translate3d(100%, 0, 0);
      }

      &.bottom {
        transform: translate3d(0, -100%, 0);
      }

      &.top {
        transform: translate3d(0, 100%, 0);
      }

      &#best-rate-mobile-panel,
      &#best-rate-mobile-panel-form {
        z-index: 9015;
      }
    }

    &.date-picker-wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    .close-mobile-panel {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 10;
      padding: 10px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
