@use 'horizontal_card_commons' as *;

@include commonTabletDesktop {
  .horizontal-card {
    display: grid;
    grid-template-columns: 335px 1fr;
    min-height: 150px;
    margin: 0 0 24px;
    box-shadow: none;
    transition: all 0.2s ease-out;

    .card-image {
      @include image_filter;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: auto;
        min-width: 100%;
        min-height: 100%;
      }

      .swiper-slide {
        @include image_filter;
        overflow: hidden;
      }
    }

    .card-container {
      display: flex;
      padding: 24px;

      .crs_offer_btn {
        margin-top: 15px;
      }
    }

    .text {
      position: relative;
      display: flex;
      flex: 0 1 100%;
      flex-wrap: wrap;
      padding-right: 35px;
      border-right: 1px solid var(--c_cloud);

      > * {
        flex: 0 0 100%;
        align-self: flex-start;

        &.l-external {
          flex: 0 0 auto;
        }
      }

      .info-links {
        display: flex;
        align-self: flex-end;
      }

      .text-header {
        margin-bottom: 15px;
      }

      .stars {
        display: inline-block;
        margin-left: 5px;
      }

      .l-down {
        flex: 0 0 auto;
      }

      .opinions {
        .xsmall {
          @include fontSize(12);
          line-height: 14px;
        }
      }

      a {
        .close {
          display: none;
        }

        &.active {
          span {
            display: none;
          }

          .close {
            display: inline-block;
          }
        }
      }
    }

    .call-to-action {
      flex: 0 0 auto;
      width: calc(260px - 25px);

      &:not(.grand) {
        align-self: flex-end;
        text-align: right;

        html:lang(ru) & {
          width: auto;
          padding-left: 20px;
        }

        .price-cnt {
          display: block;

          &.price-before {
            &.hidden {
              display: none;
            }
          }

          .hidden {
            display: none;
          }
        }

        > div {
          clear: right;

          .hint-cnt {
            @include fontSansSemiBold;
            @include fontSize(12);
            margin-bottom: 10px;
            line-height: 18px;
            white-space: normal;
          }
        }

        .prev-highlight,
        .highlight {
          text-align: right;
        }
      }

      .prev-highlight,
      .highlight {
        text-align: right;

        &.initialized {
          cursor: pointer;
        }
      }

      .prev-type {
        @include fontSansSemiBold;
        @include fontSize(12);
        color: var(--c_carrot);
        line-height: 18px;
      }

      .prev-highlight {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;

        &::after {
          position: absolute;
          left: 0;
          bottom: 10px;
          content: '';
          width: 100%;
          height: 1px;
          background: var(--c_carrot);
        }

        .price,
        .coin {
          @include fontSansSemiBold;
          @include fontSize(12);
          color: var(--c_carrot);
          line-height: 18px;
        }
      }

      .type {
        @include fontSansSemiBold;
        @include fontSize(14);
        display: block;
        line-height: 19px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }
    }

    .bottom-list {
      .s:first-child + .tooltip::after {
        left: 50%;
      }
    }

    &.comparator-card {
      display: block;
      min-height: 130px;

      .card-container {
        grid-template-areas: 'text price button';
        grid-template-columns: 1fr max-content 100px;
      }

      .text {
        grid-area: text;
        padding: 14px 0 5px 14px;

        .text-header {
          margin-bottom: 5px;
        }
      }

      .type {
        font-size: 16px;
        line-height: 16px;
      }

      .hint-cnt {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 16px;
      }

      .badges-and-price {
        grid-area: price;
        padding: 15px 25px 0 0;

        .badges-list {
          display: flex;
          margin-bottom: 20px;

          .badge {
            flex: 0 0 auto;
            margin: 0 5px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .price-cnt {
          width: 100%;
          margin-right: 5px;
          text-align: right;
        }
      }

      .not-available-message {
        ul {
          float: right;
        }
      }

      .call-to-action {
        flex: 0 1 auto;
        width: auto;
      }

      .add-to-comparator {
        @include fontSansBold;
        @include fontSize(12);
        z-index: 10;
        display: flex;
        flex: 0 0 118px;
        grid-area: button;
        background: var(--c_background);
        color: var(--c_primary);
        line-height: 14px;

        span {
          @include icon(circular-plus, before, 'l') {
            width: 100%;
            margin-bottom: 8px;
          }
          display: flex;
          flex: 0 1 100%;
          flex-direction: column;
          align-self: center;
          width: 100%;
          max-width: 114px; /* IE11 */
          padding: 0 14px;
          text-align: center;

          &.selected {
            display: none;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }

      &.selected {
        border-color: var(--c_green);

        .add-to-comparator {
          background: rgba(var(--c_green_rgb), 0.1);
          color: var(--c_green);

          span {
            @include icon(alert-check, before);

            &.normal {
              display: none;
            }

            &.selected {
              display: block;
            }
          }
        }
      }
    }
  }
}

@include desktopStyles {
  .horizontal-card {
    .card-image {
      max-height: 300px;

      img {
        pointer-events: none;
      }
    }
  }
}

@include tabletStyles {
  .horizontal-card {
    grid-template-columns: 252px 1fr;

    .call-to-action {
      .price-cnt {
        &.price-before {
          .s {
            .basic-tooltip {
              left: auto;
              right: -15px;
              transform: translateX(0);

              .tooltip-desc {
                @include fontSize(14);

                .b-discounts-tooltip {
                  .discounts-tooltip-title {
                    margin-bottom: 10px;
                  }

                  .info-discount {
                    margin-bottom: 10px;
                  }
                }
              }

              &.top {
                &::before {
                  left: auto;
                  right: 7px;
                }

                &::after {
                  left: auto;
                  right: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
