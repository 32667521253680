@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.disclaimer {
  position: relative;
  display: flex;
  align-items: center;
  padding: $space4;
  margin: auto;
  border-radius: 7px;
  box-shadow: 0 0 12px 0 var(--c_grey_200);

  &__border {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 90%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background: var(--c_carrot);
  }

  &__content {
    display: flex;
    align-items: center;

    &--icon {
      margin-right: $space3;
      color: var(--c_carrot);
    }

    &--text {
      @include fontSize(14);
    }
  }
}

@include mobileStyles {
  .disclaimer {
    align-items: flex-start;
  }
}
