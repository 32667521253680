@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.header-sticky-hotel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transform: translate(0, -100%);
  width: 100%;
  box-shadow: 0 0 50px 0 rgba(69, 69, 69, 21%);
  transition: 0s ease-out;

  &.sticky {
    transform: translate(0, 0);
    transition: 0.4s ease-out;
  }

  //TODO: PY05649-639: ibercuba
  .info-container.ibercuba {
    .badge.ocean {
      background-color: var(--c_primary);
    }
  }

  .hotel-header-intro {
    .wrapper {
      padding-top: 15px;
      padding-bottom: 14px;
    }
  }

  .sticky-internal-nav {
    background: var(--c_white);

    .internal-nav {
      padding: 10px 0;
      background: var(--c_white);
    }
  }
}
