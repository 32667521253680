@use '../../base_css/abstracts/abstracts' as *;

.user-options-link-wrapper {
  display: flex;
  align-items: center;
  color: var(--c_black_grey_700);
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    color: var(--c_primary);
  }

  .user-image {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    font-size: 12px;

    > img {
      width: 24px;
      height: 24px;
    }
  }

  .person-icon {
    @include fontSize(24);
    display: inline-block;
    margin-right: 4px;
    color: inherit;
  }
}

.main-nav {
  .user-login {
    display: none;
  }
}
