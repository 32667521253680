@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';
@use 'panel_offers';
@use 'panel_bookings';
@use 'panel_my_iberostar';
@use 'panel_last_searches';
@use '../../elements/user_level/user_level';
@use '../../shared/info_user_level/info_user_level';

/* stylelint-disable*/
.vue-submenu,
.reb-main-nav {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 8500;
  width: 100%;
  //max-width: 1200px;
  max-width: 1366px;
  height: 0;
  padding: 0;
  margin: 0 auto;

  &--container {
    position: relative;
    display: flex;
    //max-width: 1071px;
    height: 0;
    margin: 0 0 0 auto;
  }

  .panel-content {
    &--left,
    &--right {
      width: 50%;
    }
  }

  .panel-content {
    display: flex;
    flex: 0 0 100%;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 35px 30px 60px;
    background: var(--c_white);

    &.full {
      flex-direction: column;
    }
  }

  .view-all {
    display: inline-block;
  }

  .panel {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    transition: transform 0.6s ease-out;

    &.active {
      z-index: 2;
      transform: translateY(100%);
    }

    .inner-header {
      position: relative;
      display: flex;
      align-self: flex-start;

      .title {
        &::after {
          position: absolute;
          left: 7px;
          bottom: 0;
          content: '';
          width: calc(100% - 21px);
          height: 1px;
          background: var(--c_cloud);
        }
      }

      .title-container {
        display: flex;
        align-items: center;
        width: 100%;

        &--underlined {
          padding-bottom: $space1;
          border-bottom: 1px solid var(--c_grey_200);
        }

        &--with-link {
          justify-content: space-between;
        }

        .fake-link {
          &:hover {
            &::before {
              display: none;
            }
          }
        }
      }

      .title-container.ibercuba {
        .return-to {
          display: none;
        }

        .t-label {
          padding-left: 0;
          border-left: 0;
        }
      }

      .title {
        color: var(--color-title-font);

        &::after {
          display: none;
        }
      }
    }
  }

  .destinations,
  .hotels {
    .title {
      padding-left: 15px;
      border-left: 1px solid var(--c_grey_200);
    }

    &--list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .continents-and-countries,
  .destinations,
  .hotels {
    display: none;

    &.visible {
      display: block;
    }
  }

  .hotels-and-destinations {
    display: flex;
    flex-direction: column;
    &.ibercuba {
      .panel-content {
        &--right {
          .experiences--list {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }

    .return-to {
      @include icon(arrow-left-fat) {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 12px;
      }
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding-left: 20px;
      margin-right: 15px;
    }

    .panel-content {
      display: flex;

      &--left {
        .continents--list {
          display: flex;
          justify-content: space-between;

          .continent {
            flex: 0 0 33.3%;
            padding-right: 12px;
          }

          .continent-title {
            margin-bottom: 6px;
          }
        }

        .destination-with-lenght,
        .hotel {
          flex: 0 0 33.3%;
          padding-right: 12px;
        }

        .hotel {
          .t-link {
            color: var(--c_black_grey_700);
            line-height: 18px;
          }
        }

        .next-link,
        .hotel-link {
          position: relative;
          cursor: pointer;
          color: var(--c_black_grey_700);
          text-decoration: none;

          &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(9px);
            content: '';
            opacity: 0;
            width: 100%;
            height: 2px;
            background: var(--c_primary);
            transition: transform 0.2s, opacity 0.2s;
            pointer-events: none;
          }

          &:hover,
          &:focus {
            color: var(--c_primary);

            &::before {
              transform: translateY(4px);
              opacity: 1;
            }

            .lenght {
              color: var(--c_primary);
            }
          }
        }

        .country-with-lenght,
        .destination-with-lenght {
          .lenght {
            font-weight: 400;
          }
        }
      }

      &--right {
        .experiences--list {
          display: grid;
          grid-gap: 10px;
          grid-template-columns: 1fr 1fr 1fr;

          .link {
            position: relative;
            overflow: hidden;

            .with-filter {
              position: relative;

              picture {
                img {
                  display: block;
                  width: 100%;
                  object-fit: cover;
                }

                &::after {
                  content: '';
                  height: 140px;
                  background: rgba(0, 0, 0, 0.2);
                  transition: background 0.3s ease-in-out;
                }
              }
            }
            //animation zoom in feature PY04000-759
            .link--container picture img {
              transform: scale(1);
              transition: 0.3s ease-in-out;
            }

            .link--container {
              &:hover {
                picture img {
                  transform: scale(1.3);
                }

                .desc {
                  color: var(--c_primary);
                }
              }
            }

            .content {
              position: absolute;
              bottom: 0;
              width: 95%;
              padding: 8px;
              background-color: white;
              color: var(--c_black_grey_700);

              .desc {
                @include fontSize(20);
                text-align: start;
              }
            }
          }
        }
      }
    }
  }

  /*
    Conservo algo que ya existía con anterioridad pero se lo aplico únicamente
    al submenú de la parte privada de iberostarPRO, ya que en iberostar.com la parte privada
    tiene un rediseño aplicado.
  */

  .iberostar-pro-user-options {
    .link-container {
      .menu_item {
        &::after {
          content: '';
          display: none;
        }

        a {
          &::after {
            content: '';
            display: none;
          }
        }
      }

      a,
      span {
        @include fontSize(14);
        position: relative;
        cursor: pointer;
        margin: 15px 20px 16px 0;
        color: var(--c_primary);
        font-weight: var(--sans-bold);
        line-height: 20px;
        text-decoration: none;

        &::before {
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translateY(9px);
          content: '';
          opacity: 0;
          width: 100%;
          height: 2px;
          background: var(--c_primary);
          transition: transform 0.3s, opacity 0.3s;
          pointer-events: none;
        }

        &:hover,
        &:focus {
          &::before {
            transform: translateY(4px);
            opacity: 1;
          }
        }
      }
    }
  }
}

.vue-submenu-focus {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 17, 51, 0.3);
  transition: all 1s;

  &.active {
    z-index: 8000;
    opacity: 1;
    transition: all 1s;
  }
}

// SPECIFIC STYLES FOR HOTEL PAGE
.hotel-one-landing-page .vue-submenu,
.transparent-header .vue-submenu {
  left: 0 !important;
  right: 0 !important;
  padding-top: 20px;

  .panel {
    transform: translateY(100%) rotate3d(1, 0, 0, 90deg);
    transform-origin: top center;

    .panel-content > * {
      opacity: 0;
      transition: opacity 0.1s ease-out;
    }

    &.active {
      transform: translateY(100%);

      .panel-content > * {
        opacity: 1;
        transition: opacity 0.1s 0.4s ease-out;
      }
    }
  }
}
