@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  .hotel-header-intro {
    background: #f6f6f6;

    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1366px;
      padding-top: 12px;
      padding-bottom: 12px;

      .info-container {
        display: flex;
        flex: 0 1 auto;
        flex-wrap: wrap;
        align-self: center;

        > * {
          flex: 0 0 auto;
          align-self: center;
        }
      }

      .badge {
        margin-right: 8px;
      }

      .t-h5-header,
      .t-h5 {
        @include fontSize(20);
        margin-right: 8px;
        color: var(--c_dark_grey);
        font-family: var(--title-font);

        b {
          margin-right: 4px;
          font-weight: 600;
        }
      }

      .stars {
        position: relative;
        top: -3px;
        display: inline-block;

        .rating-stars::before {
          color: var(--c_dark_grey);
        }
      }

      .inline-name {
        margin-right: 8px;

        .name {
          display: inline-block;
          margin-right: 0;
          font-weight: var(--weight-title-font);

          b {
            font-weight: var(--weight-title-font);
          }
        }
      }

      .price-info {
        flex: 0 0 auto;
        align-self: center;
        padding-bottom: 0;

        .stays-per,
        .pppn {
          display: none;
        }

        .now {
          @include fontSize(14);
          margin-right: 6px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }

        .t-price,
        .price {
          @include fontSize(18);
          font-weight: 600;
          line-height: 26px;
        }

        .t-coin {
          @include fontSize(14);
          font-weight: 600;
          line-height: 20px;
        }
      }

      .btn-primary {
        .price-info {
          .now {
            @include fontSize(14);
            font-weight: 700;
            line-height: 20px;
            letter-spacing: inherit;
            text-transform: none;
          }

          .t-price,
          .t-coin {
            @include fontSize(14);
            font-weight: 700;
            line-height: 20px;
          }
        }
      }
    }
  }
}
