@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  .b-greetings {
    @include icon(user_rounded, before, 'xl') {
      display: block;
      flex: 0 0 auto;
      margin-right: 15px;
      color: var(--c_blue_700);
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    padding: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: var(--c_background);
    text-transform: none;

    &.is-popup {
      position: fixed;
      top: 30px;
      right: 50px;
      z-index: 8502;
      visibility: hidden;
      border-radius: 4px;
      box-shadow: 6px 4px 10px 2px rgba(154, 155, 156, 41%);
    }

    &.start-animation {
      animation-name: greetings-fade-in;
      animation-duration: 5s;
      animation-delay: 5s;
    }

    .greetings-link {
      display: block;
      color: var(--c_primary);
      font-weight: bold;
      font-size: 16px;
      font-family: var(--font-serif);
      line-height: 16px;
      letter-spacing: 0;
    }

    .greetings-text {
      flex: 1 0 auto;
    }

    .greetings-title {
      display: block;
      margin-bottom: 10px;
      color: #302B2B;
      font-weight: 600;
      font-size: 18px;
      font-family: var(--font-sans);
      line-height: 1em;
    }

    .greetings-body {
      display: block;
      font-weight: 600;
      font-size: 16px;
      font-family: var(--font-sans);
      line-height: 1em;
    }
  }

  @include media($mobile) {
    .b-greetings.is-popup {
      inset: auto 10px 10px;
      z-index: 9001; // Fastbooking's z-index + 1
      width: calc(100% - 20px);
      min-width: auto;
    }
  }
}
