@use 'ib_swiper_commons' as *;

.ib-swiper {
  &--template-fullscreen {
    // Layer for hover efect
    .layer {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      background: rgba(var(--c_blue_700_rgb), 0.3);
      color: var(--c_white);
      text-align: center;
      text-decoration: none;
      transition: opacity 0.2s ease-in-out;

      &.swiper-fullscreen {
        @include icon(expand, before) {
          display: block;
          margin-bottom: 16px;
          font-size: 51px;
        }
        opacity: 0;
      }

      &.play-video,
      &.play-virtual-tour {
        @include icon('play-circle', before, 'xxl') {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    &:hover {
      .swiper-fullscreen {
        opacity: 1;
      }
    }

    .swiper-exit-fullscreen {
      @include icon(collapse, after, 'l') {
        color: var(--c_white);
      }
    }
  }
}
