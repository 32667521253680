@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.card.room {
  &.prominent-room {
    border-color: #E0245D;
    background: rgba(224, 26, 93, 3%);
  }

  .feature {
    @include fontSansSemiBold;
    @include fontSize(12);
    line-height: 18px;
  }

  .desc {
    padding-bottom: 0;
  }

  .e-image-counter {
    left: 8px;
    right: auto;
  }

  .bottom-list {
    position: relative;
    height: 26px;
    padding-top: 0;
    padding-right: 10px;
    overflow: hidden;

    .feature {
      position: relative;
      padding-left: 32px;

      &::after {
        position: absolute;
        top: -3px;
        left: 0;
      }
    }

    .link-circle {
      position: absolute; // Preguntar a Jandro por qué se borró esto
      top: 0;
      right: 0;
      display: inline-block;
      width: 25px;
      height: 25px;
      border: solid 1px var(--c_primary);
      border-radius: 50%;
      color: var(--c_primary);
      font-size: 11px;
      line-height: 23px;
      text-align: center;
    }
  }

  .text {
    > a {
      display: inline-block;
      flex: 0 0 auto;
    }
  }

  .claim {
    @include fontSerifBold;
    @include fontSize(24);
    color: var(--c_blue_700);
    line-height: 26px;
  }

  .prominent-room-text {
    margin-right: 8px;
    color: #E0245D;

    .s {
      @include fontSize(16);
      vertical-align: text-bottom;
    }
  }

  .info-links {
    margin-top: 20px;
  }

  .hover {
    display: none;
  }
}

.popup-room {
  .block-title {
    margin-bottom: 16px;
  }

  .rooms-services-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: calc(100% + 48px);
    margin-left: -24px;
    background-color: var(--c_blue_green_100);

    li {
      float: none;
      text-align: center;

      span.s,
      span.desc {
        display: block;
        width: 100%;
        margin: 0 auto;
      }

      &::before {
        display: none;
      }
    }
  }

  .more-info {
    margin-top: 24px;
  }

  .t-h3 {
    margin-bottom: 8px;
  }
}
