@use '../abstracts/abstracts' as *;

.form-select {
  @include icon(arrow-down-fat, before, 'xs') {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: var(--c_primary);
  }
  position: relative;
  padding: 8px 30px 8px 10px;

  select {
    position: relative;
    appearance: none;
    cursor: inherit;
    width: 100%;
    padding-right: 20px;
    margin: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  &--no-padding {
    padding: 0;

    &::before {
      right: 0;
    }
  }
}
