@use '../../base_css/abstracts/abstracts' as *;

.main-header{
  .ihg-button {
    @include fontSize(12);
    position: relative;
    cursor: pointer;
    padding: 4px 10px;

    .s-icon-ihg::before {
      @include fontSize(16);
      content: '\f2da';
      display: inline-block;
      font-family: iberostar-symbols;
    }

    &.logged {
      .s-icon-ihg::before {
        color: var(--c_blue_700);
      }

      .after-login {
        text-transform: capitalize;
      }
    }
  }
}
