@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

.badge {
  @include badge;
}

.badges-content,
.badges-list,
.card .badges-list {
  // do not clash with segment clash, apply only to subsegment
  .badges-item .badge,
  .all-badges .badge,
  [data-badge-id] .badge{
    transition-duration: 0.2s;
  }


}

.badges-content {
  .badges-item:not(:last-child) {
    margin-bottom: 40px;
  }

  a {
    @include fontSansBold;
    @include fontSize(14);
    color: var(--c_primary);
    line-height: 20px;
  }

  .block-title {
    display: table;
    line-height: inherit;

    .title {
      display: table-cell;
    }

    .l-external {
      display: table-cell;
      padding-left: 20px;
      white-space: nowrap;
      vertical-align: middle;
    }
  }


}
