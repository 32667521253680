@use '../../abstracts/abstracts' as *;
@forward '../../abstracts/abstracts';

//
// Very
//
*,
*::before,
*::after {
  box-sizing: border-box;
}

//
// Layout and grid
//
.wrapper,
%wrapper {
  width: 100%;
  max-width: $max-width;
  padding: 0 20px;
  margin: 0 auto;

  .half {
    width: 50%;
  }
}

html,
body {
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

// Recovering original burgermenu styles
// for page wrapper. Prone to errors
// without them.
.page-wrapper {
  width: 100%;
  min-width: 320px;
  height: 100%;
}

//
// Layout and grid
//
.row {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

picture {
  position: relative;
  display: block;
  min-height: 1px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  &.loaded {
    min-height: 0;
  }
}

.hidden {
  display: none;
}

//  added this class to display the legal note inside the best price guarantee. We can remove this class if we decide to replace the fancybox with the modal: Ticket PY05650-781 871278 - The MPOG  No abre el doc de MPOG //
.hidden-fancybox {
  display: none;
}

.is-hidden {
  display: none !important;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  border: 0 !important;
  white-space: nowrap !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
}

.invisible {
  visibility: hidden;
}

.sr-only {
  position: absolute;
  left: -9999px;
}

.tablet-only {
  display: none;

  .card.hotel & {
    &.offer-pill {
      display: none;
    }
  }

  &.l-external,
  &.secondary-btn,
  &.btn-primary {
    display: none;
  }
}

.mobile-only,
.mobile-only-flex {
  display: none;

  &.l-external,
  &.secondary-btn,
  &.btn-primary {
    display: none;
  }
}

// Removes padding top from all devices
// if clean layout param is present
.clean-layout {
  padding-top: 0 !important;
}

button {
  padding: 0;
  border: 0;
  background-color: transparent;
}

@include media($tablet_landscape) {
  .desktop-only {
    display: none !important;
  }

  body {
    &.mice-home {
      padding-top: 0;
    }
  }
}
