@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.embedded-video {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--static-position {
    position: static;
  }
}
