@use 'radio_btn_commons' as *;

@include desktopStyles {
  .radio-btn {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
}

@include tabletStyles {
  .radio-btn {
    width: 9px;
    height: 9px;
    margin-left: 8px;
  }
}
