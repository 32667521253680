@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  .breadcrumb-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;

    .social-media-share {
      width: auto;
      padding: 0;
      margin: 0;
      margin-top: 15px;
    }
  }

  .hotel-breadcrumb {
    margin: 15px 0 20px;

    li {
      @include fontSize(12);

      @include icon(arrow-right, before, 'xs') {
        display: inline-block;
        width: 5px;
        margin-right: 5px;
        color: var(--c_grey_200);
      }
      display: inline-block;
      padding-left: 5px;
      margin-right: -0.285em;
      font-weight: 600;
      font-family: var(--font-sans);
      line-height: 18px;

      a {
        @include fontSize(12);
        font-weight: 600;
        line-height: 18px;
      }

      &:first-child {
        padding-left: 0;

        &::before {
          display: none;
        }
      }

      &:last-child {
        @include fontSize(13);
        opacity: 0.8;
        font-weight: 400;
      }
    }
  }

  @include media($mobile) {
    .hotel-breadcrumb {
      display: none;
    }
  }
}
