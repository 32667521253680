@use '../../base_css/abstracts/abstracts' as *;
@use './ihg_button_commons';

.main-header {
  .ihg-button {
    cursor: pointer;
    border: 1px solid var(--c_blue_700);
    color: var(--c_blue_700);

    &.logged {
      background-color: var(--c_white);
    }
  }
}

.transparent-header {
  .ihg-button {
    cursor: pointer;
    border-color: var(--c_white);
    color: var(--c_white);

    .s-icon-ihg::before {
      @include fontSize(16);
      color: var(--c_white);
    }

    &.logged {
      background-color: var(--c_white);

      .s-icon-ihg::before {
        @include fontSize(16);
        color: var(--c_blue_700);
      }

      .after-login {
        color: var(--c_black);
        text-transform: capitalize;
      }
    }
  }
}
