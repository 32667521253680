.accessibility-button {
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  background: var(--c_blue_700);
  color: var(--c_white);

  .s-ico_a11y {
    display: flex;
    align-items: center;
  }

  .accessibility-button-text {
    margin-left: 12px;
    font-weight: 700;
  }
}
