@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

.radio-btn {
  margin-left: 10px;
  border: 1px solid var(--c_white);
  border-radius: 50%;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    background: var(--c_white);
  }
}
