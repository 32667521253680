@use 'mixins' as *;
@use 'variables' as *;
@use 'sass:list';
@use '../../abstracts/mixins' as *;

@font-face {
  font-weight: normal;
  font-family: '#{$icons-font-family}';
  font-style: normal;
  src:
    url('#{$icons-font-path}/#{$icons-font-family}.woff?#{$timestamp}') format('woff'),
    url('#{$icons-font-path}/#{$icons-font-family}.ttf?#{$timestamp}') format('truetype'),
    url('#{$icons-font-path}/#{$icons-font-family}.svg?#{$timestamp}##{$icons-font-family}') format('svg');
  font-display: block;
}

.s {
  @include custom-icon;
}

$icon-sizes: 12 16 20 28 30 60;
$name-sizes: 'xs' 's' 'm' 'l' 'xl' 'xxl';

@each $size in $icon-sizes {
  $i: list.index($icon-sizes, $size);
  .s-#{list.nth($name-sizes, $i)} {
    font-size: #{$size}px;
  }
}

@include commonTabletDesktop {
  $icon-sizes: 12 16 24 32 42 64;
  $name-sizes: 'xs' 's' 'm' 'l' 'xl' 'xxl';

  @each $size in $icon-sizes {
    $i: list.index($icon-sizes, $size);
    .s-#{list.nth($name-sizes, $i)} {
      font-size: #{$size}px;
    }
  }
}

@each $name, $icon in $icons {
  .s-#{"" + $name} {
    &::before {
      content: $icon;
    }
  }
}
