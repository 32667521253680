@use '../../../src/base_css/abstracts/abstracts' as *;

%positioning {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $fake_skeleton_zindex;
  content: '';
  width: 100%;
  height: 100%;
}

.js-loading {
  position: relative;

  &::before {
    @extend %positioning;
    background: var(--c_white);
  }

  &::after {
    @extend %positioning;
    border-radius: 2px;
    background:
      linear-gradient(
        to right,
        transparent 20%,
        var(--c_cloud_hsl_2) 35%,
        var(--c_cloud_hsl_90) 50%,
        var(--c_cloud_hsl_92) 65%,
        transparent 80%
      );
    animation: placeHolderShimmer 2s linear infinite forwards;
  }
}
