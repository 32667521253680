@use 'cards_rooms_commons' as *;

@include commonTabletDesktop {
  .card.room {
    &.with-sash {
      .call-to-action {
        padding-top: 26px;
      }

      .price-before {
        margin-top: -12px;
      }
    }

    .text {
      > a {
        margin-top: 16px;
      }
    }

    .claim {
      margin-bottom: 16px;
    }
  }
}

@include desktopStyles {
  .card.room {
    .card-image {
      background-color: var(--c_black_grey_700);

      img {
        transition: opacity 0.2s ease-in-out;
      }

      .hover {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: var(--c_white);
        transition: opacity 0.2s ease-in-out;

        .s {
          margin-bottom: 16px;
          font-size: 42px;
        }
      }

      &:hover {
        img {
          opacity: 0.8;
        }

        .hover {
          opacity: 1;
        }
      }
    }

    .t-highlight {
      margin-bottom: 16px;
    }

    .bottom-list {
      margin-bottom: 24px;
    }
  }

  .popup-room {
    padding-top: 40px;
    padding-bottom: 40px;

    .block-title {
      margin-bottom: 24px;
    }

    .rooms-services-list {
      padding: 24px 10%;

      li {
        width: 200px;
        padding: 16px 0;
      }
    }
  }
}

@include tabletStyles {
  .card.room {
    margin-bottom: 18px;

    .t-highlight {
      margin-bottom: 8px;
    }

    .bottom-list {
      margin-bottom: 10px;
    }

    .card-container {
      padding: 16px 20px;
    }

    // only for tablet portrait
    @include media($tablet) {
      display: flex;

      // flex-direction: column;
      .card-image {
        position: relative;
        flex: 0 0 200px;
        width: 200px;
      }

      .card-container {
        flex: 1 1 auto;
        width: auto;
        margin-left: 0;
      }

      .text {
        // margin-bottom: 20px;
        padding-right: 0;
        border: none;
      }
    }
  }

  .popup-room {
    .rooms-services-list {
      padding: 16px 24px;

      li {
        width: 200px;
        padding: 16px 0;
      }
    }
  }
}
