@use '../abstracts/abstracts' as *;

@include commonStyles {
  // Estilos comunes
  .t-link,
  %t-link {
    @include fontSansBold;
    @include fontSize(14);
    line-height: 20px;
  }

  .t-link-secondary,
  .t-date,
  .t-small-text {
    @include fontSansSemiBold;
    @include fontSize(12);
    line-height: 18px;
  }

  .t-fb-label {
    @include fontSansSemiBold;
    @include fontSize(12);
    line-height: 18px;
    letter-spacing: 1.54px;
    text-transform: uppercase;
  }

  .t-fb-p {
    @include fontSize(16);
    font-family: var(--font-sans);
    line-height: 24px;
  }

  .t-headband {
    @include fontSansSemiBold;
    @include fontSize(14);
    color: var(--c_black_grey_700);
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .block-title {
    color: var(--c_black_grey_700);
  }

}

@include mobileStyles {
  .t-hero {
    @include fontSerifBold;
    @include fontSize(36);
    line-height: 42px;
  }

  .t-hero-semi {
    @include fontSerifSemiBold;
    @include fontSize(36);
    line-height: 42px;
  }

  .t-points {
    @include fontSansBold;
    @include fontSize(32);
    line-height: 32px;
  }

  .t-h1 {
    @include fontSerifBold;
    @include fontSize(26);
    line-height: 32px;
  }

  .t-h2,
  .block-title {
    @include blockTitle;
    @include fontSize(22);
  }

  // PY06050-3
  // To compensate for the heights of each type of source according to segmentation
  .block-title-header {
    line-height: var(--line-height);
  }

  .t-h3 {
    @include fontSerifBold;
    @include fontSize(20);
    line-height: 26px;
  }

  .t-h5 {
    @include fontSerifSemiBold;
    @include fontSize(20);
  }

  .t-h5-header {
    @include fontSerifSemiBold;
    @include fontSize(18);
    line-height: 22px;
    text-transform: uppercase;
  }

  .t-highlight {
    @include fontSansSemiBold;
    @include fontSize(16);
    line-height: 26px;
  }

  .t-p,
  .t-body,
  %t-p {
    @include fontSize(14);
    line-height: 24px;
  }

  .t-label {
    @include fontSansSemiBold;
    @include fontSize(12);
    line-height: 18px;
    letter-spacing: 1.54px;
    text-transform: uppercase;
  }

  .t-coin,
  .t-price {
    @include fontSansSemiBold;
    @include fontSize(22);
    line-height: 30px;
  }

  .t-price-coin {
    @include fontSansSemiBold;
    @include fontSize(16);
    line-height: 26px;
  }

  .t-price-coin-small,
  .t-link-secondary,
  .t-date,
  .t-small-text {
    @include fontSansSemiBold;
    @include fontSize(12);
    line-height: 18px;
  }
}

@include commonTabletDesktop {
  .t-click-accompaniment {
    @include fontSansSemiBold;
    @include fontSize(18);
    line-height: 18px;
    letter-spacing: 2.25px;
  }

  .t-hero {
    @include fontSerifBold;
    @include fontSize(68);
    line-height: 78px;
  }

  .t-hero-semi {
    @include fontSerifSemiBold;
    @include fontSize(68);
    line-height: 78px;
  }

  .t-points {
    @include fontSansBold;
    @include fontSize(56);
    line-height: 56px;
  }

  .t-h1 {
    @include fontSerifBold;
    @include fontSize(48);
    line-height: 48px;
  }

  .t-h2,
  .block-title {
    @include fontSize(32);
    @include blockTitle;
  }

  // PY06050-3
  // To compensate for the heights of each type of source according to segmentation
  .block-title-header {
    line-height: var(--line-height);
  }

  .t-h3 {
    @include fontSerifBold;
    @include fontSize(24);
    line-height: 28px;
  }

  .t-h5 {
    @include fontSerifSemiBold;
    @include fontSize(20);
  }

  .t-h5-header {
    @include fontSerifSemiBold;
    @include fontSize(20);
    line-height: 20px;
    text-transform: uppercase;
  }

  .t-highlight {
    @include fontSansSemiBold;
    @include fontSize(18);
    line-height: 26px;
  }

  .t-p,
  .t-body,
  %t-p {
    @include fontSize(16);
    line-height: 24px;
  }

  .t-medium-body,
  %t-medium-body {
    @include fontSansSemiBold;
    @include fontSize(14);
    line-height: 20px;
  }

  .t-label {
    @include fontSansSemiBold;
    @include fontSize(14);
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .t-coin {
    @include fontSansBold;
    @include fontSize(26);
    line-height: 36px;
  }

  .t-price {
    @include fontSansSemiBold;
    @include fontSize(36);
    line-height: 42px;
  }

  .t-price-coin {
    @include fontSansSemiBold;
    @include fontSize(18);
    line-height: 26px;
  }

  .t-link,
  %t-link,
  .t-price-coin-small {
    @include fontSansSemiBold;
    @include fontSize(14);
    line-height: 20px;
  }
}
