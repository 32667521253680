@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.static-map-image {
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: 100px;
  aspect-ratio: 16 / 9;
}
