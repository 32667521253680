@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.theme-city.city {
  // TODO: 72338 -> Cuidado al mergear por el proyecto de fastbooking.
  --fastbooking-margin-wrapper: -40px auto 20px;

  /** Generic **/
  --border-radius: var(--s_city_border_radius);
  --text-decoration: var(--s_city_text_decoration);
  --separator-color:  var(--c_grey_200);

  /* Button Primary */
  --btn-background-primary: var(--c_masterbrand_blue_green_500);
  --btn-label-color-primary: var(--c_masterbrand_white);
  --btn-background-primary-hover: var(--c_masterbrand_blue_green_700);
  --btn-label-color-primary-hover: var(--c_masterbrand_white);

  /* Button Secondary */
  --btn-background-secondary: transparent;
  --btn-border-color-secondary: var(--c_masterbrand_blue_green_500);
  --btn-label-color-secondary: var(--c_masterbrand_blue_green_500);
  --btn-background-secondary-hover: var(--c_masterbrand_blue_green_500);
  --btn-label-color-secondary-hover: var(--c_masterbrand_white);

  /* Button Negative Primary */
  --btn-background-color-negative-primary: var(--c_masterbrand_white);
  --btn-label-color-negative-primary: var(--c_masterbrand_blue_700);
  --btn-background-color-negative-primary-hover: var(--c_masterbrand_grey_100);

  /* Button Negative Secondary */
  --btn-label-color-negative-secondary: var(--c_masterbrand_white);
  --btn-background-color-negative-secondary: transparent;
  --btn-label-color-negative-secondary-hover: var(--c_masterbrand_blue_700);
  --btn-background-color-negative-secondary-hover: var(--c_masterbrand_white);

  /* Button close */
  --button-close-color: var(--c_masterbrand_blue_green_500);

  /* Links */
  --color-link-primary: var(--c_masterbrand_blue_green_500);
  --color-link-primary-hover: var(--c_masterbrand_blue_green_800);

  /* Title */
  --color-title-font: var(--c_masterbrand_grey_700);
  --line-height: var(--f_masterbrand_line_height);

  /* Body font */
  --body-font: var(--f_inter);
  --color-body-font: var(--c_masterbrand_grey_500);

  /* Background */
  --background-color: var(--c_city_blue_700);

  /* Interactives Elements */
  --border-color: var(--c_masterbrand_grey_500);
  --interactive-text: var(--c_masterbrand_blue_green_500);
  --interactive-background: var(--c_masterbrand_white);
  --interactive-text-hover: var(--c_masterbrand_blue_green_800);
  --interactive-background-hover: var(--c_masterbrand_blue_green_200);
  --interactive-text-base: var(--c_grey_800);

  /* Swipper */
  --swiper-theme-color: var(--c_masterbrand_blue_green_500);
  --swiper-pagination-color: var(--c_masterbrand_blue_green_500);
  --swiper-pagination-border: 1px solid var(--c_masterbrand_blue_green_500, transparent);
  --swiper-pagination-bullet-inactive-color: var(--c_white, transparent);
  --swiper-navigation-color: var(--c_masterbrand_blue_green_500);
  --swiper-navigation-background-color: var(--c_white_opacity_60);
  --swiper-navigation-background-color-hover: var(--c_masterbrand_blue_green_500);
  --swiper-navigation-border-color: var(--c_masterbrand_blue_green_500);
  --swiper-scrollbar-bg-color: var(--c_masterbrand_blue_green_200);
  --swiper-scrollbar-drag-bg-color: var(--c_masterbrand_blue_green_500);

  /* Badges */
  --tag-background-color: var(--c_city_blue_green_100);
  --tag-background-color-hover: var(--c_city_white);
  --tag-text-color: var(--c_city_blue_700);
  --tag-text-color-hover: var(--c_city_blue_700);

  /* Fastbooking */
  --fastbooking-icon-color: var(--icon-color-theme);
  --fastbooking-modal-title-font: var(--title-font);
  --fastbooking-modal-color-title-font: var(--color-title-font);
  --fastbooking-submit-button-height: 100%;
  --fastbooking-submit-button-width: 100%;
  --fastbooking-submit-border-style: solid;
  --fastbooking-submit-border-width: 0 0 0 1px; /* Top Right Bottom Left */
  --fastbooking-submit-border-color: var(--separator-color);
  --fastbooking-submit-background: var(--c_masterbrand_blue_green_500);
  --fastbooking-submit-background-hover: var(--c_masterbrand_blue_green_700);
  --fastbooking-submit-label-color: var(--c_masterbrand_white);
  --fastbooking-submit-label-color-hover: var(--c_masterbrand_white);

  /* Newsletter */
  --newsletter-background-green: var(--c_blue_green_400);
  --newsletter-background-default: var(--c_blue_green_100);
  --newsletter-background-white: var(--c_white);
  --newsletter-color-title-default: var(--c_blue_700);
  --newsletter-color-text-default: var(--c_blue_700);
  --newsletter-color-text-error: var(--c_white);
  --newsletter-color-icon-default: var(--c_blue_700);
  --newsletter-color-title-white: var(--c_grey_600);
  --newsletter-color-text-white: var(--c_grey_600);
  --newsletter-color-icon-white: var(--c_grey_600);
  --newsletter-border-white: var(--c_grey_600);

  .logo {
    .iberostar-hotels-resorts-mobile {
      max-width: 150px;
    }
  }
}
