@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

// Micromodal styles from https://gist.github.com/ghosh/4f94cf497d7090359a5c9f81caf60699#file-micromodal-html

/**************************  \
  Basic Modal Styles
\  **************************/

.modal {
  $self: &;

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 60%);
    background: rgba(var(--c_blue_700_rgb), 0.6);
  }

  &__container {
    box-sizing: border-box;
    max-height: min(100vh, 100%);
    overflow-y: auto;
    background-color: var(--c_white);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
  }

  &__content {
    padding: 0 20px 20px;
  }

  &__close {
    @include icon(close-fat, before) {
      padding: 0;
      border-radius: 0;
    }
    border: 0;
    background: transparent;
  }

  &__contact-button {
    @include icon(telf, before, 'm') {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      text-align: center;
    }
    cursor: pointer;
    display: flex;
    margin-right: 20px;
    pointer-events: all;

  }

  &__shared-button {
    @include icon(share-circles, before, 'm') {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      text-align: center
    }
    cursor: pointer;
    margin-right: 20px;
    pointer-events: all;

  }

  // Variations
  /* stylelint-disable-next-line no-duplicate-selectors */
  &__container {
    &.full-page {
      min-width: 100%;
      min-height: 100%;
    }

    &.full-height {
      height: 100%;
      overflow: hidden;

      #{ $self }__content {
        height: 100%;
        overflow-y: auto;
      }
    }

    &.no-padding {
      padding: 0;

      #{ $self }__header,
      #{ $self }__content {
        padding: 0;
      }

      #{ $self }__close {
        padding: 15px;
      }
    }

    &.floating-close-btn {
      position: relative;
      #{ $self }__header {
        position: absolute;
        width: 100%;
      }

      #{ $self }__content {
        padding: 20px;
      }
    }

    &.has-iframe {
      width: 100%;

      #{ $self }__iframe-container {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 56.25%;
        overflow: hidden;
      }

      #{ $self }__iframe {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

}

/**************************  \
  Demo Animation Style
\  **************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden='false'] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden='false'] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden='true'] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden='true'] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}
