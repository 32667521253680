@use 'header_sticky_hotel_commons' as *;

@include tabletStyles {
  .header-sticky-hotel {
    &.active.nav-down {
      transform: translate(0, 69px);
    }

    .hotel-header {
      .title-cnt {
        .title {
          display: inline;
        }
      }

      .category {
        vertical-align: baseline;

        .stars {
          height: 16px;

          .rating-stars {
            display: block;
          }
        }
      }

      .address {
        display: none;
      }
    }
  }

  .hotel-info-wrapper {
    padding-bottom: 10px;

    .floating-action {
      padding-top: 0;
    }
  }
}
