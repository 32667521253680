@use '../../base_css/abstracts/abstracts' as *;

.offer-item {
  $self: &;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;

  &__image {
    position: relative;
    margin: 0;
    border: 1px solid var(--c_grey_200);
    border-bottom: none;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
  }

  &__container {
    display: grid;
    grid-template-rows: 1fr auto;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid var(--c_grey_200);
    border-top: none;
    border-bottom: none;
  }

  &__title {
    @include fontSize(14);
    @include fontSansRegular;
    color: var(--c_dark_grey);
    line-height: 18px;

    .stars {
      display: inline-block;

      .rating-stars {
        @include fontSize(12);
        display: flex;
      }
    }
  }

  &__title-content {
    margin-right: 5px;
  }

  &__cta {
    padding: 0 8px 8px;
    border: 1px solid var(--c_grey_200);
    border-top: none;

    .btn-primary {
      @include fontSansRegular;
      width: 100%;
      padding: 10px 24px;
    }
  }

  &__dates {
    margin-top: 8px;
  }

  &__date {
    @include fontSize(12);
    @include fontSansRegular;
    line-height: 1.2em;
  }

  .price-info {
    .price-before,
    .hint-cnt {
      display: none;
    }

    .price-cnt {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
    }

    .price-text {
      @include fontSize(12);
      @include fontSansRegular;
      margin-right: 10px;
      text-transform: uppercase;
    }

    .price,
    .coin {
      @include fontSize(16);
      @include fontSansSemiBold;
    }

    .price {
      margin-left: 3px;
    }
  }
}
