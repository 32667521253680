@use '../../base_css/abstracts/abstracts' as *;

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 19px;
  border: 1px solid var(--c_primary);
  border-radius: 36px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  background-color: var(--c_white);
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  left: 3px;
  bottom: 2px;
  content: '';
  width: 13px;
  height: 13px;
  background-color: var(--c_primary);
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--c_turquoise_dark);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--c_turquoise_dark);
}

input:checked + .slider::before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}
