@use 'layout_commons' as *;

@include desktopStyles {
  .hide-on-desktop {
    display: none !important;
  }
}

@include tabletStyles {
  .page-wrapper:not(.is-fullpage) {
    .hotel-one-landing-page &,
    .transparent-header & {
      padding-top: 0;
    }
  }

  .hide-on-tablet {
    display: none !important;

    .card.hotel & {
      &.offer-pill {
        display: none;
      }
    }
  }

  .tablet-only {
    display: block;

    .card.hotel & {
      &.offer-pill {
        display: inline-block;
      }
    }

    &.l-external,
    &.secondary-btn,
    &.btn-primary {
      display: inline-block;
    }
  }

  .wrapper,
  %wrapper {
    padding: 0 10px;
  }

  .wrapper,
  %wrapper {
    padding: 0 10px;
  }

  .cms-zones {
    @include bg-half(75%);
    flex-direction: column;
    padding: 0;

    > .half {
      width: 100%;
    }

    // overrides some bg-half styles
    &::after {
      top: 0;
    }
  }
}
