@use '../../base_css/abstracts/abstracts' as *;
@use '../../shared/loader_spinner/loader_spinner';

.js-wait-loading-modal {
  @include mobileStyles {
    .modal__container {
      width: 100%;
    }
  }
}

.wait-loading {
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 50px;

  .loading-logo {
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }

  .loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .b-loader {
    position: static;
    margin-bottom: 40px;
  }
}
