// /*------------------------------------*\
//   $BUTTONS
// \*------------------------------------*/
@use '../../base_css/abstracts/abstracts' as *;

@include commonStyles {
  .btn-primary {
    @include button;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--btn-background-primary);
    color: var(--btn-label-color-primary);

    &.disabled,
    &:disabled {
      opacity: 0.4;
    }

    &:hover:not(.disabled, :disabled),
    &:focus:not(.disabled, :disabled) {
      background-color: var(--btn-background-primary-hover);
      color: var(--btn-label-color-primary-hover);
    }
  }

  /* TODO: PY05649-636-main-reb */
  // Cambiar los secondary-btn por el btn-secondary que corresponda
  .btn-secondary,
  .secondary-btn {
    @include button;
    border: 1px solid var(--btn-border-color-secondary);
    border-radius: var(--border-radius);
    background: var(--btn-background-secondary);
    color: var(--btn-label-color-secondary);

    &.disabled,
    &:disabled {
      opacity: 0.4;
    }

    &:hover:not(.disabled, :disabled),
    &:focus:not(.disabled, :disabled) {
      background: var(--btn-background-secondary-hover);
      color: var(--btn-label-color-secondary-hover);
    }

    &--white {
      border-color: var(--c_white);
      color: var(--c_white);

      &:hover:not(.disabled, :disabled),
      &:focus:not(.disabled, :disabled) {
        border-color: var(--c_primary);
      }
    }
  }

  /* TODO: PY05649-636-main-reb */
  // Cambiar los negative-btn por el btn negative que corresponda
  .btn-primary-negative,
  .negative-btn {
    @include button;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--btn-background-color-negative-primary);
    color: var(--btn-label-color-negative-primary);

    &.disabled,
    &:disabled {
      opacity: 0.4;
    }

    &:hover:not(.disabled, :disabled),
    &:focus:not(.disabled, :disabled) {
      background-color: var(--btn-background-color-negative-primary-hover);
    }
  }

  .btn-secondary-negative {
    @include button;
    border: 1px solid var(--c_white);
    border-radius: var(--border-radius);
    background: var(--btn-background-color-negative-secondary);
    color: var(--btn-label-color-negative-secondary);

    &.disabled,
    &:disabled {
      opacity: 0.4;
    }

    &:hover:not(.disabled, :disabled),
    &:focus:not(.disabled, :disabled) {
      background: var(--btn-background-color-negative-secondary-hover);
      color: var(--btn-label-color-negative-secondary-hover);
    }
  }

  // Block buttons extend the full available width
  .block-btn {
    display: block;
    flex: 1;
  }

  .fb-button,
  .google-button {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    padding: 14px 20px;
    color: var(--c_white);
    font-weight: var(--sans-bold);
    font-size: 14px;
    font-family: var(--body-font);
    line-height: 20px;

    span {
      position: relative;
      padding-left: 26px;

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  .fb-button {
    background: var(--c_facebook_primary);

    span {
      @include icon(facebook);
    }
  }

  .google-button {
    border: 1px solid var(--c_grey_400);

    span {
      background: url('../../images/icons/ico_google.svg') no-repeat 0 center;
    }
  }

  .file-link {
    @include icon(download, before, 'l') {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -18px;
      color: var(--c_blue_700);
    }
    position: relative;
    padding-left: 55px;

    .label {
      color: var(--c_blue_700);
      font-size: 16px;
      font-family: var(--body-font);
      line-height: 18px;
      text-transform: uppercase;
    }

    .link {
      display: block;

      a,
      .size {
        display: inline;
        color: var(--c_primary);
      }

      .size {
        color: var(--c_dark_grey);
      }
    }
  }

  // Borrar?
  .connect-btn {
    @include connect-btn;
  }

  // Icon cricle button Borrar?
  .btn-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin: 10px;
    border-radius: 50px;
    background: red;
    color: white;

    &.fb-btn {
      background-color: var(--c_facebook);
    }

    &.gplus-btn {
      background-color: var(--c_gplus);
    }
  }

  // Button group
  .btn-group {
    $self: &;
    display: inline-flex;
    line-height: normal;
    white-space: nowrap;

    &__btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 13px 18px;
      border-right: 1px solid var(--c_grey_200);
      outline: 0;
      background: var(--c_blue_green_100);
      color: var(--c_primary);
      text-decoration: none;

      &:first-child {
        border-radius: 2rem 0 0 2rem;
      }

      &:last-child {
        border-right: 0;
        border-radius: 0 2rem 2rem 0;
      }
    }

    &__text {
      overflow: hidden;
      transition: 0.5s;
    }

    &.is-collapsed {
      .s {
        margin: 0;
      }

      #{ $self }__text {
        width: 0 !important;
      }
    }
  }

  // Button group modifier - adds a shadow
  .btn-group--shady {
    border-radius: 2rem;
    box-shadow: 2px 2px 8px 0 rgba(188, 188, 188, 50%);
  }

  // Link with dusplicated text and animation hover
  // It need pro_link_to method
  .pro-link {
    display: inline-flex;
    align-items: center;

    .pro-content {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .pro-hover {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;

        .pro-letter {
          transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0.001, 0, 0, 1, 0, 0, 10, 0, 1);
          opacity: 0;
          transition: transform 0.4s ease-in, opacity 0.4s ease-in;

          &.active {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            opacity: 1;
            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
          }
        }
      }

      .pro-text {
        display: flex;

        .pro-letter {
          transition: transform 0.4s ease-in, opacity 0.4s ease-in;

          &.active {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1, 0, 0, -10, 0, 1);
            opacity: 0;
            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
          }
        }
      }
    }
  }

  // Btn close with cross + circle
  .btn-close-round {
    @include icon(close-fat, before, 's') {
      color: var(--button-close-color);
      font-size: 13px;
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: solid 2px var(--button-close-color);
    border-radius: 100%;
    background-color: var(--c_white);
    &:focus {
      border-color: var(--color-link-primary-hover);
    }
  }
}

@include mobileStyles {
  .btn-secondary,
  .secondary-btn {
    &:active:not(.disabled, :disabled) {
      background: var(--btn-background-secondary-hover);
      color: var(--btn-label-color-secondary-hover);

      .s {
        color: var(--btn-label-color-secondary-hover);
      }
    }

    &--white {
      border-color: var(--c_white);
      color: var(--c_white);

      &:hover,
      &:focus,
      &:active {
        &:not(.disabled, :disabled) {
          border-color: var(--c_white);
          background: transparent;
          color: var(--c_white);
        }
      }
    }
  }
}
