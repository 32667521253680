@use '../abstracts/abstracts' as *;

@include commonStyles {
  .show-in-app {
    display: none;
  }

  .in-app .show-in-app {
    display: block;
  }

  .in-app .hide-in-app {
    display: none !important;
  }
}
