/*
 * This file is generated by the rake task icons:generate
 * Don't edit this file manually
 */

/*
 * Icons variables
 */
$s-inspiration-cuba: '\e905';
$s-tiktok: '\e904';
$s-silla-ruedas: '\e901';
$s-aquatic-park: '\e902';
$s-vegan: '\e903';
$s-10-percent: '\f100';
$s-15-percent: '\f101';
$s-2-bedrooms: '\f102';
$s-20-percent: '\f103';
$s-25-percent: '\f104';
$s-30-percent: '\f105';
$s-35-percent: '\f106';
$s-40-percent: '\f107';
$s-404: '\f108';
$s-45-percent: '\f109';
$s-5-percent: '\f10a';
$s-50-percent: '\f10b';
$s-BAR-NO-ALCOHOL: '\f10c';
$s-BRL: '\f10d';
$s-BUS: '\f10e';
$s-BUTACA: '\f10f';
$s-CAD: '\f110';
$s-CAMA-DOSEL: '\f111';
$s-CAMA-REDONDA: '\f112';
$s-CARGA: '\f113';
$s-CAVA: '\f114';
$s-CHF: '\f115';
$s-COPA-CAVA: '\f116';
$s-COPA-VINO: '\f117';
$s-CUPON: '\f118';
$s-DKK: '\f119';
$s-ENCHUFE-EU: '\f11a';
$s-ENCHUFE-USA: '\f11b';
$s-ESPEJO: '\f11c';
$s-ESTACION-OTONO: '\f11d';
$s-EUR: '\f11e';
$s-FONTANA-DI-TREVI: '\f11f';
$s-FOOD-TRUCK: '\f120';
$s-FORMULARIO: '\f121';
$s-FUEGO: '\f122';
$s-GBP: '\f123';
$s-HALLOWEEN: '\f124';
$s-INFINITY-POOL: '\f125';
$s-INR: '\f126';
$s-JPY: '\f127';
$s-LAPTOP: '\f128';
$s-LITERA: '\f129';
$s-MALETA: '\f12a';
$s-MEGAPHONE: '\f12b';
$s-MICRO-2: '\f12c';
$s-MUSIC: '\f12d';
$s-MUSIC-BLUETOOTH: '\f12e';
$s-RESORT-CREDITS: '\f12f';
$s-RUB: '\f130';
$s-SEK: '\f131';
$s-SISTEMA-AUDIO: '\f132';
$s-Stamps_1: '\f133';
$s-Stamps_2: '\f134';
$s-Stamps_3: '\f135';
$s-Stamps_4: '\f136';
$s-Stamps_5: '\f137';
$s-TABLET: '\f138';
$s-TARIMA: '\f139';
$s-TARJETA-CREDITO: '\f13a';
$s-TOCADOR: '\f13b';
$s-USD: '\f13c';
$s-VASO: '\f13d';
$s-VIDEO-PROYECTOR: '\f13e';
$s-VINO: '\f13f';
$s-ZONA-RELAX: '\f140';
$s-accept: '\f141';
$s-access-to-nearby-premium-gold-and-premium-resorts: '\f143';
$s-access-to-nearby-premium-resorts: '\f144';
$s-adapted-room: '\f145';
$s-adults-only: '\f146';
$s-air-conditioninng: '\f147';
$s-air-dryer: '\f148';
$s-airport: '\f149';
$s-alert-check: '\f14a';
$s-alert-check-color: '\f14b';
$s-alert-error: '\f14c';
$s-alert-info: '\f14d';
$s-alert-warning: '\f14e';
$s-all-inclusive: '\f14f';
$s-all-suites: '\f150';
$s-aquafun: '\f151';
$s-arches: '\f152';
$s-area: '\f153';
$s-aromatherapy: '\f154';
$s-arrow-down: '\f155';
$s-arrow-down-fat: '\f156';
$s-arrow-down-short-tail: '\f2d8';
$s-arrow-left: '\f157';
$s-arrow-left-double: '\f158';
$s-arrow-left-fat: '\f159';
$s-arrow-left-long-tail: '\f15a';
$s-arrow-left-tail: '\f15b';
$s-arrow-right: '\f15c';
$s-arrow-right-double: '\f15d';
$s-arrow-right-fat: '\f15e';
$s-arrow-right-long-tail: '\f15f';
$s-arrow-up: '\f160';
$s-arrow-up-down: '\f161';
$s-arrow-up-down-slim: '\f162';
$s-arrow-up-fat: '\f163';
$s-arrow_stick_up: '\f164';
$s-arrow_up_big: '\f165';
$s-atm: '\f166';
$s-audience: '\f167';
$s-baby-center: '\f168';
$s-baby-kitchen: '\f169';
$s-baby-sitting: '\f16a';
$s-baby-spa: '\f16b';
$s-balcony: '\f16c';
$s-bali-stye-beds-with-charge: '\f16d';
$s-balinese-beds: '\f16e';
$s-bar-lounge: '\f16f';
$s-bars: '\f170';
$s-basketball: '\f171';
$s-bath: '\f172';
$s-bath-robes-and-slippers: '\f173';
$s-bathrobe: '\f174';
$s-beach-towels-at-room: '\f175';
$s-beachfront-massage: '\f176';
$s-beauty-salon: '\f177';
$s-bed-hotel: '\f178';
$s-billar: '\f179';
$s-blog: '\f17a';
$s-bono: '\f17b';
$s-bottle: '\f17c';
$s-bottle-black: '\f17d';
$s-bottle-black2: '\f17e';
$s-building: '\f17f';
$s-bussiness-center: '\f180';
$s-buttler-service: '\f181';
$s-cable-tv: '\f182';
$s-calendar: '\f183';
$s-calendar-times: '\f184';
$s-camera: '\f185';
$s-candy: '\f186';
$s-caribbean-style-shopping-street: '\f187';
$s-casino: '\f188';
$s-cast-videos: '\f189';
$s-ceiling-fan: '\f18a';
$s-chair: '\f18b';
$s-chandelier: '\f18c';
$s-chapel: '\f18d';
$s-check: '\f18e';
$s-check-in: '\f18f';
$s-children-s-menu: '\f190';
$s-children-s-pool: '\f191';
$s-chill-out-area: '\f192';
$s-cigar-lounge: '\f193';
$s-circle-economy: '\f194';
$s-circular-plus: '\f195';
$s-city-center: '\f196';
$s-clock: '\f197';
$s-close: '\f198';
$s-close-fat: '\f199';
$s-coffee-tea-kit: '\f19a';
$s-collapse: '\f19b';
$s-comment: '\f19c';
$s-compass: '\f19d';
$s-competition-games: '\f19e';
$s-concierge-at-pool-area: '\f19f';
$s-copy: '\f1a0';
$s-cuna: '\f1a1';
$s-curtains-in-the-scenario: '\f1a2';
$s-cutlery: '\f1a3';
$s-daily-aroma: '\f1a4';
$s-direct-access-to-the-beach: '\f1a5';
$s-disco: '\f1a6';
$s-dolphin: '\f1a7';
$s-double-bed: '\f1a8';
$s-download: '\f1a9';
$s-download-app: '\f1aa';
$s-dressing-room: '\f1ab';
$s-ducha: '\f1ac';
$s-dvd-player: '\f1ad';
$s-eagle: '\f1ae';
$s-eco-friendly: '\f1af';
$s-edit-thin: '\f1b0';
$s-empire-state-view: '\f1b1';
$s-energy: '\f1b2';
$s-exchange: '\f1b3';
$s-excursion: '\f1b4';
$s-expand: '\f1b5';
$s-expired: '\f1b6';
$s-extra-bed: '\f1b7';
$s-eye: '\f1b8';
$s-facebook: '\f1b9';
$s-facilities-for-cyclists: '\f1ba';
$s-families: '\f1bb';
$s-fifa-regulated-soccer-field: '\f1bc';
$s-filter: '\f1bd';
$s-fish: '\f1be';
$s-fit-and-fun: '\f1bf';
$s-fitness-room: '\f1c0';
$s-flight-hotel: '\f1c1';
$s-flower-detail: '\f1c2';
$s-flower-side: '\f1c3';
$s-formaldresscode: '\f1c4';
$s-free-wifi: '\f1c5';
$s-full-bath: '\f1c6';
$s-fun-park: '\f1c7';
$s-game-room: '\f1c8';
$s-garden-lake-view: '\f1c9';
$s-garden-view: '\f1ca';
$s-gastronomy: '\f1cb';
$s-gift: '\f1cc';
$s-glasses: '\f1cd';
$s-golf-discount-green-fee: '\f1ce';
$s-golf-round: '\f1cf';
$s-google: '\f1d0';
$s-grand-star-service: '\f1d1';
$s-green: '\f1d2';
$s-grid: '\f1d3';
$s-habitaciones_superiores: '\f1d4';
$s-hair-straightener: '\f1d5';
$s-half-board: '\f1d6';
$s-hammock: '\f1d7';
$s-hand-lens: '\f1d8';
$s-search2: '\e900';
$s-hand-pointer: '\f2d9';
$s-heart: '\f1d9';
$s-heating: '\f1da';
$s-help: '\f1db';
$s-heritage: '\f1dc';
$s-honeymoon: '\f1dd';
$s-hotel-bell-ringing: '\f1de';
$s-house: '\f1df';
$s-iberostar-inverse: '\f1e0';
$s-iberostar-logo: '\f1e1';
$s-iberostar-logo-transparent: '\f1e2';
$s-ico-bell: '\f1e3';
$s-ico-clock: '\f1e4';
$s-ico-filter: '\f1e5';
$s-ico-scroll: '\f1e6';
$s-ico_a11y: '\f1e7';
$s-ico_caminando: '\f1e8';
$s-ico_creditcard: '\f1e9';
$s-ico_exit: '\f1ea';
$s-ico_hucha: '\f1eb';
$s-ico_luxury_facilities: '\f1ec';
$s-ico_moon: '\f1ed';
$s-ico_not_smile: '\f1ee';
$s-ico_pago-hotel: '\f1ef';
$s-ico_premium: '\f1f0';
$s-ico_print: '\f1f1';
$s-ico_share-circles: '\f1f2';
$s-ico_smile: '\f1f3';
$s-ico_star: '\f1f4';
$s-ico_tarjeta-de-identificaci-n: '\f1f5';
$s-ico_thalassoterapy: '\f1f6';
$s-ico_transferencia-bancaria: '\f1f7';
$s-icon-ihg: '\f2da';
$s-icon-smartphone: '\f1f8';
$s-icono-app: '\f1f9';
$s-igh-elite: '\f2db';
$s-in-room-butler-service: '\f1fa';
$s-in-room-check-in: '\f1fb';
$s-inspirationguide: '\f1fc';
$s-instagram: '\f1fd';
$s-instructed-recreational-classes: '\f1fe';
$s-interconected-comunicated-rooms: '\f1ff';
$s-international-reception: '\f200';
$s-internet-cafe: '\f201';
$s-invoice: '\f202';
$s-iron-service: '\f203';
$s-isotipo-horizons: '\f204';
$s-jacuzzi: '\f205';
$s-jacuzzi-exterior: '\f206';
$s-kangaroo: '\f207';
$s-key: '\f208';
$s-kids: '\f209';
$s-kids-dining-room: '\f20a';
$s-kids-free: '\f20b';
$s-kitchen: '\f20c';
$s-larger-room: '\f20d';
$s-laundry-service: '\f20e';
$s-lazy-river: '\f20f';
$s-library: '\f210';
$s-linkedin: '\f211';
$s-list: '\f212';
$s-live-chat: '\f213';
$s-live-cooking: '\f214';
$s-lock: '\f215';
$s-luxury-cruise: '\f216';
$s-luxury-facilities: '\f217';
$s-mail: '\f218';
$s-mall: '\f219';
$s-map: '\f21a';
$s-marker: '\f21b';
$s-massage-in-the-jungle: '\f21c';
$s-meeting-room: '\f21d';
$s-meeting-room-clean: '\f21e';
$s-menu-restaurant-clean: '\f21f';
$s-menu-spa: '\f220';
$s-micro: '\f221';
$s-microwaves: '\f222';
$s-mini-bar: '\f223';
$s-mini-golf: '\f224';
$s-minus: '\f225';
$s-monkey: '\f226';
$s-more-options: '\f227';
$s-mouse: '\f228';
$s-nano-arrow-down-fat: '\f229';
$s-near-aquatic-park: '\f22a';
$s-near-spa: '\f22b';
$s-nearby-riding-school: '\f22c';
$s-nespresso-machine: '\f22d';
$s-no-music: '\f22e';
$s-no-smoking: '\f22f';
$s-ocean-views: '\f230';
$s-on-site-medicalservice-charge: '\f231';
$s-options_menu: '\f232';
$s-outdoor-meeting-space: '\f233';
$s-padi-certified: '\f234';
$s-padlock: '\f235';
$s-paid-wifi: '\f236';
$s-paper-bin: '\f237';
$s-paper_plane: '\f238';
$s-park-ave-view: '\f239';
$s-parking: '\f23a';
$s-pax-1: '\f23b';
$s-pax-1-1: '\f23c';
$s-pax-1-2: '\f23d';
$s-pax-2: '\f23e';
$s-pax-2-1: '\f23f';
$s-pax-2-2: '\f240';
$s-pax-3: '\f241';
$s-pax-3-1: '\f242';
$s-pax-3-2: '\f243';
$s-pax-4: '\f244';
$s-pax-4-1: '\f245';
$s-pax-4-2: '\f246';
$s-pebble-beach: '\f247';
$s-percentage: '\f248';
$s-person: '\f249';
$s-person2: '\f24a';
$s-pesca: '\f24b';
$s-pet-friendly: '\f24c';
$s-phone: '\f24d';
$s-photo-with-charge: '\f24e';
$s-picture-gallery: '\f24f';
$s-pilates: '\f250';
$s-pillow-menu: '\f251';
$s-pin: '\f252';
$s-pingpong: '\f253';
$s-pinterest: '\f254';
$s-plastic_free: '\f255';
$s-play: '\f256';
$s-play-circle: '\f257';
$s-playground: '\f258';
$s-playstation: '\f259';
$s-plus: '\f25a';
$s-plus-circle: '\f25b';
$s-pool-semi-private: '\f25c';
$s-pool-towels: '\f25d';
$s-pool-view: '\f25e';
$s-preference-room: '\f25f';
$s-printer: '\f260';
$s-private-balcony-with-ocean-view: '\f261';
$s-professional-show: '\f262';
$s-promo_bg: '\f263';
$s-qr: '\f264';
$s-quiet-area: '\f265';
$s-quiet-pool-clean: '\f266';
$s-quotes: '\f267';
$s-rating-00: '\f268';
$s-rating-05: '\f269';
$s-rating-10: '\f26a';
$s-ray: '\f26b';
$s-reception: '\f26c';
$s-reference-restaurant: '\f26d';
$s-refresh: '\f26e';
$s-register: '\f26f';
$s-reservations-required: '\f270';
$s-restaurantes: '\f271';
$s-romantic-dinner-balcony: '\f272';
$s-romantic-dinner-beach: '\f273';
$s-romantic-pack: '\f274';
$s-room-service: '\f275';
$s-rose: '\f276';
$s-safe: '\f277';
$s-salon: '\f278';
$s-salud_costera: '\f279';
$s-sand-beach: '\f27a';
$s-sauna: '\f27b';
$s-scuba-diving: '\f27c';
$s-sea-sun: '\f27d';
$s-seasoul: '\f27e';
$s-semi-private-pool: '\f27f';
$s-separate-beds: '\f280';
$s-servicios_exclusivos: '\f281';
$s-share-alt: '\f282';
$s-share-circles: '\f283';
$s-shield: '\f284';
$s-shoe-shine-service: '\f285';
$s-shopping: '\f286';
$s-shopping-area: '\f287';
$s-shower-and-tube: '\f288';
$s-single-bed: '\f289';
$s-smart-TV: '\f28a';
$s-smart-hdtv: '\f28b';
$s-sms: '\f28c';
$s-sofa-cama: '\f28d';
$s-sound-off: '\f28e';
$s-sound-on: '\f28f';
$s-spa-discount: '\f290';
$s-spa-sensation: '\f291';
$s-speech-bubbles: '\f292';
$s-sports-bar: '\f293';
$s-sports-offer: '\f294';
$s-star: '\f295';
$s-star-camp: '\f296';
$s-star-empty: '\f297';
$s-star-prestige: '\f298';
$s-star_iberostar: '\f299';
$s-starcafe: '\f29a';
$s-starmarket: '\f29b';
$s-sun: '\f29c';
$s-superior: '\f29d';
$s-supermarket: '\f29e';
$s-surprise-box: '\f29f';
$s-tag: '\f2a0';
$s-target: '\f2a1';
$s-taxi-auto: '\f2a2';
$s-telf: '\f2a3';
$s-tennis: '\f2a4';
$s-terrace: '\f2a5';
$s-terrace-with-barbecue: '\f2a6';
$s-terraza: '\f2a7';
$s-terraza-con-jacuzzi: '\f2a8';
$s-terraza-con-jaima: '\f2a9';
$s-thalassoterapy: '\f2aa';
$s-theatre: '\f2ab';
$s-ticket-client: '\f2ac';
$s-top-floor-location: '\f2ad';
$s-tour-360: '\f2ae';
$s-tree: '\f2af';
$s-triangle_down: '\f2b0';
$s-turism: '\f2b1';
$s-turn-down-service: '\f2b2';
$s-tv-pantalla-plana: '\f2b3';
$s-twilight: '\f2b4';
$s-twitter: '\f2b5';
$s-two-balcony: '\f2b6';
$s-two-bathrooms: '\f2b7';
$s-two-closets: '\f2b8';
$s-two-sofa-bed: '\f2b9';
$s-two-sofas: '\f2ba';
$s-two-tags: '\f2bb';
$s-umbrella: '\f2bc';
$s-umbrellas-with-charge: '\f2bd';
$s-unlimited: '\f2be';
$s-unlimited-diners: '\f2bf';
$s-user: '\f2c0';
$s-user_rounded: '\f2c1';
$s-videos: '\f2c2';
$s-view: '\f2c3';
$s-villas: '\f2c4';
$s-vip-zone: '\f2c5';
$s-volleyball: '\f2c6';
$s-water-park: '\f2c7';
$s-water-polo: '\f2c8';
$s-water-sports: '\f2c9';
$s-wave-pool: '\f2ca';
$s-webcam-2: '\f2cb';
$s-welcome-pack-for-adults: '\f2cc';
$s-welcomepack-for-kids: '\f2cd';
$s-wellness-room: '\f2ce';
$s-whatsapp: '\f2cf';
$s-wifi-alta: '\f2d0';
$s-wifi-en-el-lobby: '\f2d1';
$s-wifi-in-room: '\f2d2';
$s-world: '\f2d3';
$s-world-with-pins: '\f2d4';
$s-x-logo: '\f2dd';
$s-yoga: '\f2d5';
$s-yoga-room: '\f2d6';
$s-youtube: '\f2d7';

/*
 * Icons map
 */
$icons: (
  inspiration-cuba: $s-inspiration-cuba,
  tiktok: $s-tiktok,
  silla-ruedas: $s-silla-ruedas,
  aquatic-park: $s-aquatic-park,
  vegan: $s-vegan,
  10-percent: $s-10-percent,
  15-percent: $s-15-percent,
  2-bedrooms: $s-2-bedrooms,
  20-percent: $s-20-percent,
  25-percent: $s-25-percent,
  30-percent: $s-30-percent,
  35-percent: $s-35-percent,
  40-percent: $s-40-percent,
  404: $s-404,
  45-percent: $s-45-percent,
  5-percent: $s-5-percent,
  50-percent: $s-50-percent,
  BAR-NO-ALCOHOL: $s-BAR-NO-ALCOHOL,
  BRL: $s-BRL,
  BUS: $s-BUS,
  BUTACA: $s-BUTACA,
  CAD: $s-CAD,
  CAMA-DOSEL: $s-CAMA-DOSEL,
  CAMA-REDONDA: $s-CAMA-REDONDA,
  CARGA: $s-CARGA,
  CAVA: $s-CAVA,
  CHF: $s-CHF,
  COPA-CAVA: $s-COPA-CAVA,
  COPA-VINO: $s-COPA-VINO,
  CUPON: $s-CUPON,
  DKK: $s-DKK,
  ENCHUFE-EU: $s-ENCHUFE-EU,
  ENCHUFE-USA: $s-ENCHUFE-USA,
  ESPEJO: $s-ESPEJO,
  ESTACION-OTONO: $s-ESTACION-OTONO,
  EUR: $s-EUR,
  FONTANA-DI-TREVI: $s-FONTANA-DI-TREVI,
  FOOD-TRUCK: $s-FOOD-TRUCK,
  FORMULARIO: $s-FORMULARIO,
  FUEGO: $s-FUEGO,
  GBP: $s-GBP,
  HALLOWEEN: $s-HALLOWEEN,
  INFINITY-POOL: $s-INFINITY-POOL,
  INR: $s-INR,
  JPY: $s-JPY,
  LAPTOP: $s-LAPTOP,
  LITERA: $s-LITERA,
  MALETA: $s-MALETA,
  MEGAPHONE: $s-MEGAPHONE,
  MICRO-2: $s-MICRO-2,
  MUSIC: $s-MUSIC,
  MUSIC-BLUETOOTH: $s-MUSIC-BLUETOOTH,
  RESORT-CREDITS: $s-RESORT-CREDITS,
  RUB: $s-RUB,
  SEK: $s-SEK,
  SISTEMA-AUDIO: $s-SISTEMA-AUDIO,
  Stamps_1: $s-Stamps_1,
  Stamps_2: $s-Stamps_2,
  Stamps_3: $s-Stamps_3,
  Stamps_4: $s-Stamps_4,
  Stamps_5: $s-Stamps_5,
  TABLET: $s-TABLET,
  TARIMA: $s-TARIMA,
  TARJETA-CREDITO: $s-TARJETA-CREDITO,
  TOCADOR: $s-TOCADOR,
  USD: $s-USD,
  VASO: $s-VASO,
  VIDEO-PROYECTOR: $s-VIDEO-PROYECTOR,
  VINO: $s-VINO,
  ZONA-RELAX: $s-ZONA-RELAX,
  accept: $s-accept,
  access-to-nearby-premium-gold-and-premium-resorts: $s-access-to-nearby-premium-gold-and-premium-resorts,
  access-to-nearby-premium-resorts: $s-access-to-nearby-premium-resorts,
  adapted-room: $s-adapted-room,
  adults-only: $s-adults-only,
  air-conditioninng: $s-air-conditioninng,
  air-dryer: $s-air-dryer,
  airport: $s-airport,
  alert-check: $s-alert-check,
  alert-check-color: $s-alert-check-color,
  alert-error: $s-alert-error,
  alert-info: $s-alert-info,
  alert-warning: $s-alert-warning,
  all-inclusive: $s-all-inclusive,
  all-suites: $s-all-suites,
  aquafun: $s-aquafun,
  arches: $s-arches,
  area: $s-area,
  aromatherapy: $s-aromatherapy,
  arrow-down: $s-arrow-down,
  arrow-down-fat: $s-arrow-down-fat,
  arrow-down-short-tail: $s-arrow-down-short-tail,
  arrow-left: $s-arrow-left,
  arrow-left-double: $s-arrow-left-double,
  arrow-left-fat: $s-arrow-left-fat,
  arrow-left-long-tail: $s-arrow-left-long-tail,
  arrow-left-tail: $s-arrow-left-tail,
  arrow-right: $s-arrow-right,
  arrow-right-double: $s-arrow-right-double,
  arrow-right-fat: $s-arrow-right-fat,
  arrow-right-long-tail: $s-arrow-right-long-tail,
  arrow-up: $s-arrow-up,
  arrow-up-down: $s-arrow-up-down,
  arrow-up-down-slim: $s-arrow-up-down-slim,
  arrow-up-fat: $s-arrow-up-fat,
  arrow_stick_up: $s-arrow_stick_up,
  arrow_up_big: $s-arrow_up_big,
  atm: $s-atm,
  audience: $s-audience,
  baby-center: $s-baby-center,
  baby-kitchen: $s-baby-kitchen,
  baby-sitting: $s-baby-sitting,
  baby-spa: $s-baby-spa,
  balcony: $s-balcony,
  bali-stye-beds-with-charge: $s-bali-stye-beds-with-charge,
  balinese-beds: $s-balinese-beds,
  bar-lounge: $s-bar-lounge,
  bars: $s-bars,
  basketball: $s-basketball,
  bath: $s-bath,
  bath-robes-and-slippers: $s-bath-robes-and-slippers,
  bathrobe: $s-bathrobe,
  beach-towels-at-room: $s-beach-towels-at-room,
  beachfront-massage: $s-beachfront-massage,
  beauty-salon: $s-beauty-salon,
  bed-hotel: $s-bed-hotel,
  billar: $s-billar,
  blog: $s-blog,
  bono: $s-bono,
  bottle: $s-bottle,
  bottle-black: $s-bottle-black,
  bottle-black2: $s-bottle-black2,
  building: $s-building,
  bussiness-center: $s-bussiness-center,
  buttler-service: $s-buttler-service,
  cable-tv: $s-cable-tv,
  calendar: $s-calendar,
  calendar-times: $s-calendar-times,
  camera: $s-camera,
  candy: $s-candy,
  caribbean-style-shopping-street: $s-caribbean-style-shopping-street,
  casino: $s-casino,
  cast-videos: $s-cast-videos,
  ceiling-fan: $s-ceiling-fan,
  chair: $s-chair,
  chandelier: $s-chandelier,
  chapel: $s-chapel,
  check: $s-check,
  check-in: $s-check-in,
  children-s-menu: $s-children-s-menu,
  children-s-pool: $s-children-s-pool,
  chill-out-area: $s-chill-out-area,
  cigar-lounge: $s-cigar-lounge,
  circle-economy: $s-circle-economy,
  circular-plus: $s-circular-plus,
  city-center: $s-city-center,
  clock: $s-clock,
  close: $s-close,
  close-fat: $s-close-fat,
  coffee-tea-kit: $s-coffee-tea-kit,
  collapse: $s-collapse,
  comment: $s-comment,
  compass: $s-compass,
  competition-games: $s-competition-games,
  concierge-at-pool-area: $s-concierge-at-pool-area,
  copy: $s-copy,
  cuna: $s-cuna,
  curtains-in-the-scenario: $s-curtains-in-the-scenario,
  cutlery: $s-cutlery,
  daily-aroma: $s-daily-aroma,
  direct-access-to-the-beach: $s-direct-access-to-the-beach,
  disco: $s-disco,
  dolphin: $s-dolphin,
  double-bed: $s-double-bed,
  download: $s-download,
  download-app: $s-download-app,
  dressing-room: $s-dressing-room,
  ducha: $s-ducha,
  dvd-player: $s-dvd-player,
  eagle: $s-eagle,
  eco-friendly: $s-eco-friendly,
  edit-thin: $s-edit-thin,
  empire-state-view: $s-empire-state-view,
  energy: $s-energy,
  exchange: $s-exchange,
  excursion: $s-excursion,
  expand: $s-expand,
  expired: $s-expired,
  extra-bed: $s-extra-bed,
  eye: $s-eye,
  facebook: $s-facebook,
  facilities-for-cyclists: $s-facilities-for-cyclists,
  families: $s-families,
  fifa-regulated-soccer-field: $s-fifa-regulated-soccer-field,
  filter: $s-filter,
  fish: $s-fish,
  fit-and-fun: $s-fit-and-fun,
  fitness-room: $s-fitness-room,
  flight-hotel: $s-flight-hotel,
  flower-detail: $s-flower-detail,
  flower-side: $s-flower-side,
  formaldresscode: $s-formaldresscode,
  free-wifi: $s-free-wifi,
  full-bath: $s-full-bath,
  fun-park: $s-fun-park,
  game-room: $s-game-room,
  garden-lake-view: $s-garden-lake-view,
  garden-view: $s-garden-view,
  gastronomy: $s-gastronomy,
  gift: $s-gift,
  glasses: $s-glasses,
  golf-discount-green-fee: $s-golf-discount-green-fee,
  golf-round: $s-golf-round,
  google: $s-google,
  grand-star-service: $s-grand-star-service,
  green: $s-green,
  grid: $s-grid,
  habitaciones_superiores: $s-habitaciones_superiores,
  hair-straightener: $s-hair-straightener,
  half-board: $s-half-board,
  hammock: $s-hammock,
  hand-lens: $s-hand-lens,
  search2: $s-search2,
  hand-pointer: $s-hand-pointer,
  heart: $s-heart,
  heating: $s-heating,
  help: $s-help,
  heritage: $s-heritage,
  honeymoon: $s-honeymoon,
  hotel-bell-ringing: $s-hotel-bell-ringing,
  house: $s-house,
  iberostar-inverse: $s-iberostar-inverse,
  iberostar-logo: $s-iberostar-logo,
  iberostar-logo-transparent: $s-iberostar-logo-transparent,
  ico-bell: $s-ico-bell,
  ico-clock: $s-ico-clock,
  ico-filter: $s-ico-filter,
  ico-scroll: $s-ico-scroll,
  ico_a11y: $s-ico_a11y,
  ico_caminando: $s-ico_caminando,
  ico_creditcard: $s-ico_creditcard,
  ico_exit: $s-ico_exit,
  ico_hucha: $s-ico_hucha,
  ico_luxury_facilities: $s-ico_luxury_facilities,
  ico_moon: $s-ico_moon,
  ico_not_smile: $s-ico_not_smile,
  ico_pago-hotel: $s-ico_pago-hotel,
  ico_premium: $s-ico_premium,
  ico_print: $s-ico_print,
  ico_share-circles: $s-ico_share-circles,
  ico_smile: $s-ico_smile,
  ico_star: $s-ico_star,
  ico_tarjeta-de-identificaci-n: $s-ico_tarjeta-de-identificaci-n,
  ico_thalassoterapy: $s-ico_thalassoterapy,
  ico_transferencia-bancaria: $s-ico_transferencia-bancaria,
  icon-ihg: $s-icon-ihg,
  icon-smartphone: $s-icon-smartphone,
  icono-app: $s-icono-app,
  igh-elite: $s-igh-elite,
  in-room-butler-service: $s-in-room-butler-service,
  in-room-check-in: $s-in-room-check-in,
  inspirationguide: $s-inspirationguide,
  instagram: $s-instagram,
  instructed-recreational-classes: $s-instructed-recreational-classes,
  interconected-comunicated-rooms: $s-interconected-comunicated-rooms,
  international-reception: $s-international-reception,
  internet-cafe: $s-internet-cafe,
  invoice: $s-invoice,
  iron-service: $s-iron-service,
  isotipo-horizons: $s-isotipo-horizons,
  jacuzzi: $s-jacuzzi,
  jacuzzi-exterior: $s-jacuzzi-exterior,
  kangaroo: $s-kangaroo,
  key: $s-key,
  kids: $s-kids,
  kids-dining-room: $s-kids-dining-room,
  kids-free: $s-kids-free,
  kitchen: $s-kitchen,
  larger-room: $s-larger-room,
  laundry-service: $s-laundry-service,
  lazy-river: $s-lazy-river,
  library: $s-library,
  linkedin: $s-linkedin,
  list: $s-list,
  live-chat: $s-live-chat,
  live-cooking: $s-live-cooking,
  lock: $s-lock,
  luxury-cruise: $s-luxury-cruise,
  luxury-facilities: $s-luxury-facilities,
  mail: $s-mail,
  mall: $s-mall,
  map: $s-map,
  marker: $s-marker,
  massage-in-the-jungle: $s-massage-in-the-jungle,
  meeting-room: $s-meeting-room,
  meeting-room-clean: $s-meeting-room-clean,
  menu-restaurant-clean: $s-menu-restaurant-clean,
  menu-spa: $s-menu-spa,
  micro: $s-micro,
  microwaves: $s-microwaves,
  mini-bar: $s-mini-bar,
  mini-golf: $s-mini-golf,
  minus: $s-minus,
  monkey: $s-monkey,
  more-options: $s-more-options,
  mouse: $s-mouse,
  nano-arrow-down-fat: $s-nano-arrow-down-fat,
  near-aquatic-park: $s-near-aquatic-park,
  near-spa: $s-near-spa,
  nearby-riding-school: $s-nearby-riding-school,
  nespresso-machine: $s-nespresso-machine,
  no-music: $s-no-music,
  no-smoking: $s-no-smoking,
  ocean-views: $s-ocean-views,
  on-site-medicalservice-charge: $s-on-site-medicalservice-charge,
  options_menu: $s-options_menu,
  outdoor-meeting-space: $s-outdoor-meeting-space,
  padi-certified: $s-padi-certified,
  padlock: $s-padlock,
  paid-wifi: $s-paid-wifi,
  paper-bin: $s-paper-bin,
  paper_plane: $s-paper_plane,
  park-ave-view: $s-park-ave-view,
  parking: $s-parking,
  pax-1: $s-pax-1,
  pax-1-1: $s-pax-1-1,
  pax-1-2: $s-pax-1-2,
  pax-2: $s-pax-2,
  pax-2-1: $s-pax-2-1,
  pax-2-2: $s-pax-2-2,
  pax-3: $s-pax-3,
  pax-3-1: $s-pax-3-1,
  pax-3-2: $s-pax-3-2,
  pax-4: $s-pax-4,
  pax-4-1: $s-pax-4-1,
  pax-4-2: $s-pax-4-2,
  pebble-beach: $s-pebble-beach,
  percentage: $s-percentage,
  person: $s-person,
  person2: $s-person2,
  pesca: $s-pesca,
  pet-friendly: $s-pet-friendly,
  phone: $s-phone,
  photo-with-charge: $s-photo-with-charge,
  picture-gallery: $s-picture-gallery,
  pilates: $s-pilates,
  pillow-menu: $s-pillow-menu,
  pin: $s-pin,
  pingpong: $s-pingpong,
  pinterest: $s-pinterest,
  plastic_free: $s-plastic_free,
  play: $s-play,
  play-circle: $s-play-circle,
  playground: $s-playground,
  playstation: $s-playstation,
  plus: $s-plus,
  plus-circle: $s-plus-circle,
  pool-semi-private: $s-pool-semi-private,
  pool-towels: $s-pool-towels,
  pool-view: $s-pool-view,
  preference-room: $s-preference-room,
  printer: $s-printer,
  private-balcony-with-ocean-view: $s-private-balcony-with-ocean-view,
  professional-show: $s-professional-show,
  promo_bg: $s-promo_bg,
  qr: $s-qr,
  quiet-area: $s-quiet-area,
  quiet-pool-clean: $s-quiet-pool-clean,
  quotes: $s-quotes,
  rating-00: $s-rating-00,
  rating-05: $s-rating-05,
  rating-10: $s-rating-10,
  ray: $s-ray,
  reception: $s-reception,
  reference-restaurant: $s-reference-restaurant,
  refresh: $s-refresh,
  register: $s-register,
  reservations-required: $s-reservations-required,
  restaurantes: $s-restaurantes,
  romantic-dinner-balcony: $s-romantic-dinner-balcony,
  romantic-dinner-beach: $s-romantic-dinner-beach,
  romantic-pack: $s-romantic-pack,
  room-service: $s-room-service,
  rose: $s-rose,
  safe: $s-safe,
  salon: $s-salon,
  salud_costera: $s-salud_costera,
  sand-beach: $s-sand-beach,
  sauna: $s-sauna,
  scuba-diving: $s-scuba-diving,
  sea-sun: $s-sea-sun,
  seasoul: $s-seasoul,
  semi-private-pool: $s-semi-private-pool,
  separate-beds: $s-separate-beds,
  servicios_exclusivos: $s-servicios_exclusivos,
  share-alt: $s-share-alt,
  share-circles: $s-share-circles,
  shield: $s-shield,
  shoe-shine-service: $s-shoe-shine-service,
  shopping: $s-shopping,
  shopping-area: $s-shopping-area,
  shower-and-tube: $s-shower-and-tube,
  single-bed: $s-single-bed,
  smart-TV: $s-smart-TV,
  smart-hdtv: $s-smart-hdtv,
  sms: $s-sms,
  sofa-cama: $s-sofa-cama,
  sound-off: $s-sound-off,
  sound-on: $s-sound-on,
  spa-discount: $s-spa-discount,
  spa-sensation: $s-spa-sensation,
  speech-bubbles: $s-speech-bubbles,
  sports-bar: $s-sports-bar,
  sports-offer: $s-sports-offer,
  star: $s-star,
  star-camp: $s-star-camp,
  star-empty: $s-star-empty,
  star-prestige: $s-star-prestige,
  star_iberostar: $s-star_iberostar,
  starcafe: $s-starcafe,
  starmarket: $s-starmarket,
  sun: $s-sun,
  superior: $s-superior,
  supermarket: $s-supermarket,
  surprise-box: $s-surprise-box,
  tag: $s-tag,
  target: $s-target,
  taxi-auto: $s-taxi-auto,
  telf: $s-telf,
  tennis: $s-tennis,
  terrace: $s-terrace,
  terrace-with-barbecue: $s-terrace-with-barbecue,
  terraza: $s-terraza,
  terraza-con-jacuzzi: $s-terraza-con-jacuzzi,
  terraza-con-jaima: $s-terraza-con-jaima,
  thalassoterapy: $s-thalassoterapy,
  theatre: $s-theatre,
  ticket-client: $s-ticket-client,
  top-floor-location: $s-top-floor-location,
  tour-360: $s-tour-360,
  tree: $s-tree,
  triangle_down: $s-triangle_down,
  turism: $s-turism,
  turn-down-service: $s-turn-down-service,
  tv-pantalla-plana: $s-tv-pantalla-plana,
  twilight: $s-twilight,
  twitter: $s-twitter,
  two-balcony: $s-two-balcony,
  two-bathrooms: $s-two-bathrooms,
  two-closets: $s-two-closets,
  two-sofa-bed: $s-two-sofa-bed,
  two-sofas: $s-two-sofas,
  two-tags: $s-two-tags,
  umbrella: $s-umbrella,
  umbrellas-with-charge: $s-umbrellas-with-charge,
  unlimited: $s-unlimited,
  unlimited-diners: $s-unlimited-diners,
  user: $s-user,
  user_rounded: $s-user_rounded,
  videos: $s-videos,
  view: $s-view,
  villas: $s-villas,
  vip-zone: $s-vip-zone,
  volleyball: $s-volleyball,
  water-park: $s-water-park,
  water-polo: $s-water-polo,
  water-sports: $s-water-sports,
  wave-pool: $s-wave-pool,
  webcam-2: $s-webcam-2,
  welcome-pack-for-adults: $s-welcome-pack-for-adults,
  welcomepack-for-kids: $s-welcomepack-for-kids,
  wellness-room: $s-wellness-room,
  whatsapp: $s-whatsapp,
  wifi-alta: $s-wifi-alta,
  wifi-en-el-lobby: $s-wifi-en-el-lobby,
  wifi-in-room: $s-wifi-in-room,
  world: $s-world,
  world-with-pins: $s-world-with-pins,
  x-logo: $s-x-logo,
  yoga: $s-yoga,
  yoga-room: $s-yoga-room,
  youtube: $s-youtube,
);
