@use 'burgermenu_commons' as *;

@include tabletStyles {
  .burgermenu-left,
  .burgermenu-right {
    height: 100%;
  }

  .opened-left,
  .opened-right {
    .page-wrapper {
      margin-top: 0;
    }
  }

  .burgermenu-sticky-head {
    &.apply-segment-border {
      &.nav-up {
        transform: translate3d(0, -83px, 0);
      }
    }

    &.nav-up {
      transform: translate3d(0, -83px, 0);
    }
  }
}
