@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

$dark-blue: #002855;
$blue: #F1F2F9;
$green: #F0F9E8;
$grey: var(--c_dark_grey);
$yellow: var(--c_white)be1;
$orange: #FEF5E8;
$turquoise: var(--c_primary);

.headband {
  position: relative;
  z-index: 9000; // has to be above desktop's vue menu panels in desktop and below mobile header in mobile
  transform: translateY(-100%);
  opacity: 0;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  background-color: var(--c_grey_light);
  color: var(--c_primary);
  transition: all 1s ease-in-out;

  &__stash {
    visibility: hidden;
  }

  .s {
    margin-right: 4px;
  }

  >.wrapper {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__countdown {
    padding-left: 8px;
    margin-left: 8px;
    border-color: inherit;
    border-left: 2px solid;
  }

  &__link {
    margin-left: auto;
    border-bottom: 1px solid;
  }

  a {
    font-size: inherit; // prevents default a tag styles from overriding inline styles in parents
  }

  &.bg-black {
    background: #000;
  }

  &.bg-dark {
    background-color: $dark-blue;
  }

  &.bg-blue {
    background-color: $blue;
  }

  &.bg-green {
    background-color: $green;
  }

  &.bg-yellow {
    background-color: $yellow;
  }

  &.bg-orange {
    background-color: $orange;
  }

  &.bg-turquoise {
    background-color: $turquoise;
  }

  &.font-white {
    color: white;

    a {
      color: white;
    }
  }

  &.font-grey {
    color: $grey;

    a {
      color: $grey;
    }
  }

  &.font-dark {
    color: $dark-blue;

    a {
      color: $dark-blue;
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
  }

  &.slide-down {
    transform: translateY(0);
    opacity: 1;
    max-height: 200px;
    padding: 11px 0;
  }
}

body.fancybox-active,
body.opened-menu {
  .headband.sticky {
    visibility: hidden;
    opacity: 0;
  }
}
