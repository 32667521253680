@use '../../base_css/abstracts/abstracts' as *;
@use '../../shared/offer_item/offer_item';

.vue-submenu,
.reb-main-nav {
  .offer-items-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding-right: 15px;
  }

  .banners-container {
    display: flex;

    .offer-banner {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 370px;
      margin: 0;
      color: var(--c_white);

      &:nth-child(2n) {
        margin: 0 15px;
      }

      &:hover {
        .offer-banner--data {
          transform: translateY(0);
        }
      }

      &--container {
        position: relative;
        cursor: pointer;
        overflow: hidden;

        &__overlay-img {
          position: absolute;
          z-index: 1;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.01%) 0%, #000 100%);
        }
      }

      &--image {
        img {
          width: 100%;
          min-height: 370px;
          object-fit: cover;
        }
      }

      &--data {
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 95%;
        padding: 8px;
        background: var(--c_white);
        color: var(--c_black_grey_700);
        transition: transform 0.3s;

        &:not(.without-translation) {
          transform: translateY(65px);
        }

        .introduction {
          font-weight: 600;
          font-size: 12px;
          font-family: var(--font-sans);
          line-height: 18px;
          letter-spacing: 1.5 px;
          text-transform: uppercase;
        }

        .visible-text {
          word-break: break-word;

          .content {
            @include fontSize(18);
            margin-top: $space1;
            margin-bottom: $space3;
            color: var(--c_grey_500);
            font-weight: bold;
            line-height: 24px;
          }
        }
      }

      &--content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
        margin-top: 22px;
        margin-bottom: 5px;

        .t-link {
          @include icon(arrow-right-fat, after, 'xs') {
            @include fontSize(10);
            position: absolute;
            top: 5px;
            left: auto;
            margin-left: 8px;
            font-weight: bold;
          }

          @include fontSize(14);
          position: relative;
          padding: 0;
          color: var(--c_primary);
          font-weight: bold;
        }
      }
    }
  }

  .offer-banner--container picture img {
    transition: 0.3s ease-in-out;
  }

  .offer-banner--container {
    &:hover {
      picture img {
        transform: scale(1.05);
      }
    }
  }

  .offers {
    display: flex;
    flex-direction: column;
  }
}
