@use '../../base_css/abstracts/abstracts' as *;

$mr_icon: 16px;
$width_icon: 36px;
$size_icon: 24px;

.offer-notification-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem;
  border-bottom: 1px solid var(--c_grey_100);
  background-color: var(--c_white);
  color: var(--c_dark_grey);

  &.hidden {
    display: none;
  }

  .notification-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $width_icon;
    height: $width_icon;
    padding: 5px;
    margin-right: $mr_icon;
    border-radius: 50%;
    background-color: var(--c_ambar_secondary);
    font-size: $size_icon;

    &.cosmos {
      background-color: var(--c_blue_700);
      color: var(--c_blue_grey);
    }

    &.ocean {
      background-color: var(--c_ocean);
      color: var(--c_blue_green_100);
    }

    &.heritage {
      background-color: var(--c_heritage);
      color: var(--c_verde_salvia_secondary);
    }

    &.coral {
      background-color: #E5554F;
      color: var(--c_red_secondary);
    }
  }

  .notification-icon::after {
    line-height: normal;
  }

  .notification-info {
    @include fontSize(14);
    position: relative;
    width: calc(100% - #{$mr_icon} - #{$width_icon});
    padding-right: 20px;

    .title {
      font-weight: bold;
    }

    .notification-close {
      @include icon(close-fat, before, 'xs');
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      margin-left: 24px;

      &:hover {
        cursor: pointer;
      }

      &::before {
        color: var(--c_primary);
      }
    }
  }
}

.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;

  &.hidden {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
}

@include media($tablet) {
  .offer-notification-container .notification-info .notification-close {
    right: 30px;
  }

  .notification-link {
    color: var(--c_primary) !important;
  }
}
