@use '../../base_css/abstracts/abstracts' as *;

.dates-alert.gamification-alert {
  @include icon(calendar, before, 'xl') {
    margin-right: 16px;
    color: var(--c_carrot);
  }
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
  padding: 8px 30px 8px 8px;
  border: 1px solid var(--c_carrot);
  border-radius: 4px;
  background-color: var(--c_carrot_secondary);
  color: var(--c_carrot);

  .alert-title {
    text-align: left;
    text-transform: uppercase;
  }

  .close {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: var(--c_primary);
    font-size: 10px;
  }

  &.hidden {
    display: none;
  }
}
