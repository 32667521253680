@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.formikation {
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    left: -9999px;
    cursor: pointer;
    padding: 0;
    padding-top: 1px;
    margin: 0;
  }

  label {
    @include fontSize(16);
    cursor: pointer;
    display: block;
    line-height: 24px;

    &.fk-check {
      position: relative;
      padding-left: 30px;

      &::before {
        position: absolute;
        top: 2px;
        left: 0;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid var(--c_grey_400);
      }

      &.checked {
        @include icon(check, after, 'xs') {
          position: absolute;
          top: 9px;
          left: 4px;
          color: var(--c_white);
        }

        &::before {
          border-color: var(--c_primary);
          background: var(--c_primary);
        }
      }

      &.disabled {
        cursor: default;
        color: var(--c_grey_200);
        pointer-events: none;

        &::before {
          border-color: var(--c_grey_200);
        }
      }
    }

    &.fk-radio {
      &::before {
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        top: 4px;
        left: 3px;
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: transparent;
      }

      &.checked {
        &::before {
          border-color: var(--c_green);
        }

        &::after {
          background: var(--c_green);
        }
      }

      &.disabled {
        &.checked {
          &::after {
            background: var(--c_grey_200);
          }
        }
      }
    }

    &.switch {
      &.fk-radio,
      &.fk-check {
        width: 73px;
        height: 34px;
        border: 1px solid var(--c_grey_400);
        border-radius: 22px;
        font-size: 0;

        &::before {
          position: absolute;
          top: 50%;
          left: 3px;
          z-index: 2;
          content: ' ';
          width: 26px;
          height: 26px;
          margin-top: -13px;
          border: 0;
          border-radius: 50%;
          background: var(--c_grey_400);
          transition: all 0.3s ease;
        }

        &::after {
          @include fontSize(14);
          position: absolute;
          top: 50%;
          left: auto;
          right: 8px;
          z-index: 1;
          content: attr(data-off);
          width: auto;
          height: auto;
          margin-top: -6px;
          border-radius: 0;
          line-height: 14px;
          text-transform: uppercase;
        }

        &.checked {
          border-color: var(--c_green);

          &::before {
            left: calc(100% - 29px);
            content: '';
            background: var(--c_green);
          }

          &::after {
            @include fontSize(14);
            left: 8px;
            right: auto;
            content: attr(data-on);
            background: transparent;
            color: var(--c_blue_700);
            font-family: var(--font-sans);
            line-height: 14px;
          }
        }

        &.disabled {
          border-color: var(--c_grey_200);

          &::before {
            background: var(--c_grey_200);
          }

          &::after {
            color: var(--c_grey_200);
          }
        }
      }
    }
  }

  .fk-file-input {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    outline: 0;
    font-size: 14px;

    &::after {
      position: absolute;
      right: 0;
      content: attr(data-text);
      cursor: pointer;
      display: block;
      border: 0;
      outline: 0;
    }

    > p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 1.15;
    }

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      cursor: pointer;
      opacity: 0;

      // IE8
      filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      width: 100%;
      height: 100%;
      font-size: 14px;
    }
  }
}
