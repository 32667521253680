@use 'user_level_commons' as *;

@include commonTabletDesktop {
  .user-level {
    &-1 {
      &:not(.user-level-extra-info) {
        &::after {
          width: 82%;
          background: var(--c_grey_200);
        }
      }
    }

    &-2 {
      &:not(.user-level-extra-info) {
        &::after {
          width: 57%;
          background: var(--c_grey_200);
        }
      }
    }

    &-3 {
      &:not(.user-level-extra-info) {
        &::after {
          width: 32%;
          background: var(--c_grey_200);
        }
      }
    }
  }
}
