@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';
@use '../../../elements/discounts_tooltip/discounts_tooltip';

.horizontal-card {
  border: 1px solid var(--c_grey_200);

  .swiper-container {
    .card-image-prev::before,
    .card-image-next::after {
      @include fontSize(24);
    }

    .card-image-prev::after {
      content: '';
    }

    .card-image-prev {
      left: 5px;
    }

    .card-image-next {
      right: 5px;
    }
  }

  .text {
    .badges-list {
      display: flex;
      margin-bottom: 10px;

      .badge {
        margin-right: 8px;
      }
    }
  }

  &.offer {
    .rating-stars {
      &::before {
        margin-bottom: 5px;
      }
    }
  }

  &.card.hotel {
    .call-to-action {
      &:not(.grand) {
        .hint-cnt {
          display: flex;
          align-items: center;
          margin-top: 4px;

          &__text {
            display: inline-block;
            margin-right: 4px;
            white-space: nowrap;
          }

          [data-fancybox] {
            color: var(--c_primary);
            font-size: 16px;
            line-height: 14px;
          }
        }
      }

      .btn-primary {
        .highlight {
          .t-coin,
          .t-price {
            @include fontSize(14);
            color: var(--c_white);
            font-weight: 700;
            line-height: 20px;
          }
        }
      }
    }
  }
}
