@use 'modal_commons' as *;

.modal {
  &__container {
    max-width: 90%;
    max-height: 90%;
  }

  &--small {
    top: 33%;
    left: 33%;
    max-width: 550px;
  }
}
