@use 'hotel_breadcrumb_commons' as *;

@include desktopStyles {
  .breadcrumb-container {
    .social-media-share {
      margin-bottom: 30px;
    }
  }

  .social-media-share {
    margin-top: 15px;
  }
}

@include tabletStyles {
  .hotel-one-landing-page {
    .hotel-breadcrumb {
      display: none;
    }
  }

  .breadcrumb-container {
    .social-media-share {
      margin-bottom: 20px;
    }
  }
}
