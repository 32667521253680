@use 'sass:map';
@use '../../base_css/abstracts/abstracts' as *;

// This could be done by iterating classes instead of regex,
// and that is the way we implemented it in the first place.
// It worked in localhost but not in pre. So we changed
// it to regex. May be a different Sass version between
// development and servers to blame?

$max-rating: 5;
$content: '';

.rating-stars {
  @include icon(nil, after) {
    color: var(--c_black_grey_700);
  }

  @include icon(nil, before) {
    color: var(--c_black_grey_700);
  }

  @for $rating from 1 through $max-rating {
    $content: $content + '#{map.get($icons, star)}';

    &[class*='r#{$rating}*P']:before,
    &[class*='r#{$rating}*']:before {
      content: $content;
    }

    &[class*='r#{$rating}*P']:after {
      content: '#{map.get($icons, superior)}';
      margin-top: 2px;
    }
  }
  font-size: 12px;
  line-height: 1;
  letter-spacing: 4px;

  &--primary {
    &::after,
    &::before {
      color: var(--c_primary);
    }
  }

  &--white {
    &::after,
    &::before {
      color: var(--c_white);
    }
  }

  &--black {
    &::after,
    &::before {
      color: var(--c_black);
    }
  }

  &--xxs {
    @include fontSize(8);
  }

  &--xs {
    @include fontSize(12);
  }

  &--s {
    @include fontSize(14);
  }

  &--m {
    @include fontSize(16);
  }
}
