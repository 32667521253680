@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.cards-slider {
  position: relative;

  .swiper-container {
    position: static;
    margin: 40px 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-top: -14px;
    border: 1px solid var(--c_white);
    border-radius: 50%;
    background: rgba(0, 5, 10, 23%);
    color: var(--c_white);
  }

  .swiper-button-prev {
    @include icon(arrow-left, before) {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -3px;
      margin-left: -3px;
      color: var(--c_white);
      font-size: 7px;
    }
    left: 30px;
  }

  .swiper-button-next {
    @include icon(arrow-right, before) {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -3px;
      margin-left: -2px;
      color: var(--c_white);
      font-size: 7px;
    }
    right: 30px;
  }
}

.card-banner {
  @include icon(welcomepack-for-kids, before, 'l') {
    flex: 0 0 auto;
    margin: 0 16px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid var(--c_grey_200);
  background-color: var(--c_white);
  color: var(--c_dark_grey);
}

.card {
  position: relative;
  background: var(--c_white);

  .card-image {
    @include image_filter;
    position: relative;
    display: block;

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .tooltip {
      &.top {
        margin-top: 35px;
        margin-left: -40px;
      }

      &.left {
        top: 5px !important;
        margin-left: 20px;
      }
    }

    .info-price {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      display: table;
      width: 100%;
      padding: 10px;
      background: rgba(var(--c_turquoise_rgb), 0.8);
      color: var(--c_white);

      .prev-type,
      .prev-highlight {
        display: none;
      }

      > div {
        display: table-cell;
        font-size: 12px;
        font-family: var(--font-sans);
        line-height: 14px;
        white-space: nowrap;

        .type,
        .highlight {
          display: inline-block;
          margin: 0;
          color: var(--c_white);
        }

        .type {
          font-size: 14px;
        }

        .highlight {
          font-weight: bold;
          font-size: 18px;
          font-family: var(--font-sans);
          line-height: 22px;
        }

        &:first-child {
          width: 100%;
        }
      }
    }
  }

  .bottom-list {
    padding-top: 5px;

    .s {
      display: inline-block;
      margin-right: 16px;

      &::after {
        line-height: 24px;
      }

      &:first-child + .tooltip::after {
        left: 30px;
      }
    }

    .basic-tooltip {
      width: auto;
      white-space: nowrap;
    }
  }

  p {
    margin: 0;
  }

  .title {
    @include fontSansSemiBold;
    @include fontSize(18);
    padding-bottom: 5px;
    color: var(--c_dark_grey);
    line-height: 26px;

    .s {
      position: relative;
      top: -2px;
      display: inline-block;
      margin-top: -22px;
      font-size: 44px;
    }
  }

  .text {
    .title {
      color: var(--c_dark_grey);

      a,
      &-link {
        @include fontSansSemiBold;
        @include fontSize(18);
        color: var(--c_dark_grey);
        line-height: 26px;
      }
    }

    .title-link {
      margin-right: 5px;
      color: var(--c_dark_grey);
    }
  }

  .offer-image {
    display: block;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .card-container {
    position: relative;
    padding: 20px;
    font-family: var(--font-sans);

    .crs_offer_btn {
      display: inline-block;
      margin-left: 5px;
    }
  }

  .desc {
    padding-bottom: 5px;
    color: var(--c_dark_grey);
  }

  .summary {
    margin-top: 1em;
    margin-bottom: 9px;
    color: var(--c_dark_grey);
    font-size: 14px;
  }

  .call-to-action {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .btn-primary {
    white-space: nowrap;
    text-align: center;
  }
}
