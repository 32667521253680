@use '../../base_css/abstracts/abstracts' as *;

.vue-submenu,
.reb-main-nav {
  .not-logged-form {
    padding: 0;

    .hide-in-menu {
      display: none;
    }

    .menu-style-row {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 10px;
    }

    .fz-col {
      margin-right: 0;
    }

    .locator-code {
      margin-top: 10px;
    }

    .hotel-column {
      margin-bottom: 11px;
    }

    .btn-primary {
      width: 100%;
    }

    .toggle-inputs {
      flex-direction: column;
    }
  }

  .bookings {
    .panel-content {
      display: flex;
      flex-direction: row;
      padding: 0;

      &__bookings-options {
        padding: 20px 30px 40px;
      }

      .private-area {
        cursor: pointer;
      }

      .my-bookings {
        flex: 0 0 30%;
        margin-top: 20px;
      }

      .my-bookings.ibercuba {
        flex: content;
        width: 100%;

        .inner-header {
          display: flex;
          justify-content: center;
        }
      }

      .check-in-options {
        flex: 0 0 70%;
        padding: 25px 30px 40px;
        border-left: 1px solid var(--c_grey_200);

        &--container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .hidden-img {
            display: none;
          }
        }
      }
    }

    .link-container {
      position: relative;
      flex: 0 0 calc(50% - 5px);
      margin: 0 10px 0 0;
      overflow: hidden;

      &:last-child {
        margin: 0;
      }

      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .desc {
        position: relative;
        top: 65%;
        transform: translateY(-65%);
        width: 100%;
        padding: 0 60px;
        overflow: auto;
        color: var(--c_white);
        text-align: center;

        .big-title {
          margin-bottom: 10px;
        }

        .title {
          padding-top: 12px;
          margin-bottom: 44px;
          text-align: center;

          &::before {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            display: block;
            width: 37px;
            height: 2px;
            margin-top: -13px;
            background: var(--c_white);
          }
        }
      }
    }

    picture {
      img {
        display: block;
        width: 100%;
        max-height: 287px;
        object-fit: cover;
      }

      &::after {
        opacity: 0.3;
        background: var(--c_black);
      }
    }
  }
}
