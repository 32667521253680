@use 'cards_hotels_commons' as *;
@use '../../../elements/radio_btn/radio_btn';

@include commonTabletDesktop {
  .card.hotel {
    .only-mobile {
      display: none;
    }

    .info-box-services {
      .opinion-widget {
        display: none;
      }
    }

    &.horizontal-card {
      .call-to-action {
        z-index: 10;
        width: auto;

        &:not(.grand) > div.hint-cnt {
          margin-bottom: 0;
        }
      }

      .text {
        padding-right: 0;
        border-right: 0;

        .text-header {
          margin-bottom: 4px;
        }
      }
    }

    .footer {
      &__disclaimer {
        font-size: 12px;
      }
    }

    .disclaimer {
      &__text {
        margin-left: 10px;
      }
    }
  }

  .card.hotel-light {
    .card-container {
      align-items: stretch;
    }

    .widget {
      margin-left: -6px;
    }

    .price-before {
      margin-bottom: -18px;
    }

    .price-from {
      margin-bottom: 8px;
    }

    .t-price,
    .t-coin {
      @include fontSize(22);
    }

    .cta-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }

    .btn-primary {
      width: 193px;
    }
  }
}

@include tabletStyles {
  @include media($tablet_landscape) {
    .card.hotel {
      &.horizontal-card {
        .text {
          padding-right: 0;
          margin-bottom: 10px;

          .location {
            margin-top: 5px;

            .hotel-name {
              @include fontSize(10);
              line-height: 15px;
            }

            .tripadvisor-cal {
              @include fontSize(9);

              .info-tripadvisor-tooltip {
                @include fontSize(12);
              }
            }
          }
        }
      }

      .call-to-action {
        padding: 0 0 0 25px;

        &:not(.grand) {
          > div {
            white-space: normal;
          }

          .btn-floating-fastbooking {
            white-space: nowrap;
          }

          .separator {
            display: none;
          }
        }
      }
    }
  }

  @include media($tablet) {
    .card.hotel {
      .card-container {
        display: flex;
        flex-direction: column;

        .discounts {
          flex-direction: row;
          justify-content: flex-start;

          .offer-pill {
            margin-left: 0;
            margin-right: 8px;
          }
        }

        .call-to-action {
          width: 100%;

          .btn-primary {
            width: 100%;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
