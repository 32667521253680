@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.shared-social {
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .shared-social-form{
    &__actions{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
