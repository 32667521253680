@use '../../base_css/abstracts/abstracts' as *;

@include commonTabletDesktop {
  .destination-header {
    .internal-nav {
      .menu {
        display: block;
        text-align: center;

        .menu_item {
          display: inline-block;
          float: none;

          &::after {
            top: 50%;
            margin-top: -11px;
          }

          a {
            padding-top: 15px;
          }
        }
      }
    }
  }
}

@include mobileStyles {
  .destination-header {
    .internal-nav {
      overflow-x: auto;
    }
  }
}
