@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.meetings-search-engine {
  position: relative;
  width: 100%;
  height: calc(586px + 39px);
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;

  .media-wrapper {
    height: calc(100% - 39px);
    max-height: 620px;
    overflow: hidden;

    .media-item {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title-block {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding-top: 80px;
  }

  .h1 {
    width: 100%;
    max-width: 460px;
    color: var(--c_white);
    letter-spacing: 0.5px;
  }

  &__fastbooking {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
  }

  &__fastbooking-mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
    width: 100%;
    padding: 10px;
    background-color: white;

    .submit {
      width: 100%;
      text-align: center;
    }
  }
}
