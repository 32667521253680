@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.b-loader {
  // Legacy loader
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--c_white);

  svg {
    width: 64px;
    height: 64px;
  }

  &.fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;

    svg {
      width: auto;
      height: auto;
    }
  }

  &--big {
    svg {
      width: auto;
      height: auto;
    }
  }

  &:not(.hidden) {
    display: flex;
  }

  .loader-title,
  .loader-subtitle {
    font-weight: bold;
    font-family: var(--font-serif);
    text-align: center;
  }

  .loader-title {
    @include fontSize(28);
    @include fontSansBold;
    margin-top: 15px;
    margin-bottom: 0;
    line-height: 38px;
  }

  // new loader variations
  &--default {
    position: relative;
    top: auto;
    left: auto;
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;

    svg {
      width: auto;
      height: auto;
    }
  }

  &__spinner {
    &--primary.spinner {
      svg path {
        fill: var(--c_primary);
      }
    }
  }
}

@include mobileStyles {
  .b-loader {
    .loader-title {
      font-size: 22px;
    }

    .loader-subtitle {
      margin-top: 15px;
      font-size: 16px;
      line-height: 18px;
    }
  }
}
