@use '../../base_css/abstracts/abstracts' as *;

.vue-submenu,
.reb-main-nav {
  .panel {
    .b-last-searches-cards {
      padding: 0;
      margin: 0;

      .last-search-card {
        flex-basis: calc(33.3% - 9px);
        min-height: 181px;
        margin-right: 14px;
        border: 0.7px solid var(--c_grey_200);

        .hidden-img {
          display: none;
        }

        .content {
          padding: 14px 10px 10px 17px;
          border: none;

          &--button {
            display: none;
          }

          &--info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
          }

          .text-segment {
            display: none;
          }

          .resource-title {
            padding: 0;
          }

          .dates {
            padding: 0;
            font-weight: 400;
          }
        }
      }
    }

    .last-search-card {
      min-height: 181px;
      border: 0.7px solid var(--c_grey_200);
    }
  }
}
