@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

body {
  &:not(.inspiration-guide) {
    .mice-header {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 15;
      width: 100%;

      .mice-header-content {
        display: flex;
        margin-bottom: 20px;
      }

      .banner-back {
        height: 40px;
        margin-bottom: 20px;
        background: var(--c_primary);

        a {
          @include icon (arrow-left-fat, after, 'xs') {
            position: absolute;
            top: 3px;
            left: 0;
          }
          position: relative;
          padding-left: 20px;
          color: white;
          line-height: 40px;
        }

        .language-selector {
          a {
            color: var(--c_black_grey_700);
          }
        }
      }

      .logo {
        flex: 0 0 224px;

        .logo-mice-img {
          display: block;
        }

        &.dark {
          display: none;
        }
      }

      .main-nav {
        position: relative;
        z-index: 15;
        flex: 0 1 100%;
        align-self: center;
        text-align: right;

        > ul {
          li {
            display: inline-block;
            text-align: center;
          }
        }

        li {
          position: relative;
          padding: 0 16px;

          &:first-child {
            padding-left: 0;

            &::before {
              display: none;
            }
          }

          &:last-child {
            padding-right: 0;
          }

          &.has-children {
            @include icon(arrow-down, after, 'xs') {
              margin-top: 2px;
              margin-left: 8px;
              font-size: 12px;
            }
            padding-bottom: 12px;
            margin-bottom: -12px;
            color: var(--c_white);
            font-weight: bolder;
            font-size: 14px;

            .drop-down-links {
              top: 100%;
            }

            .menu_item {
              display: block;
              padding: 8px 30px;

              &:hover {
                background-color: var(--c_blue_green_100);
              }

              a {
                color: var(--c_primary);
                text-decoration: none;

                &:hover::before {
                  display: none;
                }
              }
            }
          }

          &:first-child {
            &.current {
              &::after {
                left: 0;
              }
            }
          }

          &:first-child,
          &:last-child {
            &.current {
              &::after {
                position: absolute;
                left: 19px;
                bottom: -1px;
                content: '';
                width: calc(100% - 38px);
                height: 2px;
                background: var(--c_blue_700);
              }
            }
          }

          a {
            color: white;

            &.phone {
              @include icon(phone, before, 'm') {
                display: inline-block;
                margin-right: 10px;
              }
              font-weight: bold;
              font-size: 14px;
            }
          }
        }

        .language-selector {
          a {
            color: var(--c_primary);
          }
        }

        .locale-form,
        .currency-form {
          @include fontSize(12);
          margin-left: 36px;
          color: white;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.6px;
          text-transform: uppercase;

          select {
            border-color: transparent;
            background-color: transparent;
          }
        }

        .fk-select-label {
          @include icon(arrow-down) {
            margin-left: 5px;
            color: white;
            font-size: 8px;
          }
        }
      }

      .fastbooking {
        z-index: 0;
      }

      &.with-bg {
        position: relative;

        .logo {
          &.dark {
            display: block;
          }

          &.light {
            display: none;
          }
        }

        .main-nav {
          li {
            &.menu_item {
              &::before {
                background-color: var(--c_grey_400);
              }
            }

            a {
              color: var(--c_primary);
            }

            &.current {
              a {
                color: var(--c_blue_700);
              }
            }
          }

          .locale-form,
          .currency-form {
            position: relative;
            color: var(--c_primary);
          }

          .fk-select-label {
            &::after {
              color: var(--c_primary);
            }
          }
        }
      }
    }

    .mobile-mice-header {
      display: none;
    }
  }
}
