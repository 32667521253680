@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

.user-level {
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  justify-content: center;

  &--divisor {
    position: absolute;
    z-index: 1;
    width: 2px;
    height: 10px;
    background: var(--c_white);

    &.divisor-1 {
      right: 75%;
    }

    &.divisor-2 {
      right: 50%;
    }

    &.divisor-3 {
      right: 25%;
    }
  }

  &:not(.user-level-extra-info) {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 22px;
    background:
      linear-gradient(
        to right,
        var(--c_sand_horizons) 0%,
        var(--c_sand_horizons) 25%,
        var(--c_ocean_horizons) 25%,
        var(--c_ocean_horizons) 50%,
        var(--c_sky_horizons) 50%,
        var(--c_sky_horizons) 75%,
        var(--c_infinity_horizons) 75%,
        var(--c_infinity_horizons) 100%
      );

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      content: '';
      border-top-right-radius: 22px;
      border-bottom-right-radius: 22px;
    }
  }
}
