@use 'sass:math';
@use '../abstracts/abstracts' as *;
@forward '../../themes/themes.scss';

@include commonStyles {
  $f_default_size: 16;
  $f_default_line_height: 24px;

  html,
  body,
  ol,
  ul,
  address,
  blockquote,
  pre,
  code,
  kbd,
  samp,
  q,
  sub,
  sup,
  small,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font-size:   #{$f_default_size}px; ---- Comentado por Julia
    $rem_size: math.div($f_default_size, 16);
    margin: 0;
    font-family: var(--body-font);

    // font-size:   #{$rem_size}rem;
    // line-height: $f_default_line_height; ---- Comentado por Julia
    // text-align: left; ---- Comentado por Julia
    // font-weight: 400; ---- Comentado por Julia
    // color: var(--c_dark_grey); ---- Comentado por Julia
  }

  // Comentario de Julia ----------------------------------------------
  // Podemos quitar estas reglas de las etiquetas y ponerlas únicamente en el body?
  // Si quiero poner todo el contenido de un div en otro color o alineado en otro sentido, me obliga a sobreescribir esas reglas para cada elemento
  // Dejándolos en el body, podemos aplicar los estilos por defecto y sobreescribirlos con más facilidad
  body {
    color: var(--c_grey_500);
    font-weight: 400;
    font-size: #{$f_default_size}px;
    line-height: $f_default_line_height;
    text-align: left;
  }

  // ----------------------------------------------
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: inherit;
  }

  //
  // Listas
  //

  // ul li { list-style: disc outside; }
  // ol li { list-style: decimal outside; }
  ul,
  ol,
  li {
    list-style: none;
  }

  li {
    line-height: $f_default_line_height;
  }

  ol,
  ul {
    padding: 0;
    margin: 0;
  }

  // wysiwyg editor
  // revert value restores to the browser default value
  [data-base-text='true'] {
    ul,
    ol,
    li {
      list-style: revert;
    }

    ol,
    ul {
      padding: revert;
      margin: revert;
    }
  }

  .block-title {
    .l-external {
      @include fontSize(16);
      line-height: 24px;

      @include media($mobile) {
        @include fontSize(12);

        &::after {
          font-size: 10px;
        }
      }
    }
  }

  //
  // Enlaces
  //
  a,
  button {
    color: var(--color-link-primary);
    text-decoration: none;

    &:focus,
    &:hover {
      outline: 0;
      text-decoration: none;
    }
  }

  // Transformaciones de texto
  .line-through {
    text-decoration: line-through;
  }

  // Justificaciones
  .txt-r {
    text-align: right;
  }

  .txt-c {
    text-align: center;
  }

  .txt-l {
    text-align: left;
  }
}
