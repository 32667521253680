@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.info-level {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0 38px 24px;
  border-left: 1px solid var(--c_grey_100);

  &--title {
    @include fontSize(16);
    margin-bottom: 7px;
    color: var(--c_blue_700);
    letter-spacing: normal;
  }

  .level-icons {
    @for $i from 1 through 3 {
      &.user-level-#{$i} {
        .image {
          &:nth-child(n+#{$i + 1}) {
            img {
              filter: brightness(0%) contrast(1000%) opacity(0.2);
            }
          }
        }
      }
    }
    position: relative;
    display: flex;

    .image {
      display: flex;
      flex: 1 0 25%;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;

      img {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
      }
    }
  }

  .hidden-img {
    display: none;
  }

  .description {
    margin-top: 5px;
    margin-bottom: 16px;

    &.show-on-mobile-menu {
      display: none;
    }
  }
}
