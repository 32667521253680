@use '../../../src/base_css/abstracts/abstracts' as *;

@include commonStyles {
  a {
    &:not([class]),
    &.negative,
    &.inline,
    &.form-link,
    &.open-link,
    &.msg-do-nothing,
    &.l-down,
    &.l-right,
    &.l-external,
    &.export-csv-link,
    &.multi-modal,
    &.change-password-link {
      @include link-base;
      @include negativable;
      @include downable;
      @include rightable;
    }
  }

  .block,
  .fancybox-container,
  .modal {
    * {
      @include link-styles;
    }
  }

  a#menu_item_hotel_a.fake-link.utag-menu-hotel::after {
    content: '';
    border: 1px solid var(--c_blue_700);
  }

  .fake-link {
    // may apply to tags different to "a"
    @include link-base;
    @include negativable;
    @include downable;
    @include rightable;
  }

  .d-link {
    @include fontSize(14);

    // dropdown link
    @include icon(arrow-down-fat, after, 'xs') {
      margin-top: 2px;
      margin-left: 8px;
    }
    position: relative;
    display: inline-flex;
    align-items: center;
    color: var(--color-link-primary);
    font-weight: var(--sans-bold);
    font-family: var(--body-font);
    line-height: 20px;
    text-decoration: none;

    &.active {
      @include icon(arrow-up-fat);
      @include interactiveElement;
    }

    &::before {
      display: none;
    }

    &:hover,
    &:focus {
      @include interactiveElement;

      &::before {
        display: none;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .b-link{
    text-decoration: none;
  }

  .s-link {
    @include fontSize(14); // link with icon
    position: relative;
    display: inline-flex;
    align-items: center;
    color: var(--color-link-primary);
    font-weight: var(--sans-bold);
    font-family: var(--body-font);
    line-height: 20px;

    &::after,
    &::before,
    .s {
      color: var(--color-link-primary);
      pointer-events: none;
    }

    &::after {
      margin-right: 0;
    }

    &::before {
      margin-left: 0;
    }

    &.hidden {
      display: none;
    }
  }

  .open-link {
    display: inline-block;
    padding: 10px 16px;
    border-radius: 25px;
    background: var(--c_white);
    color: var(--color-link-primary);
    transition: background 0.2s ease-in-out;

    .s {
      padding-right: 2px;

      &::after {
        font-size: 28px;
        line-height: 20px;
      }
    }

    &::before {
      display: none;
    }

    &:hover {
      background: var(--c_background_hsl);
      text-decoration: none;
    }

    &.hidden {
      display: none;
    }
  }

  .with-underline-animation {
    position: relative;
    display: inline-block;

    &::after {
      transform: translateY(10px) scale(0.8);
      content: '';
      opacity: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: var(--c_white);
      transition:
        transform 0.5s,
        opacity 0.5s;
    }

    &:hover {
      &::after {
        transform: translateY(2px) scale(1);
        opacity: 1;
      }
    }

    &--primary {
      &::after {
        background: var(--c_primary);
      }
    }
  }
}

@include desktopStyles {
  .s-link {
    &:hover {
      color: var(--c_dark_grey);

      &::after,
      &::before,
      .s {
        color: var(--c_dark_grey);
      }
    }
  }
}

@include mobileStyles {
  a {
    &:not([class]),
    &.negative {
      &::before {
        display: none;
      }
    }
  }
}
