@use 'meetings_search_engine_commons' as *;
@use '../../shared/fastbooking_meetings/fastbooking_meetings';
@use '../../shared/elements/embedded_video/embedded_video';

.meetings-search-engine {
  height: calc(100vh - 10px);

  .media-wrapper {
    height: calc(100vh - 40px);
    max-height: none;
  }
}

@include commonTabletDesktop {
  .meetings-search-engine {
    .fastbooking {
      position: relative;
      z-index: 16;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1; /* Firefox */
      }

      .top-band {
        display: none;
        background-color: var(--c_background);

        .wrapper {
          display: flex;
          align-items: center;
          padding: 0 24px 0 18px;
        }
      }

      .wrapper {
        position: relative;

        .container {
          position: relative;
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 80px;
          box-shadow: 0 12px 17px 3px rgb(0 0 0 / 4%);
          background: var(--c_white);

          input {
            @include fontSize(16);
            display: block;
            padding: 0;
            border: none;
          }

          > .input {
            position: relative;
          }

          > .input:not(.submit) {
            min-width: 110px;
            border-right: solid 1px var(--c_grey_200);

            &.focused,
            &:focus {
              border-color: var(--c_primary);
              outline: none;
              background: var(--c_blue_green_100);

              input {
                background: var(--c_blue_green_100);
              }
            }
          }

        }
      }

      .t-fb-label {
        display: block;
        color: var(--c_dark_grey);
        white-space: nowrap;
      }

      select {
        &.chosen-select {
          visibility: hidden;
          display: none;
        }
      }

      .chosen-container {
        cursor: pointer;
        width: 100% !important;
        height: 22px;
        margin-top: 8px;

        .chosen-drop {
          cursor: default;
          width: 1200px;
          margin-top: 38px;
          margin-left: -15px;
          border: none;
          border-radius: 0;
          box-shadow: 0 0 0 transparent;
          background: transparent;
          background: var(--c_white);
        }

        .chosen-results {
          z-index: 2;
          display: flex;
          width: 100%;
          height: 515px;
          max-height: 515px;
          padding: 0;
          margin: 0;
          border: 1px solid var(--c_primary);
          background: var(--c_white);

          // When hotels result is empty, set order to 1 to make it appear last
          &--empty {
            order: 1;
          }

          .text__highlight {
            background: none;
            font-weight: 600;
          }

          .no-results {
            position: relative;
            padding: 10px 40px;
            line-height: 22px;

            .close-no-results {
              @include icon(close-fat, before, 'xs') {
                color: var(--c_primary);
              }
              position: absolute;
              top: 10px;
              right: 20px;
            }
          }

          .group-result {
            padding: 14px 32px;
            color: var(--c_blue_700);
            font-weight: 600;
            text-transform: uppercase;

            &.suggestions {
              display: none;
            }

            &.last-searches {
              display: none;
            }
          }

          .group-option {
            padding: 12px 30px;
            color: var(--c_black_grey_700);
            font-weight: normal;

            &.last-search-opt {
              margin: 0;
            }

            &:hover,
            &.highlighted-keyboard {
              background: var(--c_blue_green_100);
            }

            &.featured {
              display: none;
            }

            .value {
              @include fontSize(16);
              position: relative;
              margin-bottom: 3px;
              line-height: 24px;
            }

            .element-title {
              margin-right: 8px;
            }
          }

          &.icon {
            .group-option[data-allow-children='true'] {
              .value {
                @include icon(kids-free, after, 'm') {
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  display: block;
                  color: var(--c_primary);
                }
                display: block;
                padding-right: 30px;
              }
            }
          }
        }
      }
    }
  }

  .mice-header {
    .fastbooking {
      z-index: 0;
    }
  }

  @include media($max-width) {
    .meetings-search-engine {
      .fastbooking {
        > .wrapper {
          padding: 0 10px;
        }

        .t-fb-label {
          @include fontSize(11);
          letter-spacing: 1px;
        }

        .chosen-container {
          .chosen-results,
          .confirm-box {
            min-width: 245px;
            background: var(--c_white);
          }
        }
      }
    }
  }
}

@include tabletStyles {
  .fastbooking {
    .chosen-container {
      .chosen-drop {
        max-width: calc(100vw - 20px);
        margin-left: -7px;

        .chosen-results {
          height: 440px;
          max-height: 440px;
        }
      }
    }

    .group-result,
    .active-result {
      padding: 10px 15px;
    }

    > .wrapper {
      .container {
        .input-text,
        .t-link,
        .check-in,
        .check-out, {
          font-size: 12px;
        }

        .t-fb-label {
          font-size: 9px;
        }
      }
    }
  }
}
