@use '../../base_css/abstracts/abstracts' as *;
@use './ihg_button_commons';

.ihg-button,
.ihg-button.logged {
  @include fontSize(12);
  margin-right: 10px;
  color: var(--c_black_grey_700);
  font-weight: 600;
  line-height: 18px;
  text-align: center;

  .before-login:not(.hidden),
  .after-login:not(.hidden) {
    display: inline-block;
    margin-top: 4px;
  }

  .s-icon-ihg::before {
    @include fontSize(24);
    content: '\f2da';
    display: inline-block;
    color: inherit;
    font-weight: 400;
    font-family: iberostar-symbols;
    line-height: 1em;
  }
}
