@use 'cookies_content_commons' as *;

@include desktopStyles {
  .cookies-content {
    .collapsible-blocks {
      cursor: pointer;
    }
  }

  .accept-all-float-button {
    position: absolute;
    top: 37px;
    right: 43px;
    z-index: 1;
    background-color: var(--c_blue_700);

    &:focus,
    &:hover {
      border: 1px solid var(--c_blue_700);
      background-color: var(--c_white) !important;
      color: var(--c_blue_700);
    }
  }
}
