@use '../abstracts/abstracts' as *;

// --------------------
// ------ COLORS ------
// --------------------
.c-primary {
  color: var(--c_primary) !important;
}

.c-cosmos {
  color: var(--c_blue_700) !important;
}

.c-white {
  color: var(--c_white) !important;
}

.c-grey-light {
  color: var(--c_grey_100) !important;
}

.c-grey {
  color: var(--c_grey_200) !important;
}

.c-concrete {
  color: var(--c_grey_400) !important;
}

.c-dark-grey {
  color: var(--c_dark_grey) !important;
}

.c-black-grey {
  color: var(--c_black_grey_700) !important;
}

.c-black {
  color: var(--c_black) !important;
}

.c-green {
  color: var(--c_green) !important;
}

.c-danger {
  color: var(--c_danger) !important;
}

// --------------------
// ------ LAYOUT ------
// --------------------
.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.w-100 {
  width: 100% !important;
}

.text-nowrap {
  text-wrap: nowrap;
}

// --------------------
// ------ TEXT ------
// --------------------
.font-open-sans {
  font-family: var(--font-sans);
}
