@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.theme-beachfront.waves {
  // TODO: 72338 -> Cuidado al mergear por el proyecto de fastbooking.
  --fastbooking-margin-wrapper: -40px auto 20px;

  /** Generic **/
  --border-radius: var(--s_waves_border_radius);
  --text-decoration: var(--s_waves_text_decoration);
  --separator-color: var(--c_grey_200);

  /* Button Primary */
  --btn-background-primary: var(--c_waves_red_orange_300);
  --btn-label-color-primary: var(--c_waves_white);
  --btn-background-primary-hover: var(--c_waves_red_orange_700);
  --btn-label-color-primary-hover: var(--c_waves_white);

  /* Button Secondary */
  --btn-background-secondary: transparent;
  --btn-border-color-secondary: var(--c_waves_blue_300);
  --btn-label-color-secondary: var(--c_waves_blue_300);
  --btn-background-secondary-hover: var(--c_waves_blue_300);
  --btn-label-color-secondary-hover: var(--c_waves_white);

  /* Button Negative Primary */
  --btn-background-color-negative-primary: var(--c_waves_white);
  --btn-label-color-negative-primary: var(--c_waves_blue_700);
  --btn-background-color-negative-primary-hover: var(--c_waves_grey_100);

  /* Button Negative Secondary */
  --btn-label-color-negative-secondary: var(--c_waves_white);
  --btn-background-color-negative-secondary: transparent;
  --btn-label-color-negative-secondary-hover: var(--c_waves_blue_700);
  --btn-background-color-negative-secondary-hover: var(--c_waves_white);

  /* Button close */
  --button-close-color: var(--c_waves_blue_300);

  /* Links */
  --color-link-primary: var(--c_waves_blue_300);
  --color-link-primary-hover: var(--c_waves_blue_600);

  /* Title */
  --title-font: var(--f_pp_woodland);
  --color-title-font: var(--c_waves_red_orange_300);
  --line-height: var(--f_waves_line_height);
  --line-height_mobile: var(--f_line_height_title_mobile);
  --weight-title-font: var(--f_font_weight_700);
  --offset-line-height: 0px;

  /* Body font */
  --body-font: var(--f_inter);
  --color-body-font: var(--c_waves_grey_500);
  --weight-body-font: var(--f_font_weight_400);

  /* Background */
  --background-color: var(--c_waves_blue_700);
  --background-color-default: var(--c_grey_100);

  /* Interactives Elements */
  --border-color: var(--c_waves_grey_500);
  --interactive-text: var(--c_waves_blue_300);
  --interactive-background: var(--c_waves_white);
  --interactive-text-hover: var(--c_waves_blue_600);
  --interactive-background-hover: var(--c_waves_blue_100);
  --interactive-text-base: var(--c_grey_800);

  /* Swipper */
  --swiper-theme-color: var(--c_waves_blue_300);
  --swiper-pagination-color: var(--c_waves_blue_300);
  --swiper-pagination-border: 1px solid var(--c_waves_blue_300, transparent);
  --swiper-pagination-bullet-inactive-color: var(--c_white, transparent);
  --swiper-navigation-color: var(--c_waves_blue_300);
  --swiper-navigation-background-color: var(--c_white_opacity_60);
  --swiper-navigation-background-color-hover: var(--c_waves_blue_300);
  --swiper-navigation-border-color: var(--c_waves_blue_300);
  --swiper-scrollbar-bg-color: var(--c_waves_blue_100);
  --swiper-scrollbar-drag-bg-color: var(--c_waves_blue_300);

  /* Wave of change */
  --wave-of-change: var(--c_wave_of_change_waves);

  /* Badges */
  --tag-background-color: var(--c_waves_blue_200);
  --tag-text-color: var(--c_waves_blue_300);
  --tag-background-color-hover: var(--c_waves_blue_300);
  --tag-text-color-hover: var(--c_waves_blue_200);

  /* icons */
  --icon-color-default: var(--c_grey_600);
  --icon-color-theme: var(--c_waves_blue_300);
  --icon-color-theme-hover: var(--c_waves_blue_600);

  /* Fastbooking */
  --fastbooking-icon-color: var(--icon-color-theme);
  --fastbooking-modal-title-font: var(--title-font);
  --fastbooking-modal-color-title-font: var(--color-title-font);
  --fastbooking-submit-button-height: 70%;
  --fastbooking-submit-button-width: 90%;
  --fastbooking-submit-border-style: solid;
  --fastbooking-submit-border-width: 1px; /* Top Right Bottom Left */
  --fastbooking-submit-border-color: var(--separator-color);
  --fastbooking-submit-background: var(--c_waves_red_orange_300);
  --fastbooking-submit-background-hover: var(--c_waves_red_orange_700);
  --fastbooking-submit-label-color: var(--c_waves_white);
  --fastbooking-submit-label-color-hover: var(--c_waves_white);

  /* Newsletter */
  --newsletter-background-green: var(--c_blue_green_400);
  --newsletter-background-default: var(--c_blue_green_100);
  --newsletter-background-white: var(--c_white);
  --newsletter-color-title-default: var(--c_blue_700);
  --newsletter-color-text-default: var(--c_blue_700);
  --newsletter-color-text-error: var(--c_white);
  --newsletter-color-icon-default: var(--c_blue_700);
  --newsletter-color-title-white: var(--c_grey_600);
  --newsletter-color-text-white: var(--c_grey_600);
  --newsletter-color-icon-white: var(--c_grey_600);
  --newsletter-border-white: var(--c_grey_600);
}
