@use '../../../base_css/abstracts/abstracts' as *;
@use 'swiper/scss/pagination';

@at-root {
  :root {
    --swiper-pagination-color: var(--c_primary);
    --swiper-pagination-border:1px solid var(--c_turquoise, transparent);
    --swiper-pagination-bullet-border-radius: 100%;
    --swiper-pagination-bullet-inactive-color: var(--c_white, transparent);
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-right: 8px;
    --swiper-pagination-left: auto;
    --swiper-pagination-bottom: 30px;
    --swiper-pagination-top: auto;
  }
}

.swiper-pagination-bullet {
  border: var(--swiper-pagination-border);
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
}

.ib-swiper{
  &--template-coverflow{
    .swiper-pagination{
      @include fontSize(14);
      @include fontSansSemiBold;
      position: relative;
      top: 0;
      bottom: 0;
      display: flex;
      gap: 5px;
      align-items: center;
      margin: 0;
      text-transform: uppercase;
    }
  }

  &--template-default{
    &.pagination-custom {
      .swiper-pagination{
        @include fontSize(14);
        position: absolute;
        z-index: 1;
        width:  fit-content;
        height: fit-content;
        padding: 2px 7px;
        border-radius: 5px;
        background: var(--c_white);
        font-weight: 700;

      }
    }
  }
}

@include commonTabletDesktop {
  .ib-swiper {
    &--template-default.pagination-custom{
      --swiper-pagination-left: 100px;

      .swiper-pagination{
        left: var(--swiper-pagination-left);

        &.first-slide {
          left: 30px;
        }

        &.last-slide {
          left: 170px;
        }
      }
    }
  }
}

@include mobileStyles {
  .ib-swiper {
    --swiper-pagination-bottom: 10px;

    &--template-default.pagination-custom{
      --swiper-pagination-right: 45px;
      --swiper-pagination-top: 15px;

      .swiper-pagination{
        left: auto;
        right: var(--swiper-pagination-right);
      }
    }
  }
}
