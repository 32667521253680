@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.modal-ihg {
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 16px 8px;
    font-size: 14px;

    .ihg-link {
      cursor: pointer;
      color: var(--c_primary) !important;
      font-weight: 600;
      font-size: 16px !important;
    }
  }
}

.content {
  &__title-modal-ihg {
    margin-bottom: 4px;
    color: var(--c_blue_700);
    font-weight: 700;
    font-family: NoeText, serif;
  }

  &__subtitle-modal-ihg {
    margin-bottom: 16px;
    color: var(--c_blue_700);
    font-weight: 600;
    font-family: inter-regular, sans-serif;

    &.club {
      color: #607985;
    }

    &.slvr {
      color: #7C8286;
    }

    &.gold {
      color: #C7B263;
    }

    &.pltn {
      color: var(--c_black_grey_700);
    }

    &.spre {
      color: #000;
    }

    .circle-user-level {
      width: 10px;
      height: 10px;
      border-radius: 50%;

      &.club {
        background-color: #607985;
      }

      &.slvr {
        background-color: #7C8286;
      }

      &.gold {
        background-color: #C7B263;
      }

      &.pltn {
        background-color: #E2E7EA;
      }

      &.spre {
        background-color: #000;
      }
    }
  }
}
