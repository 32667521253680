/* stylelint-disable scss/dollar-variable-pattern */

/**
  CSS variables using custom properties.
  This file is NOT an abstract like Sass variables
  because it gets compiled into actual code.

  These variables must be keeped into this separate
  file so the CSS rules are not declared over and over
  while CSS is being loaded in the browser.
**/

/**
  Var Colors
**/
// TODO: PY05649-636 Borrar cuando acabemos de designar todo
:root {
  --c_turquoise: #3AA597;
  --c_verde_salvia: #A6C26E;
  --c_red: #EF5350;
  --c_green: #7BD124;
  --c_light_green: #F1FAE9;
  --c_ambar: #FFD54F;
  --c_danger: #EF5350;
  --c_fair_pink: #FFEFEE;
  --c_carrot: #EE8E00;
  --c_grey: #DBDBDB;
  --c_concrete: #7F7F7F;
  --c_dark_grey: #4A4A4A;
  --c_black_grey: #333;
  --c_black: #080808;
  --c_turquoise_no_accessibility: #3AA597;
  --c_turquoise_dark: #B2D3CF;
  --c_turquoise_rgb: 103, 186, 175;
  --c_verde_salvia_rgb: 166, 194, 110;
  --c_red_rgb: 239, 83, 80;
  --c_green_rgb: 123, 209, 36;
  --c_light_green_rgb: 241, 250, 233;
  --c_ambar_rgb: 255, 213, 79;
  --c_danger_rgb: 239, 83, 80;
  --c_carrot_rgb: 238, 142, 0;
  --c_grey_rgb: 219, 219, 219;
  --c_concrete_rgb: 127, 127, 127;
  --c_dark_grey_rgb: 74, 74, 74;
  --c_black_grey_rgb: 51, 51, 51;
  --c_black_rgb: 8, 8, 8;
  --c_cosmos_rgb: 0, 40, 85;

  /**
    var Horizons
  **/

  --c_sand_horizons: #AF9093;
  --c_ocean_horizons: #007C90;
  --c_sky_horizons: #31C1E7;
  --c_infinity_horizons: #524BB9;
  --c_dark_blue_horizons: #002954;
  --c_ocean: var(--c_primary);
  --c_turquoise_secondary: #EDF7F6;
  --c_ocean_secondary: var(--c_blue_green_100);
  --c_cosmos: #002855;
  --c_city: var(--c_cosmos);
  --c_cloud: #F1F2F9;
  --c_cloud_hsl: 232, 40%; // 232, 40%, 96%
  --c_cloud_hsl_2: hsl(var(--c_cloud_hsl), 2%);
  --c_cloud_hsl_90: hls(var(--c_cloud_hsl), 90%);
  --c_cloud_hsl_92: hls(var(--c_cloud_hsl), 92%);
  --c_city_secondary: var(--c_cloud);
  --c_heritage: var(--c_verde_salvia);
  --c_verde_salvia_secondary: #F0F5E8;
  --c_heritage_secondary: var(--c_verde_salvia_secondary);
  --c_grand: var(--c_red);
  --c_red_secondary: #FCECEC;
  --c_grand_secondary: var(--c_red_secondary);
  --c_green_secondary: #F0F9E8;
  --c_ambar_secondary: #FFFBE1;
  --c_danger_secondary: #FCECEC;
  --c_carrot_secondary: #FEF5E8;
  --c_blue_grey: #B2BECC;
  --c_carrot_ada: #B45600;

  /**
    RRSS
  **/
  --c_facebook: #3B5998;
  --c_gplus: #DD4B39;

  /**
    Fonts
  **/

  /** PLEASE keep up to date with SCSS variables in abstracts
  /_variables.scss
  **/
  --font-serif: var(--title-font);
  --font-sans: inter-regular, sans-serif;
  --base_lineHeight: normal;
  --font-base: 16;
  --serif-semi-bold: 600;
  --serif-bold: bold;
  --sans-regular: 400;
  --sans-semi-bold: 600;
  --sans-bold: 700;

  /**
  ************************
  Variables REB
  ************************
  **/

  /**
  The naming of variables starts with the defining characteristic.
  --c: colour
  --s: style
  --f: fonts
  **/

  /* Typefaces */
  --f_noe_display: noe-display-medium, sans-serif;
  --f_pp_woodland: woodland-bold, sans-serif;
  --f_f37_bergman: bergman-regular, sans-serif;
  --f_inter: inter-regular, sans-serif;
  --f_noe:'Noe Text';

  /* Line Height */
  --f_line_height_normal: normal;
  --f_masterbrand_line_height: 61.25px;
  --f_selection_line_height: 61.25px;
  --f_joia_line_height: 56.3px;
  --f_waves_line_height: 48.96px;
  --f_line_height_title_mobile: 40px;

  /* font weights */
  --f_font_weight_100: 100;
  --f_font_weight_200: 200;
  --f_font_weight_300: 300;
  --f_font_weight_400: 400; /* normal */
  --f_font_weight_500: 500;
  --f_font_weight_600: 600;
  --f_font_weight_700: 700; /* bold */
  --f_font_weight_800: 800;
  --f_font_weight_900: 900;

  /** colors **/

  /* Go from 100 to 900, the middle is 500
  we are going to use this color wheel
  (https://blog.leatheredgepaint.com/es/la-rueda-de-color)
  to name the colors, and then we are going to asing a number
  to the color, 100 being the lightest and 900 the darker
  */

  /**
    Structural colors
  **/
  --c_background: #F6F8FC;
  --c_background_hsl: 220, 50%, 95%; // complete hsl code 220, 50%, 97%
  --c_primary: #3AA597;
  --c_primary_rgb: 103, 186, 175;
  --c_facebook_primary: #3A599A;
  --c_white: #FFF;
  --c_white_opacity_40: rgba(255, 255, 255, 40%);
  --c_white_opacity_50: rgba(255, 255, 255, 50%);
  --c_white_opacity_60: rgba(255, 255, 255, 60%);
  --c_white_opacity_70: rgba(255, 255, 255, 70%);
  --c_white_opacity_80: rgba(255, 255, 255, 80%);
  --c_orange_500: #FF5635;
  --c_grey_100: #F6F6F6;
  --c_grey_100_rgb: 246, 246, 246;
  --c_grey_200: #DBDBDB;
  --c_grey_300: #D9D9D9;
  --c_grey_400: #7F7F7F;
  --c_grey_400_rgb: 127, 127, 127;
  --c_grey_500: #6C6C6C;
  --c_grey_600: #4A4A4A;
  --c_grey_600_rgb: 74, 74, 74;
  --c_grey_800: #2B2B2B;
  --c_blue_700: #002855;
  --c_blue_700_rgb: 0, 40, 85;
  --c_black_grey_700: #333;
  --c_black_grey_700_rgb: 51, 51, 51;
  --c_blue_green_100: #EDF7F6;
  --c_blue_green_400: #76C0B6;
  --c_blue_green_700_rgb: 103, 186, 175;

  /* Masterbrand */
  --c_masterbrand_white: #FFF;
  --c_masterbrand_blue_green_100: #F1F9F8;
  --c_masterbrand_blue_green_200: #C8EAE5;
  --c_masterbrand_blue_green_500: #38A596;
  --c_masterbrand_blue_green_700: #1B6E63;
  --c_masterbrand_blue_green_800: #134E46;
  --c_masterbrand_blue_700: #002855;
  --c_masterbrand_grey_100: #CCD4DD;
  --c_masterbrand_grey_500: #6C6C6C;
  --c_masterbrand_grey_700: #4A4A4A;
  --c_wave_of_change_masterbrand: #002855;
  --s_masterbrand_border_radius: 0px;
  --s_masterbrand_text_decoration: 'underline';

  /* Waves */
  --c_waves_white: #FFF;
  --c_waves_blue_100: #BCE8EE;
  --c_waves_blue_200: #EEF8FA;
  --c_waves_blue_300: #19A0B4;
  --c_waves_blue_600: #084C57;
  --c_waves_blue_700: #002855;
  --c_waves_red_orange_300: #FA5D70;
  --c_waves_red_orange_700: #C23645;
  --c_waves_grey_100: #CCD4DD;
  --c_waves_grey_500: #6C6C6C;
  --c_wave_of_change_waves: #002855;
  --s_waves_border_radius: 24px;
  --s_waves_text_decoration: 'underline';

  /* Selection */
  --c_selection_white: #FFF;
  --c_selection_blue_green_100: #F1F9F8;
  --c_selection_green_100: #EFFFC2;
  --c_selection_green_200: #E6FF9D;
  --c_selection_blue_green_200: #C8EAE5;
  --c_selection_blue_green_500: #38A596;
  --c_selection_blue_green_800: #134E46;
  --c_selection_blue_700: #002855;
  --c_selection_grey_100: #D3DAE7;
  --c_selection_grey_500: #6C6C6C;
  --c_selection_grey_700: #4A4A4A;
  --c_wave_of_change_selection: #002855;
  --s_selection_border_radius: 0px;
  --s_selection_text_decoration: 'underline';

  /* Joia */
  --c_joia_white: #FFF;
  --c_joia_orange_100: #FFCDB6;
  --c_joia_orange_300: #FF8E5A;
  --c_joia_grey_100: #D3DAE7;
  --c_joia_grey_500: #6C6C6C;
  --c_joia_grey_600: #695E4A;
  --c_joia_blue_300: #3A63A1;
  --c_joia_blue_500: #234789;
  --c_joia_blue_800: #0C1A3F;
  --c_joia_yellow_100: #F5EAD7;
  --c_joia_yellow_200: #F9F3E8;
  --c_wave_of_change_joia: #234789;
  --s_joia_border_radius: 0px;
  --s_joia_text_decoration: 'underline';

  /* city */
  --c_city_white: #FFF;
  --c_city_blue_green_100: #F1F2F9;
  --c_city_blue_700: #002855;
  --s_city_border_radius: 0px;
  --s_city_text_decoration: 'underline';

  /* heritage */
  --c_heritage_white: #FFF;
  --c_heritage_yellow_orange_500: #ED9B3380;
  --c_heritage_blue_500: #2C5794;
  --c_heritage_green_500: #3C4F25;
  --c_heritage_green_200: #A6C26E;
  --c_heritage_green_400: #789D4A;
  --s_heritage_border_radius: 0px;
  --s_heritage_text_decoration: 'underline';

  /* cuba */
  --c_cuba_white: #FFF;
  --c_cuba_green_700: #235B4E;
  --c_cuba_blue_green_100: #EDF7F6;
  --c_cuba_blue_green_200: #C8EAE5;
  --c_cuba_green_700_rgb: 35, 91, 78;
  --c_cuba_grey_300: #D6D6D6;
  --c_cuba_grey_400: #5C5F66;
  --c_cuba_grey_500: #6C6C6C;
  --c_cuba_black_grey_700: #333;
  --s_cuba_border_radius: 0px;
  --s_cuba_text_decoration: 'underline';

  /* inspiration guide */
  --c_content_site_white: #FFF;
  --c_content_site_blue_green_300: #8DB6AF;
  --c_content_site_blue_green_200: #EEF7F7;
  --c_content_site_blue_800: #00192F;
  --c_content_site_black_900: #14171A;
  --c_content_site_blue_violet_500: #324489;
  --c_content_site_red_500: #C91116;
  --c_content_site_grey_400: #595959;
  --c_content_site_blue_600: #215FA7;
  --c_content_site_article_font_size: 16px;
  --c_content_site_article_font_family: 'sans-serife';
  --c_content_site_title_h2_font_size: 32px;
  --c_content_site_title_h1_font_size: 48px;

  /**
  ************************
  End Variables REB
  ************************
  **/

  $c_grand_secondary: var(--c_red_secondary);

  .ada {
    --c_primary: #117E77;
    --c_verde_salvia: #577A1D;
    --c_green: #5AA012;
    --c_ambar: #C0870D;
    --c_danger: #C83D3A;
    --c_carrot: #D8751A;
    --c_grey_100: #F6F6F6;
    --c_grey_200: #DBDBDB;
    --c_grey_600: #5D5D5D;
    --c_grey_400: var(--c_grey_600);
    --c_black: #080808;
    --c_black_grey_700: var(--c_black);
    --c_primary_rgb: 17, 126, 119;
    --c_verde_salvia_rgb: 87, 122, 29;
    --c_red_rgb: 200, 61, 58;
    --c_green_rgb: 90, 160, 18;
    --c_ambar_rgb: 192, 135, 13;
    --c_danger_rgb: 200, 61, 58;
    --c_carrot_rgb: 216, 117, 26;
    --c_grey_100_rgb: 246, 246, 246;
    --c_grey_rgb: 219, 219, 219;
    --c_grey_400_rgb: var(--c_grey_600_rgb);
    --c_grey_600_rgb: 93, 93, 93;
    --c_black_grey_700_rgb: 8, 8, 8;
    --c_black_rgb: var(--c_black_rgb);
  }
}
