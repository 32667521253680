@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';
@use '../../base_css/skeletons/fake_skeleton';
@use '../../shared/mobile_menu/mobile_menu_mobile';
@use '../../shared/ihg_button/ihg_button_mobile';

@mixin tablet-mobile-styles {
  $nav-height: 65px;

  #partner-logo-mobile {
    z-index: 0;
    width: 130px;

    .partner-logo-item--iberostar,
    .partner-logo-item-menu {
      img {
        max-width: 100px;
      }
    }
  }

  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000; // do not fall below blue top fringe
    justify-content: space-between;
    width: 100%;
    height: $nav-height;
    box-shadow: 2px 2px 8px 0 rgba(var(--c_grey_rgb), 0.5);
    background: var(--c_white);
    transition: transform 0.5s ease-out;

    /* We need to force top 0 on initial state because the mobile header can be
      pushed down dynamically with JavaScript when there are sticky headbands.
      Initial state in mobile-header means that the header is not fixed to the top
      and its positioned based on the body of the page, and in this case the body's
      top position is pushed too with the height of sticky headbands */

    &.initial {
      /* stylelint-disable-next-line declaration-no-important */
      top: 0 !important;
      z-index: 9003;
    }

    &.nav-down {
      z-index: 10;
      transform: translate3d(0, 0, 0);
      background: var(--c_white);
    }

    &.nav-up {
      transform: translate3d(0, -$nav-height, 0);
      background: var(--c_white);
    }

    // For hotel page, applied right after removing initial class
    &.nav-up-after-initial {
      transform: translate3d(0, -$nav-height, 0);
      opacity: 0;
      display: none;
      background: var(--c_white);
    }

    > * {
      flex: 0 0 auto;
    }

    .logo {
      width: 100%;
      padding-right: 10px;
      margin-bottom: 8px;

      picture {
        img {
          &.app-logo {
            img {
              max-width: 80px;
            }
          }

          @media (width <= 399px) {
            max-width: 100%;
          }
        }
      }

      &.logo-staragents {
        justify-content: flex-start;
        height: auto;
      }
    }

    .mobile-button-text {
      display: block;
      margin-top: 4px;
      color: var(--c_primary);
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;

      &:hover {
        text-decoration: none;
      }
    }

    .mobile-back {
      width: 45px;
      color: var(--c_blue_700);
      font-size: 20px;
      line-height: 60px;
      text-align: center;
    }

    .mobile-user {
      position: relative;
      cursor: pointer;

      .circle {
        position: absolute;
        top: -1px;
        left: calc(50% - 14px);
        display: inline-block;
        width: 9px;
        height: 9px;
        border: 1px solid var(--c_white);
        border-radius: 50%;
        background-color: var(--c_danger);
      }

      .user-image {
        @include fontSize(12);
        width: 24px;
        height: 24px;
        margin: auto;

        .user-image-letter {
          margin-top: 2px;
        }

        &.without-name {
          .user-image-letter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            overflow: hidden;
            border-radius: 50%;
            background-color: var(--c_blue_700);

            &::after {
              color: var(--c_white);
              font-size: 28px;
            }
          }
        }
      }

      .user-image-with-avatar {
        > img {
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }
    }

    .mobile-user,
    .mobile-call-center {
      // display: grid;
      // grid-template-columns: 1fr;
      // align-self: center;
      // width: auto;
      // padding: 0 4px;
      text-align: center;

      & > .s {
        display: block;
        font-size: 24px;
      }

      .mobile-button-text {
        @include fontSize(12);
        display: block;
        font-weight: var(--sans-semi-bold);
        line-height: 18px;
      }
    }

    .mobile-call-center {
      position: relative;
      padding-right: 10px;
      margin-bottom: 10px;

      .mobile-button-text {
        color: var(--c_primary);
      }
    }

    .ihg-button {
      padding-right: 5px;
      margin-bottom: 10px;
    }

    .hotel-one-landing-page &,
    .transparent-header & {
      .logo-green {
        display: block;
      }

      .logo-white {
        display: none;
      }

      &.fake-header-menu {
        transform: translate3d(0, 0, 0);
        background: var(--c_white);
        transition: none;
      }

      &.initial {
        position: absolute;
        box-shadow: none !important;

        .mobile-menu-toggle {
          z-index: 1;

          span {
            color: var(--c_white);
          }

          &::before,
          &::after {
            background: var(--c_white);
          }
        }

        .mobile-call-center,
        .ihg-button {
          color: var(--c_white);

          .mobile-button-text {
            color: var(--c_white);
          }
        }

        .logo-green {
          display: none;
        }

        .logo-white {
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }

    &.hidden {
      display: none;
    }

    .w-headband.hotel-one-landing-page &.initial,
    .w-headband .transparent-header &.initial {
      margin-top: 0.2%;

      &.nav-down {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  body {
    &:not(.inspiration-guide) {
      .mobile-menu-toggle {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        width: 40px;
        min-height: 60px;
        margin: 0 10px;

        &::after,
        &::before {
          position: relative;
          top: -8px;
          content: '';
          display: block;
          flex: 0 0 auto;
          width: 40px;
          height: 2px;
          border-radius: 2px;
          background: var(--c_black);
          transition: transform 0.2s;
        }

        &::before {
          transform: translateY(-4px);
        }

        &::after {
          transform: translateY(4px);
        }

        &__text {
          @include fontSize(12);
          position: absolute;
          left: 50%;
          bottom: 4px;
          transform: translateX(-50%);
          text-align: center;
        }

        &.ibp-toggle {
          span {
            bottom: 0;
          }
        }
      }
    }
  }

  /* Fix to avoid menu jump the first time you click on the toggle button */
  #vue-mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9001;
    transform: translate3d(-100%, 0, 0);
    width: $menu-width;
    height: 100%;
    padding-top: 65px;
    background-color: var(--c_white);
    transition: transform 0.5s ease-out;

    .js-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .opened-menu {
    .mobile-menu-toggle {
      z-index: 1;

      &::before {
        transform: translateY(1px) translateX(4px) rotate(-45deg);
        width: 31px;
      }

      &::after {
        transform: translateY(-1px) translateX(4px) rotate(45deg);
        width: 31px;
      }
    }

    #vue-mobile-menu {
      transform: translate3d(0, 0, 0);
    }

    .mobile-header {
      position: fixed;
      top: 0;
      z-index: 10001;
    }
  }

  .opened-left {
    body {
      padding: 0;
    }

    .mobile-header {
      border-left: 1px solid #DDD;
    }

    .mobile-menu-toggle {
      &::before {
        transform: translateY(1px) rotate(-45deg);
        width: 31px;
      }

      &::after {
        transform: translateY(-1px) rotate(45deg);
        width: 31px;
      }
    }
  }

  .profiler-results {
    display: none;
  }
}

@include commonTabletMobile {
  @include tablet-mobile-styles;

  .mobile-header {
    margin-top: 0;
  }
}

// Tablet - Small Desktop Style
@include tabletStyles {
  .main-header.burgermenu-sticky-head {
    .mobile-user {
      position: relative;
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr;
      align-self: center;
      width: auto;
      margin-left: 15px;
      color: var(--c_primary);
      text-align: center;

      & > .s {
        display: block;
        font-size: 24px;
      }

      .circle {
        position: absolute;
        top: -1px;
        left: calc(50% - 14px);
        display: inline-block;
        width: 9px;
        height: 9px;
        border: 1px solid var(--c_white);
        border-radius: 50%;
        background-color: var(--c_danger);
      }

      .user-image {
        @include fontSize(12);
        width: 24px;
        height: 24px;
        margin: auto;

        .user-image-letter {
          margin-top: 2px;
        }

        &.without-name {
          .user-image-letter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            overflow: hidden;
            border-radius: 50%;
            background-color: var(--c_blue_700);

            &::after {
              color: var(--c_white);
              font-size: 28px;
            }
          }
        }
      }

      .user-image-with-avatar {
        > img {
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }

      .mobile-button-text {
        @include fontSize(12);
        display: block;
        font-weight: var(--sans-semi-bold);
        line-height: 18px;
      }
    }

    .w-headband &.initial {
      margin-top: 4%;
    }
  }

  .opened-menu {
    .mobile-menu-toggle {
      &::before {
        top: -12px;
      }

      &::after {
        top: -36px;
      }
    }
  }

  .mobile-menu-toggle {
    &::before {
      transform: translateY(-6px);
    }

    &::after {
      transform: translateY(-20px);
    }
  }
}

@media only screen and (min-width: $tablet_portrait) and (width <= 900px) {
  .main-header.burgermenu-sticky-head {
    .w-headband &.initial {
      margin-top: 9.5%;
    }
  }
}

@media screen and (width >= 768px) and (width <= 1024px) and (orientation: landscape) {
  .opened-menu {
    .mobile-menu-toggle {
      &::after {
        top: -36px;
      }

      &::before {
        top: -12px;
      }
    }
  }
}

@media only screen and (width >= 768px) and (max-height: $mobile) and (orientation: landscape) {
  .opened-menu {
    .mobile-menu-toggle {
      &::after {
        top: -12px;
      }

      &::before {
        top: -12px;
      }
    }
  }
}
