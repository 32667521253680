@use '../../base_css/abstracts/abstracts' as *;
@use '../../base_css/global/animations/rings';
@use '../../base_css/global/animations/rings2';

@include commonTabletDesktop {
  .notification-bell {
    @include fontSize(24);
    color: var(--c_black_grey_700);
    line-height: 24px;

    &:hover {
      color: var(--c_primary);
    }

    .s {
      position: relative;
      cursor: pointer;
    }

    &.ring {
      .s-ico-bell {
        display: block;
        animation: ring2 0.3s 0s ease-in-out 5;
      }
    }

    &.has-unread-notifications {
      .circle {
        position: absolute;
        top: 1px;
        left: 0;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--c_danger);
      }
    }
  }
}
