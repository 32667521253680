@use 'fastbooking_meetings_commons' as *;

@include desktopStyles {
  .fastbooking.meetings {
    .wrapper .container {
      .place-to-go-mice {
        padding: 8px 10px;
      }

      .fake-select-mice {
        line-height: 6px;
      }
    }

    .chosen-container .chosen-drop {
      margin-top: 8px;
    }
  }
}

@include commonTabletDesktop {
  #fastbooking-meetings {
    .fastbooking.meetings {
      .wrapper .container {
        flex-wrap: nowrap;
        height: auto;
        padding: 16px 16px 8px;

        .place-to-go-mice {
          min-width: 300px;
        }

        .budget,
        .specialties {
          min-width: 250px;
        }

        .place-to-go-mice,
        .budget,
        .specialties,
        .people,
        .rooms {
          margin: 0 8px 8px 0;
          border: 1px solid var(--c_grey_200);
        }
      }
    }

    .people input {
      width: 100%;
    }
  }

  // HOTFIX
  // Pese a estos estilos, el FB
  // sticky de mice estaba desmaquetado.
  //
  // Conservamos los estilos del no-sticky.
  //
  // .fastbooking.meetings.sticky {
  //   .wrapper .container {
  //     width: calc(100% + 22px);

  //     .place-to-go-mice {
  //       min-width: 240px;
  //     }

  //     .budget,
  //     .specialties {
  //       min-width: 200px;
  //     }

  //     .rooms,
  //     .people {
  //       flex: 1 1 100%;
  //       min-width: auto;
  //     }

  //     .place-to-go-mice,
  //     .budget,
  //     .specialties,
  //     .rooms,
  //     .people {
  //       border-top: 1px solid var(--c_grey_200);
  //       border-right: 0;
  //       border-bottom: 1px solid var(--c_grey_200);
  //       border-left: 1px solid var(--c_grey_200);
  //     }
  //   }

  //   .chosen-container {
  //     .chosen-drop {
  //       .chosen-choices,
  //       .confirm-box {
  //         min-width: auto;
  //       }

  //       .confirm-box {
  //         flex-direction: column;
  //         padding: 10px;

  //         .clear-all {
  //           margin-top: 10px;
  //           margin-right: 0;
  //         }
  //       }
  //     }
  //   }

  //   &,
  //   .floating-fastbooking & {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     transform: translateY(0);
  //     width: 100%;
  //     margin: 0;
  //     transition: transform 100ms ease-in-out;

  //     &.hidden {
  //       transform: translateY(-100%);
  //       display: block;
  //     }
  //   }

  //   &,
  //   .mice-header & {
  //     box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  //     background-color: white;

  //     .container {
  //       border: none;
  //       border-radius: 0;
  //       box-shadow: none;
  //     }

  //     .fastbooking-links {
  //       display: none;
  //     }
  //   }

  //   & {
  //     .wrapper > .container {
  //       .place-to-go-mice,
  //       .people,
  //       .rooms,
  //       .budget,
  //       .specialties,
  //       .action {
  //         margin: 0;
  //       }
  //     }
  //   }

  //   &,
  //   .floating-fastbooking & {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     transform: translateY(0);
  //     width: 100%;
  //     margin: 0;
  //     transition: transform 100ms ease-in-out;

  //     &.hidden {
  //       transform: translateY(-100%);
  //       display: block;
  //     }
  //   }

  //   &,
  //   .mice-header & {
  //     padding: 16px 0 0;
  //     background-color: white;

  //     .top-band,
  //     .fastbooking-links {
  //       display: none;
  //     }
  //   }
  // }
}

@include tabletStyles {
  .fastbooking.meetings {
    &.focused {
      .wrapper .container {
        height: auto;
      }
    }

    .top-band {
      .wrapper {
        .subject {
          max-width: 266px;
        }
      }
    }

    > .wrapper {
      padding: 0;
    }

    .wrapper {
      .container {
        height: auto;

        .place-to-go-mice,
        .rooms,
        .people,
        .budget,
        .specialties {
          padding: 12px 8px 8px;
        }

        .place-to-go-mice {
          max-width: 265px;
        }

        .fake-select-mice {
          line-height: 12px;
        }

        .chosen-container {
          .chosen-drop {
            width: calc(100% + 18px);
          }

          &.chosen-container-active {
            .chosen-drop {
              left: 1px;
              margin-top: 4px;
            }
          }
        }
      }

      .action {
        .quote {
          width: auto;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
}
