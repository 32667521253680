@use '../abstracts/abstracts' as *;

@include commonStyles {
  //
  // Parpadeo, se usa por ejemplo en los puntos de los relojes
  //
  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }

  // ---------------------------------------------------------------- //

  // Efecto de typing para la búsqueda
  @keyframes type {
    from {
      width: 0;
    }

    to {
      width: 120px;
    }
  }

  // ---------------------------------------------------------------- //

  @keyframes type-mobile {
    from {
      width: 0;
    }

    to {
      width: auto;
    }
  }

  // ---------------------------------------------------------------- //

  // Animación del skeleton
  @keyframes placeHolderShimmer {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  //---------------------------------------------------------------- //

  @keyframes greetings-fade-in {
    0% {
      visibility: visible;
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    80% {
      opacity: 1;
    }

    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

  // ---------------------------------------------------------------- //
  // New animations for home tabs
  @keyframes show-block-info {
    0% {
      transform: translateY(0%) translateX(0%) scale(1);
      opacity: 1;
    }

    15% {
      opacity: 0.3;
    }

    30% {
      transform: translateY(50%) translateX(-50%) scale(0);
      opacity: 0;
    }

    31% {
      transform: translateY(-50%) translateX(-50%) scale(0);
      opacity: 0;
    }

    75% {
      opacity: 0.8;
    }

    100% {
      transform: translateY(0%) translateX(0%) scale(1);
      opacity: 1;
    }
  }

  @keyframes fade-in-out {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }

  // ---------------------------------------------------------------- //
  // New animations for mobile sections

  @keyframes hide-text {
    0% {
      transform: translateX(0%);
      opacity: 1;
    }

    85% {
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
      opacity: 0;
    }
  }

  @keyframes button-bounce {
    0% {
      transform: translateX(0%);
    }

    75% {
      transform: translateX(100%);
      opacity: 1;
    }

    100% {
      transform: translateX(calc(100% - 54px));
    }
  }

  @keyframes round-border {
    0% {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    85% {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    100% {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }

  @keyframes show-button {
    0% {
      transform: translateX(90%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes show-dropdown-items {
    0% {
      transform: translateY(1px);
      margin: 0 auto;
      border: 1px solid var(--c_white);
      background: var(--c_white);
    }

    100% {
      transform: translateY(-12px) scale(0.8);
      margin: 0 auto;
      border: 1px solid var(--c_white);
      background: var(--c_white);
    }
  }
}

@keyframes side-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes side-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes side-slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes side-slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes side-slide-top-in {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes side-slide-top-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}
