@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  .user-image {
    @include fontSerifBold;
    @include fontSize(54);
    display: none;
    width: 120px;
    height: 120px;

    &.user-image-with-letter {
      align-items: center;
      justify-content: center;

      .user-image-letter {
        font-family: Georgia;
        text-transform: uppercase;
      }

      &.without-name {
        width: auto;

        .user-image-letter {
          &::after {
            @include fontSize(24);
            color: var(--c_blue_700);
            line-height: 1.5em;
          }
        }
      }

      &.with-name {
        border-radius: 50%;
        background-color: var(--c_blue_700);
        color: var(--c_white);

        .user-image-letter {
          &::after {
            display: none;
          }
        }
      }

      &.user-image-flex {
        display: inline-flex;
      }
    }

    &.user-image-with-avatar {
      display: inline-flex;

      > img {
        max-width: 100%;
        border-radius: 50%;
      }
    }
  }
}
