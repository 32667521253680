@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  .fastbooking.meetings {
    .top-band {
      .wrapper {
        padding: 0;

        .subject {
          width: 100%;
          max-width: 336px;
          padding: 13px 0 13px 15px;
          border-right: 1px solid var(--c_grey_200);
          background-color: var(--c_cloud);
          color: #00244E;
          font-weight: bold;
          font-size: 18px;
        }

        .fastbooking-links {
          padding: 13px 15px;
        }
      }
    }

    .wrapper {
      .container {
        .input {
          cursor: pointer;
          height: 64px;

          input {
            border: 0;
          }
        }

        .input:not(.submit).focused input {
          background-color: transparent;
        }
      }
    }

    .chosen-choices {
      cursor: pointer;
      padding: 0;
      border: 0;
      box-shadow: 0 0 0 0;
      background: var(--c_white);

      > li {
        @include icon(check, after, 'xs') {
          position: absolute;
          top: 12px;
          right: 10px;
          width: 20px;
          height: 20px;
          background: var(--c_green);
          color: var(--c_white);
          line-height: 24px;
          text-align: center;
        }
        position: relative;
        float: none;
        padding: 0;
        padding: 9px 10px 8px;
        font-size: 18px;
        font-size: 1.125rem;
        font-family: var(--font-sans);
        line-height: 22px;

        &.search-choice {
          position: relative;
          margin: 0;
          border: 0;
          border-radius: 0;
          background: var(--c_cloud);
          background-image: unset;

          &:hover {
            background: var(--c_white);
          }

          .search-choice-close {
            position: absolute;
            inset: 0;
            z-index: 2;
            width: 100%;
            height: unset;
          }
        }

        &.search-field {
          &::after {
            display: none;
          }

          input[type='text'] {
            cursor: pointer;
            color: transparent;
          }
        }
      }
    }

    .counter {
      @include fontSize(16);
      position: absolute;
      bottom: 0;
      cursor: pointer;
      margin: 0;
      background: transparent;
    }

    .chosen-results {
      width: auto;
      min-width: 0;
      height: auto;
      max-height: 365px;
      margin-top: 0;
      overflow: hidden auto;
      border: none;
      box-shadow: 0 0 0 0;

      > li {
        position: relative;

        &::after {
          position: absolute;
          top: 8px;
          right: 15px;
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid #979797;
        }

        &.result-selected {
          display: none;

          &.hidden {
            display: none;
          }

          &::after {
            border: 0;

            //background: url('../../../images/interface/green-check.svg'); see if we can use inline SVG
          }
        }

        &.destinations,
        &.hotels {
          border-bottom: 1px solid #979797;

          &::after {
            border: 0;
          }
        }
      }
    }

    .budget,
    .specialties {
      .chosen-container {
        height: 24px;
        margin-top: 0;

        .chosen-drop {
          .confirm-box {
            min-height: 0;
            padding: 10px;

            .secondary-btn {
              margin-right: 0;
            }
          }
        }

        > input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 24px;
          background-color: transparent;
        }
      }

      .chosen-choices,
      .chosen-results {
        > li {
          padding: 10px;
          font-size: 16px;
        }
      }

      .counter {
        @include fontSize(16);
        position: relative;
        white-space: nowrap;
      }

      .chosen-container-active {
        .chosen-drop {
          top: 20px;
          left: -1px;
          right: -15px;
          width: calc(100% + 22px);
          white-space: nowrap;

          &::before {
            right: 10px;
          }
        }
      }
    }

    .chosen-drop {
      box-shadow: 0 10px 50px 0 rgb(0 0 0 / 31%);
      background: var(--c_white);
    }

    .secondary-btn {
      cursor: pointer;
      margin-right: 10px;
    }

    .clear-all {
      @include fontSize(14);
      cursor: pointer;
      margin-right: 20px;
      color: var(--c_primary);
      font-weight: var(--sans-bold);
      font-family: var(--font-sans);
      line-height: 20px;
      text-decoration: none;
    }

    // copied styles from standard fastbooking chosen-results
    .chosen-list {
      z-index: 1011;
      padding: 0;
      margin: 20px 0 0;
      border-radius: 2px;
      box-shadow: 0 1px 2px 0 rgb(117 124 133 / 23%);
      background: #ECF6FD;

      li {
        position: relative;
        padding: 0;
        padding: 9px 10px 8px 50px;
        color: #8B9199;
        font-size: 18px;
        font-size: 1.125rem;
        font-family: var(--font-sans);
        line-height: 22px;

        &::after {
          position: absolute;
          top: 10px;
          right: 30px;
          content: '';
          display: block;
          width: 20px;
          height: 20px;

          //background: url('../../../images/interface/green-check.svg'); see if we can use inline SVG
        }
      }
    }

    .wrapper {
      .container {
        display: flex;
        border: 1px solid var(--c_grey_200);
        border-right: 0;
        border-left: 0;

        input,
        .fk-select,
        .chosen-single {
          @include fontSize(16);
          background-color: transparent;
          line-height: 22px;
        }

        .fk-is-placeholder,
        .chosen-default {
          @include fontSize(18);
          line-height: 22px;
        }

        .fake-select-mice,
        .fake-input-mice {
          @include fontSize(16);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          cursor: pointer;
          width: 100%;
          height: 100%;
          padding: 43px 10px 0;
          white-space: nowrap;
        }

        .action {
          padding: 0;
        }

        .people {
          position: relative;

          &::after {
            position: absolute;
            left: -1px;
            bottom: -1px;
            content: '';
            display: none;
            width: 100%;
            height: 4px;
            border-width: 0;
            background: var(--c_carrot);
            background-image: unset;
          }

          &.parsley-error {
            &::before {
              display: block;
            }
          }
        }

        .people,
        .rooms {
          flex: 0 1 auto;
          padding: 8px 10px;

          // width: 30%;
          &::after {
            display: none;
          }
        }
      }
    }

    .budget,
    .rooms,
    .specialties {
      border-left: 1px solid var(--c_grey_200);
    }

    .budget {
      // width: 50%;
      .fk-select {
        letter-spacing: -0.5px;
      }

      .clear-all {
        margin-right: 6px;
      }

      .chosen-choices {
        > li::after {
          right: 15px;
        }
      }
    }

    .specialties {
      // width: 50%;
      .chosen-choices {
        > li::after {
          right: 15px;
        }
      }

      .chosen-container {
        &-active .chosen-drop {
          top: 20px;
          left: -1px;
          width: calc(100% + 22px);
        }
      }
    }

    .people {
      width: 17%;
    }

    .rooms {
      width: 24%;
    }

    .place-to-go-mice {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      select.chosen-select-mice{
        overflow: hidden;
      }

      .chosen-select {
        border: none;

        .destinations {
          position: relative;
          top: 5px;
        }
      }

      .chosen-container {
        padding: 0;

        &-active .chosen-drop {
          position: absolute;
          left: -1px;
          width: calc(100% + 22px);
          margin-top: 10px;
          margin-left: -10px;
          border: 1px solid var(--c_primary);
        }
      }

      .chosen-choices {
        > li.search-choice {
          padding-right: 38px;
          font-size: 16px;
        }
      }

      .chosen-results {
        li {
          padding-right: 38px;
        }

        .group-result {
          padding: 14px 0;
          margin: 6px 16px 0;
          border-top: 1px solid var(--c_grey_200);
          color: var(--c_dark_grey);
          letter-spacing: 1.5px;
        }

        .active-result {
          padding: 14px 16px;
        }
      }
    }

    .budget,
    .specialties,
    .place-to-go-mice {
      .t-fb-label {
        padding: 0;
      }

      .fk-select,
      .chosen-single {
        display: block;
        height: 22px;
        padding-left: 0;
        padding-right: 18px;
        overflow: hidden;
      }
    }

    label {
      margin-bottom: 0;
    }

    .chosen-container {
      padding-bottom: 0;

      .chosen-search {
        top: -20px;
        padding: 0 20px 0 0;
      }

      .chosen-results {
        li {
          &.active-result:not(.group-option) {
            padding-left: 10px;
            padding-right: 38px;

            &.highlighted {
              background: var(--c_cloud);
              color: var(--c_dark_grey);
            }
          }
        }
      }
    }

    select.has-fk-select {
      z-index: 1;
    }

    .overlay-block {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      display: none;
      width: 100%;
      height: 100%;
    }

    .action {
      .quote {
        display: none;
      }

      .btn-primary {
        width: 100%;
        height: 100%;
      }
    }

    .label-tablet {
      display: none;
    }

    .parsley-error {
      &::before {
        position: absolute;
        left: -1px;
        bottom: -1px;
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: var(--c_carrot);
      }
    }

    .place-to-go-mice .parsley-error {
      &::before {
        border-bottom-left-radius: 4px;
      }
    }

    .parsley-errors-list {
      display: none;
    }
  }
}
