@use 'destination_breadcrumb_commons' as *;
@use '../drop_down_links/drop_down_links';

@include tabletStyles {
  .destination-breadcrumb {
    .b-hotels-services-filter & {
      .sticky {
        top: 90px;
      }
    }
  }
}
