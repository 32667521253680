@use '../../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../../src/base_css/abstracts/abstracts';

.card.hotel {
  &:last-child {
    margin-bottom: 0;
  }

  .card-container {
    display: grid;
    grid-template-areas:
      'segment discounts discounts'
      'text    text      myibe    '
      'text    text      cta      ';
    grid-template-columns: max-content 1fr min-content;
    padding: 16px;

    .segment {
      position: relative;
      grid-area: segment;
    }

    .discounts {
      position: relative;
      display: flex;
      flex-flow: row-reverse wrap;
      grid-area: discounts;
      align-items: center;
      justify-content: flex-start;

      .offer-pill {
        @include icon(tag, before, 's') {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          color: var(--c_carrot);
        }
        cursor: pointer;
        margin-left: 8px;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    .myibe-discount {
      position: relative;
      display: flex;
      grid-area: myibe;
      align-items: flex-start;
      justify-content: flex-end;

      .offer-pill {
        @include icon(check, before, 's') {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-40%);
          display: flex;
          align-items: center;
          color: var(--c_green);
        }
        border: solid 1px var(--c_green);
        background: var(--c_green_secondary);
        color: var(--c_green);

        a.s-alert-info {
          display: flex;
          margin-left: 5px;
        }
      }
    }

    .text {
      position: relative;
      grid-area: text;
    }

    .call-to-action {
      position: relative;
      grid-area: cta;
      padding-left: 10px;
    }

    .overlay {
      display: none;
    }

    .badges-list {
      .badge {
        margin-bottom: 8px;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .offer-pill {
      position: relative;
      padding-left: 30px;

      &.not-icon {
        padding-left: 10px;

        &::before {
          display: none;
        }
      }
    }
  }

  .hint {
    display: block;
    color: var(--c_dark_grey);
  }

  .call-to-action {
    .accommodation {
      @include fontSize(12);

      @include icon(reference-restaurant, before, 's') {
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 5px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }

  .call-to-action:not(.grand) {
    padding: 0 0 0 6px;
  }

  .stars {
    position: relative;
    top: -2px;
    margin: 0;
  }

  .location {
    @include tripadvisor-cal;
    display: block;

    .hotel-name {
      margin-right: 4px;
    }
  }

  .features {
    li {
      margin-top: 5px;
      color: var(--c_dark_grey);
      font: 14px/14px var(--font-sans);

      span.s {
        color: var(--c_primary);
        font-size: 18px;
      }
    }
  }

  .opinions {
    display: inline-block;

    img {
      vertical-align: middle;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .pills-cnt {
    margin-bottom: 5px;
  }

  .badges-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;


  }

  .offer-pill {
    display: flex;
    padding: 5px 10px;
    margin-left: 0;
    margin-bottom: 8px;
    border: solid 1px var(--c_carrot);
    background: var(--c_carrot_secondary);
    color: var(--c_carrot);
    line-height: 14px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    &.s-kids-free {
      &::after {
        line-height: 1;
      }
    }
  }

  .swiper-button-prev {
    @include icon(arrow-left, before, 'xs') {
      position: absolute;
      top: 50%;
      right: 50%;
      margin-top: -7px;
      margin-right: -7px;
      color: var(--c_white);
    }
    left: 0;
    width: 30px;
    background: none transparent;
  }

  .swiper-button-next {
    @include icon(arrow-right, after, 'xs') {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7px;
      margin-left: -7px;
      color: var(--c_white);
    }
    right: 0;
    width: 30px;
    background: none transparent;
  }

  .overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: 0 9px 8px;
    text-align: right;

    li {
      display: inline-block;
    }

    .gallery,
    .gallery-expand {
      @include icon(expand);
      color: var(--c_white);
      font-size: 14px;
    }

    .gallery-expand {
      @include fontSize(26);
    }
  }

  &.horizontal-card {
    min-height: 145px;

    &.hidden {
      display: none;
    }

    &.comparator-card {
      min-height: 130px;

      .card-container {
        padding: 0;
      }

      .text {
        min-height: 118px;
        padding: 8px;
        margin-bottom: 0;
      }

      .location {
        margin: 5px 0;
      }

      .features {
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;

        li {
          flex: 0 0 auto;

          &:first-child {
            margin-right: 50px;
          }
        }
      }
    }
  }

  .footer {
    &__disclaimer {
      grid-area: 2 / 1 / 3 / 3;
      padding: 15px 15px 15px 30px;
      border-top: 1px solid var(--c_grey_200);
      background: var(--c_white);
    }
  }

  .disclaimer {
    &__hotel-card {
      display: flex;
      flex-flow: row nowrap;
      place-content: center flex-start;
      align-items: center;

      &.hidden {
        display: none;
      }
    }
  }
}

.card.hotel,
.card.hotel-light {
  .image-link,
  .hotel-image {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.card.hotel-light {
  .hotel-name,
  .tripadvisor-open-fancy {
    @include fontSize(12);

  }

  .price-before {
    .t-price,
    .t-coin {
      @include fontSize(12);
    }
  }

  .text {
    border-right: 0;
  }

  .line-through {
    color: var(--c_carrot);
  }

  .t-link-secondary {
    display: block;
  }
}
