@use 'mice_header_commons' as *;

@include desktopStyles {
  body {
    &:not(.inspiration-guide) {
      .mice-header {
        .language-selector {
          &,
          select {
            cursor: pointer;
          }
        }

        .language-selector {
          select,
          .language-selector__current {
            cursor: pointer;
          }

          a {
            color: var(--c_primary);
          }

          li {
            padding: 0;
          }

          .language-selector__current {
            @include icon(arrow-down, after, 'xs') {
              margin-top: 2px;
              margin-left: 8px;
              font-size: 12px;
            }

            @include icon(world, before, 's') {
              margin-right: 8px;
              vertical-align: bottom;
            }
            display: inline-block;
            color: var(--c_white);
            font-weight: bolder;
            font-size: 0.875rem;
            font-family: inter-regular, sans-serif;
            line-height: 20px;
          }
        }

        &.with-bg {
          .language-selector__current {
            color: var(--c_black_grey_700);
          }

          .main-nav li.has-children {
            color: var(--c_primary);
          }
        }

        .logo {
          min-width: 257px;

          .logo-mice-img {
            width: 100%;
          }
        }
      }
    }
  }
}

@include commonTabletDesktop {
  body {
    &:not(.inspiration-guide) {
      .mobile-mice-header {
        display: none;
      }
    }
  }

}
