@use 'sass:list';
@use '../abstracts/abstracts' as *;

// -------------------------------------
//   Spacing
// -------------------------------------

@mixin generate-spacing-helpers($spacing-sizes) {
  @for $i from 1 through list.length($spacing-sizes) {
    $size: list.nth($spacing-sizes, $i);

    // margins
    .m-#{$i} {
      margin: #{$size}px;
    }
    .mb-#{$i} {
      margin-bottom: #{$size}px;
    }
    .mt-#{$i} {
      margin-top: #{$size}px;
    }
    .mr-#{$i} {
      margin-right: #{$size}px;
    }
    .ml-#{$i} {
      margin-left: #{$size}px;
    }

    // paddings
    .p-#{$i} {
      padding: #{$size}px;
    }
    .pb-#{$i} {
      padding-bottom: #{$size}px;
    }
    .pt-#{$i} {
      padding-top: #{$size}px;
    }
    .pr-#{$i} {
      padding-right: #{$size}px;
    }
    .pl-#{$i} {
      padding-left: #{$size}px;
    }
  }
}

@include commonTabletDesktop {
  $spacing-sizes: $sp1 $sp2 $sp3 $sp4 $sp5 $sp6 $sp7;

  @include generate-spacing-helpers($spacing-sizes);
}

@include mobileStyles {
  $spacing-sizes: $sp1mob $sp2mob $sp3mob $sp4mob $sp5mob $sp6mob $sp7mob;

  @include generate-spacing-helpers($spacing-sizes);
}
