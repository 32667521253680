@use 'swiper/scss/scrollbar';

@at-root {
  :root {
    --swiper-scrollbar-border-radius: 5px;
    --swiper-scrollbar-top: auto;
    --swiper-scrollbar-bottom: 4px;
    --swiper-scrollbar-left: auto;
    --swiper-scrollbar-right: 4px;
    --swiper-scrollbar-sides-offset: 1%;
    --swiper-scrollbar-bg-color: var(--c_blue_green_100);
    --swiper-scrollbar-drag-bg-color: var(--c_primary);
    --swiper-scrollbar-size: 4px;
  }
}

.swiper-scrollbar{
  position: relative;
  inset: 0;

  &.swiper-scrollbar-horizontal{
    position: relative;
    inset: 0;
  }
}
