@use 'cards_commons' as *;
@use 'cards_hotels/cards_hotels';
@use 'cards_rooms/cards_rooms';
@use 'horizontal_card/horizontal_card';
@use '../../elements/badge/badge';
@use 'hotel_big_card/hotel_big_card';

@include tabletStyles {
  .card {
    .card-container {
      min-width: auto;
    }
  }

  .horizontal-card {
    .call-to-action {
      flex: 0 1 auto;

      &:not(.grand) {
        .price-cnt {
          display: inline-block;
        }
      }
    }

    &.comparator-card {
      &.hotel,
      &.offer {
        .card-container {
          flex-wrap: nowrap;
        }

        .text {
          flex: 0 1 100%;
        }
      }

      .badges-and-price {
        display: none;
      }
    }
  }
}

.ada {
  .horizontal-card.card.comparator-card.selected .add-to-comparator {
    background: var(--c_blue_green_100) !important;
    color: var(--c_primary) !important;
  }
}
