@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.b-discounts-tooltip {
  color: var(--c_dark_grey);
  text-align: left;

  div {
    white-space: normal;
  }

  .discounts-tooltip-title {
    margin-bottom: 20px;
    font-weight: 600;
  }

  .info-discount {
    @include icon(check, after, 'xs') {
      position: absolute;
      top: 6px;
      left: 0;
      color: #7BD124;
      line-height: 20px;
    }
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .info-title {
    margin-bottom: 5px;
  }


}
