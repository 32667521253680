@keyframes ring {
  0% {
    transform: rotateZ(0);
  }

  1% {
    transform: rotateZ(10deg);
  }

  3% {
    transform: rotateZ(-10deg);
  }

  5% {
    transform: rotateZ(10deg);
  }

  7% {
    transform: rotateZ(-10deg);
  }

  9% {
    transform: rotateZ(10deg);
  }

  11% {
    transform: rotateZ(-10deg);
  }

  13% {
    transform: rotateZ(10deg);
  }

  15% {
    transform: rotateZ(-10deg);
  }

  17% {
    transform: rotateZ(8deg);
  }

  19% {
    transform: rotateZ(-8deg);
  }

  21% {
    transform: rotateZ(8deg);
  }

  23% {
    transform: rotateZ(-8deg);
  }

  25% {
    transform: rotateZ(6deg);
  }

  27% {
    transform: rotateZ(-6deg);
  }

  29% {
    transform: rotateZ(4deg);
  }

  31% {
    transform: rotateZ(-2deg);
  }

  33% {
    transform: rotateZ(2deg);
  }

  35% {
    transform: rotateZ(-1deg);
  }

  37% {
    transform: rotateZ(1deg);
  }

  39% {
    transform: rotateZ(-1deg);
  }

  41% {
    transform: rotateZ(1deg);
  }

  43% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(0);
  }
}
