@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts.scss';

// "Inspiration guide and content site are the same thing. On the front end, we use the 'Inspiration Guide' class, while from the CMS, when we want to configure things, we will need to go to the 'Content Site' section."
.inspiration-guide {
  /** Generic **/
  --border-radius: var(--s_masterbrand_border_radius);
  --text-decoration: var(--s_masterbrand_text_decoration);
  --separator-color: var(--c_grey_200);

  /* Button Primary */
  --btn-background-primary: var(--c_masterbrand_blue_green_500);
  --btn-label-color-primary: var(--c_masterbrand_white);
  --btn-background-primary-hover: var(--c_masterbrand_blue_green_700);
  --btn-label-color-primary-hover: var(--c_masterbrand_white);

  /* Button Secondary */
  --btn-background-secondary: transparent;
  --btn-border-color-secondary: var(--c_masterbrand_blue_green_500);
  --btn-label-color-secondary: var(--c_masterbrand_blue_green_500);
  --btn-background-secondary-hover: var(--c_masterbrand_blue_green_500);
  --btn-label-color-secondary-hover: var(--c_masterbrand_white);

  /* Button Negative Primary */
  --btn-background-color-negative-primary: var(--c_masterbrand_white);
  --btn-label-color-negative-primary: var(--c_masterbrand_blue_700);
  --btn-background-color-negative-primary-hover: var(--c_masterbrand_grey_100);

  /* Button Negative Secondary */
  --btn-label-color-negative-secondary: var(--c_masterbrand_white);
  --btn-background-color-negative-secondary: transparent;
  --btn-label-color-negative-secondary-hover: var(--c_masterbrand_blue_700);
  --btn-background-color-negative-secondary-hover: var(--c_masterbrand_white);

  /* Button close */
  --button-close-color: var(--c_masterbrand_blue_green_500);

  /* Links */
  --color-link-primary: var(--c_masterbrand_blue_green_500);
  --color-link-primary-hover: var(--c_blue_700);

  /* Title  (font = font family) */
  --title-font: var(--f_noe);
  --color-title-font: var(--c_blue_700);
  --line-height: var(--f_masterbrand_line_height);
  --line-height_mobile: var(--f_line_height_title_mobile);
  --weight-title-font: var(--f_font_weight_600);
  --offset-line-height: 0px;
  --title-font-size-h2: var(--c_content_site_title_h2_font_size);
  --title-font-size-h1: var(--c_content_site_title_h1_font_size);

  /* Body font */
  --body-font: var(--f_inter);
  --color-body-font: var(--c_content_site_blue_800);
  --weight-body-font: var(--f_font_weight_100);

  // /* Article */
  // --article-font_size: var(--c_content_site_article_font_size);

  /* Background */
  --background-color: var(--c_masterbrand_white);
  --background-color-default: var(--c_content_site_white);

  /* Interactives Elements */
  --border-color: var(--c_masterbrand_grey_500);
  --interactive-text: var(--c_masterbrand_blue_green_500);
  --interactive-background: var(--c_masterbrand_white);
  --interactive-text-hover: var(--c_masterbrand_blue_green_800);
  --interactive-background-hover: var(--c_masterbrand_blue_green_200);
  --interactive-text-base: var(--c_grey_800);

  /* Swipper */
  --swiper-theme-color: var(--c_masterbrand_blue_green_500);
  --swiper-pagination-color: var(--c_masterbrand_blue_green_500);
  --swiper-pagination-border: 1px solid var(--c_masterbrand_blue_green_500, transparent);
  --swiper-pagination-bullet-inactive-color: var(--c_white, transparent);
  --swiper-navigation-color: var(--c_masterbrand_blue_green_500);
  --swiper-navigation-background-color: var(--c_white_opacity_60);

  // --swiper-navigation-background-color-hover: var(--c_masterbrand_blue_green_500);
  --swiper-navigation-border-color: var(--c_masterbrand_blue_green_500);
  --swiper-scrollbar-bg-color: var(--c_masterbrand_blue_green_200);
  --swiper-scrollbar-drag-bg-color: var(--c_masterbrand_blue_green_500);

  /* Badges */
  --tag-background-color: var(--c_masterbrand_blue_green_100);
  --tag-text-color: var(--c_masterbrand_blue_green_500);
  --tag-background-color-hover: var(--c_masterbrand_blue_green_500);
  --tag-text-color-hover: var(--c_masterbrand_blue_green_100);

  /* icons */
  --icon-color-default: var(--c_grey_600);
  --icon-color-theme: var(--c_masterbrand_blue_green_500);
  --icon-color-theme-hover: var(--c_masterbrand_blue_green_800);

  /* Newsletter */
  --newsletter-background-green: var(--c_blue_green_400);
  --newsletter-background-default: var(--c_blue_green_100);
  --newsletter-background-white: var(--c_white);
  --newsletter-color-title-default: var(--c_blue_700);
  --newsletter-color-text-default: var(--c_blue_700);
  --newsletter-color-text-error: var(--c_white);
  --newsletter-color-icon-default: var(--c_blue_700);
  --newsletter-color-title-white: var(--c_grey_600);
  --newsletter-color-text-white: var(--c_grey_600);
  --newsletter-color-icon-white: var(--c_grey_600);
  --newsletter-border-white: var(--c_grey_600);
}
