/* stylelint-disable no-descending-specificity */
@use '../../../base_css/abstracts/abstracts' as *;
@use 'swiper/scss/navigation';

:root {
  --swiper-navigation-color: var(--c_primary);
  --swiper-navigation-background-color: var(--c_white_opacity_60);
  --swiper-navigation-background-color-hover: var(--c_white);
  --swiper-navigation-size: 40px;
  --swiper-navigation-border-color: var(--c_primary);
}

.ib-swiper {
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 100;
    display: flex;
    width: auto;
    aspect-ratio: 1/1;
    border: 2px solid var(--swiper-navigation-border-color);
    border-radius: 100%;
    background-color: var(--swiper-navigation-background-color);

    &:hover {
      background-color: var(--swiper-navigation-background-color-hover);
      color: var(--swiper-navigation-border-color)
    }
  }

  &--template-coverflow:not(.is-fullscreen),
  &--template-scrollbar {
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      inset: 0;
      margin: 0;
      border: 2px solid var(--swiper-navigation-border-color);
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--swiper-navigation-color);
        color: var(--c_white);
      }
    }
  }

  &--template-external {
    .swiper-button-prev,
    .swiper-button-next {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
    }

    .swiper-button-prev {
      left: 10px;
    }

    .swiper-button-next {
      right: 10px;
    }
  }

  .swiper-button-prev {
    @include icon('arrow-left-fat', after, 'xs');
  }

  .swiper-button-next {
    @include icon('arrow-right-fat', after, 'xs');
  }
}

@include desktopStyles {
  .ib-swiper {
    &--template-fullscreen {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }

      &:hover .swiper-button-prev,
      &:hover .swiper-button-next {
        display: flex;
        transition: all 0.3s ease-in-out;
      }

      &.is-fullscreen {
        .swiper-button-prev,
        .swiper-button-next {
          display: flex;
        }
      }
    }
  }
}
