@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

body {
  &:not(.inspiration-guide) {
    .mobile-menu {
      display: none;
    }
  }
}

@mixin tablet-mobile-styles {
  body {
    &:not(.inspiration-guide) {
      .mobile-menu {
        display: flex;
        overflow-y: scroll;
        background: var(--c_grey_100);

        a {
          &:active::before,
          &:hover::before {
            display: none;
          }
        }

        .menu-item,
        .language,
        .currency {
          border-bottom: 1px solid var(--c_grey_200);
          background: var(--c_white);

          .link,
          .currency-link {
            display: block;
            padding: 15px 18px;
            color: var(--c_black_grey_700);
            font-weight: 600;
            line-height: 26px;
          }
        }

        > ul {
          width: 100%;
          height: 100%;
          overflow: hidden scroll;

          > li {
            width: 100%;
            margin-top: -1px;
            border-top: 1px solid #DDD;
            border-bottom: 1px solid #DDD;

            > a {
              @include fontSize(14);
              display: block;
              padding: 16px 20px;
              color: var(--c_primary);
              font-family: var(--font-sans);
              line-height: 17px;
              text-decoration: none;

              &.burger-search {
                position: relative;
                padding-right: 30px;

                &::after {
                  position: absolute;
                  top: 50%;
                  right: 18px;
                  transform: translateY(-50%);
                }
              }
            }

            &.toggle-btn {
              &.is-in-microsite {
                display: none;
              }

              .accessibility-button {
                cursor: pointer;
                display: flex;
                padding: 16px 20px;
                background: var(--c_blue_700);
                color: var(--c_white);

                .s-ico_a11y {
                  display: flex;
                  align-items: center;
                }

                .accessibility-button-text {
                  margin-left: 12px;
                  font-weight: 700;
                }
              }
            }

            &:first-child {
              border-bottom: 0 none;
            }

            &.user-menu {
              background-color: var(--c_blue_green_100);

              li {
                a {
                  display: block;
                  padding: 16px 20px;
                  border-top: 1px solid #DDD;
                  color: var(--c_primary);
                  font-size: 14px;
                  line-height: 18px;
                }

                &.menu_item .mx-offers {
                  @include icon(shopping, before, 's') {
                    display: inline-block;
                    margin-right: 10px;
                    color: var(--c_primary);
                  }
                }

                &.menu_item .mx-last-hotel {
                  @include icon(building, before, 's') {
                    display: inline-block;
                    margin-right: 10px;
                    color: var(--c_primary);
                  }
                }

                &.menu_item .mx-last-destination {
                  @include icon(honeymoon, before, 's') {
                    display: inline-block;
                    margin-right: 10px;
                    color: var(--c_primary);
                  }
                }
              }
            }

            &.dropdown {
              > a {
                @include icon(arrow-down-fat);
                position: relative;
                padding-right: 25px;

                &::after {
                  position: absolute;
                  top: 50%;
                  right: 18px;
                  margin-top: -3px;
                  color: var(--c_primary);
                  font-size: 10px;
                }
              }

              &.opened {
                background: #EFEFF8;

                > a {
                  @include icon(arrow-up-fat) {
                    color: var(--c_blue_700);
                  }
                  border: 0;
                  color: var(--c_blue_700);
                }
              }
            }

            &.header-mobile-menu {
              &.logout {
                .name-cnt {
                  @include icon(user_rounded, before, 'l') {
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    transform: translateY(-50%);
                    color: var(--c_primary);
                  }
                  padding-left: 63px;
                }
              }

              &.logged {
                .name-cnt {
                  align-items: center;
                  justify-content: flex-start;
                  padding-left: 20px;

                  .user-image {
                    @include fontSize(18);
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                  }

                  .name {
                    flex: 0 0 auto;
                    margin-left: 10px;
                  }
                }

                .user-image {
                  &.without-name {
                    .user-image-letter {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 32px;
                      height: 32px;
                      overflow: hidden;
                      border-radius: 50%;
                      background-color: var(--c_blue_700);

                      &::after {
                        color: var(--c_white);
                        font-size: 36px;
                      }
                    }
                  }
                }
              }

              .name-cnt {
                position: relative;
                display: flex;
                height: 90px;
                background: #EFF8F7;
                color: var(--c_primary);

                .name {
                  @include fontSize(14);
                  flex: 0 0 100%;
                  align-self: center;
                  line-height: 20px;
                }

                &.with-msg {
                  flex-wrap: wrap;
                  height: auto;
                  min-height: 90px;
                  padding: 16px 0 8px 45px;

                  &::before {
                    top: 15px;
                    left: 7px;
                    transform: translateY(0);
                  }

                  .name {
                    position: relative;
                    flex: 0 0 calc(100% - 14px);
                    padding-bottom: 5px;
                    border-bottom: 1px solid var(--c_blue_700);
                  }

                  .name-cnt-msg-title {
                    @include fontSansSemiBold;
                    @include fontSize(11);
                    flex: 0 0 calc(100% - 14px);
                    color: var(--c_blue_700);
                    line-height: 15px;
                  }

                  .name-cnt-msg-desc {
                    @include fontSansRegular;
                    @include fontSize(11);
                    flex: 0 0 calc(100% - 14px);
                    color: var(--c_blue_700);
                    line-height: 15px;
                  }
                }
              }
            }

            &.hide-on-tablet {
              display: none;
            }

            &.logout {
              border: 0;

              > a {
                padding: 16px 20px;
              }
            }

            &.best-price {
              border: 0;

              > a {
                @include fontSize(12);
                line-height: 14px;
                text-transform: uppercase;
              }
            }
          }
        }

        .dropdown-children {
          padding: 0 0 8px;
          margin-top: -8px;

          > li {
            > a {
              @include fontSize(14);
              padding: 10px 20px;
              color: var(--c_primary);
              line-height: 17px;
            }
          }
        }

        &::after {
          display: none;
        }

        .is-children {
          > li,
          > .li {
            a {
              @include fontSize(14);
              padding: 12px 20px;
              color: var(--c_primary);
              line-height: 17px;
              text-decoration: none;
            }

            > ul {
              padding-bottom: 6px;
              margin-top: -5px;

              li {
                border-bottom: 0;

                a {
                  @include icon(arrow-right-fat);
                  position: relative;
                  padding: 8px 25px 8px 9px;

                  &::after {
                    position: absolute;
                    top: 50%;
                    right: 9px;
                    margin-top: -3px;
                    color: var(--c_grey_200);
                    font-size: 10px;
                  }
                }
              }
            }

            &.has-children {
              > a {
                @include icon(arrow-right-fat);
                position: relative;
                padding-right: 25px;

                &.parent {
                  color: var(--c_primary);
                }

                &::after {
                  position: absolute;
                  top: 50%;
                  right: 18px;
                  margin-top: -3px;
                  color: var(--c_primary);
                  font-size: 10px;
                }
              }
            }

            &.top {
              a {
                @include fontSize(12);
                text-transform: uppercase;
              }
            }

            &.back {
              background-color: var(--c_blue_green_100);

              a {
                @include icon(arrow-left-fat, after, 'xs') {
                  position: absolute;
                  top: 50%;
                  left: 18px;
                  transform: translateY(-50%);
                  color: var(--c_primary);
                }
                position: relative;
                display: flex;
                align-items: center;
                height: 90px;
                padding: 0 35px;
                color: var(--c_primary);
                font-family: var(--font-sans);
              }
            }

            &.locale-option {
              border-bottom: 0;
            }
          }
        }

        .my-iberostar-menu {
          min-height: 100%;
          background: var(--c_white);

          .user-options {
            padding-top: 6px;
            padding-bottom: 6px;
            background-color: #EFEFF8;

            .menu_item {
              padding: 10px 20px;

              a {
                &::after {
                  content: none;
                }
              }
            }
          }

          .agency-logout {
            padding: 22px 20px;
          }

          &.is-children {
            li {
              > ul {
                margin-top: 0;

                li {
                  &:first-child {
                    padding-top: 6px;
                  }

                  &:last-child {
                    padding-bottom: 6px;
                  }

                  a {
                    padding: 12px 20px;

                    &::after {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .logout-li {
            background: var(--c_white);

            a {
              padding: 22px 20px;
            }
          }
        }
      }
    }

    &.iberostarpro-home {
      .mobile-menu {
        display: flex !important;
        padding-bottom: 125px !important;
        overflow-y: hidden !important;
      }

      .top-user-nav{
        li {
          padding: 0 !important;
          margin: 0 !important;
          border-top: none !important;
          border-bottom: none !important;

          a {
            padding: 0 !important;
            margin: 16px 0;
          }
        }

        .link-phone-list {
          display: flex;
          align-items:center;
          width: 100%;
          margin: 0;
        }

        .mobile-user {
          margin-top: 16px !important;
        }

      }
    }

    &.staragent-home {
      .mobile-menu {
        display: flex !important;
        padding-bottom: 125px !important;
        overflow-y: hidden !important;
      }

      .top-user-nav{
        li {
          padding: 0 !important;
          margin: 0 !important;
          border-top: none !important;
          border-bottom: none !important;

          a {
            padding: 0 !important;
            margin: 16px 0;
          }
        }

        .link-phone-list {
          display: flex;
          align-items:center;
          width: 100%;
          margin: 0;
        }

        .mobile-user {
          margin-top: 16px !important;
        }

      }
    }
  }

}

@include commonTabletMobile {
  @include tablet-mobile-styles;
}
