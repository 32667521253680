@use 'mobile_menu_commons' as *;

.mobile-menu {
  .hide-on-mobile {
    display: none !important;
  }

  > ul {
    > li {
      &.header-mobile-menu {
        .name-cnt {
          height: 65px;

          &.with-msg {
            height: auto;
            min-height: 65px;

            &::before {
              top: 11px;
            }
          }
        }
      }

      &.hide-on-tablet:not(.hidden) {
        display: block;
      }
    }
  }

  .is-children {
    li {
      &.back {
        a {
          height: 65px;
        }
      }
    }
  }
}

// Cabe la posibilidad de que un móvil girado pueda llegar a ser un small-desktop
// Es por ello que se debe incluir el mixin que hay en el commons para que si Akamai nos dice que esto es un
// Mobile, mantenga estos estilos si giramos y el dispositivo es muy grande de ancho
@include media(1600px) {
  @include tablet-mobile-styles;
}
