@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';
@use './modules/navigation';
@use './modules/pagination';
@use './modules/scrollbar';
@use 'swiper/scss';

@at-root {
  :root {
    --swiper-theme-color: var(--c_primary);
  }
}

.ib-swiper {
  .swiper-slide {
    width: fit-content;
    height: fit-content;

    img:not(.is-vertical), iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 16/9;
    }

  }

  &--template-fullscreen {
    .layer {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      background: rgba(var(--c_blue_700_rgb), 0.3);
      color: var(--c_white);
      text-align: center;
      text-decoration: none;
      transition: opacity 0.2s ease-in-out;

      &.swiper-fullscreen {
        @include icon(expand, before) {
          display: block;
          margin-bottom: 16px;
          font-size: 51px;
        }
        opacity: 0;
      }

      &.play-video,
      &.play-virtual-tour {
        @include icon('play-circle', before, 'xxl') {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    .swiper-exit-fullscreen {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 5;
      cursor: pointer;
      filter: drop-shadow(0 0 0.25rem var(--c_black_grey_700));
    }

    &.is-fullscreen {
      display: flex;
      height: 100%;

      .swiper-wrapper {
        height: fit-content;
        margin: auto;
      }
    }
  }

  &--template-coverflow {
    .swiper-slide {
      cursor: pointer;
      width: 85%;

      &:hover {
        .swiper-fullscreen {
          opacity: 1;
        }
      }
    }

    // Layer for hover efect
    .layer {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      background: rgba(var(--c_blue_700_rgb), 0.3);
      color: var(--c_white);
      text-align: center;
      text-decoration: none;
      transition: opacity 0.2s ease-in-out;

      &.swiper-fullscreen {
        @include icon(expand, before) {
          display: block;
          margin-bottom: 16px;
          font-size: 51px;
        }
        opacity: 0;
      }

      &.play-video,
      &.play-virtual-tour {
        @include icon('play-circle', before, 'xxl') {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    .swiper-exit-fullscreen {
      @include icon(collapse, after, 'l') {
        color: var(--c_white);
      }
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 5;
      cursor: pointer;
      filter: drop-shadow(0 0 0.25rem var(--c_black_grey_700));
    }

    &.is-fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999;
      width: 100%;
      max-width: 100%;
      height: 100%;

      .swiper-slide {
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        .layer.swiper-fullscreen {
          opacity: 0;
          display: none;
        }
      }
    }

    .swiper-navigation-wrapper{
      justify-content: flex-end;

      .swiper-pagination{
        width: fit-content;
      }
    }
  }

  &--template-scrollbar{
    .swiper-navigation-wrapper{
      flex-direction: row nowrap;

      .swiper-pagination{
        justify-content: flex-end;
        width: 100%;
      }

      .swiper-navigation{
        display: flex;
        gap: 10px;
      }

      &--no-pagination{
        display: flex;
        flex-flow: row nowrap;
        gap: 20px;

        .swiper-navigation{
          display: flex;
          gap: 10px;
        }
      }
    }
  }

  .swiper-navigation-wrapper{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
  }

}
