@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.cookies-content {
  .block-title {
    @include fontSansSemiBold;
    @include fontSize(12);
    margin-bottom: 23px;
    color: var(--c_black);
    font-style: normal;
    line-height: 16px;
    letter-spacing: 2.25px;
    text-transform: uppercase;
  }

  .desc {
    @include fontSansRegular;
    @include fontSize(16);
    margin-bottom: 24px;
    font-style: normal;
    line-height: 24px;
  }

  .collapsible-blocks {
    border-bottom: 1px solid var(--c_grey_200);

    .block-title {
      @include icon(arrow-down-fat) {
        line-height: normal;
      }

      @include fontSansSemiBold;
      @include fontSize(14);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 19px 0;
      margin-bottom: 0;
      border-top: 1px solid var(--c_grey_200);
      color: var(--c_blue_700);
      font-style: normal;
      line-height: 20px;
      letter-spacing: 1.8px;
      text-transform: uppercase;

      &.active {
        @include icon(arrow-up-fat) {
          line-height: normal;
        }
      }
    }

    .desc {
      display: none;
      overflow: hidden;

      table {
        margin: 15px 0;
        border: 1px solid var(--c_grey_200);

        tr {
          &:nth-child(odd) td {
            background: var(--c_background);
          }

          &:first-child td {
            background: var(--c_grey_200);
          }
        }

        td {
          padding: 0 1em;
          border-bottom: 1px solid var(--c_grey_200);
          vertical-align: top;
        }
      }
    }
  }

  .privacy_prompt_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 38px;

    &--links {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;

      .link-in-cookies-message {
        color: var(--c_blue_700);
        text-decoration: underline;

        &:first-child {
          margin-right: 10px;
        }
      }
    }

    &--buttons {
      .accept-all {
        background-color: var(--c_blue_700);

        &:focus,
        &:hover {
          border: 1px solid var(--c_blue_700);
          background-color: var(--c_blue_700);
          color: var(--c_blue_700);
        }
      }
    }

    .privacy_prompt_footer--buttons {
      .secondary-btn {
        border: 1px solid var(--c_blue_700);
        color: var(--c_blue_700);
      }

      .secondary-btn:hover {
        border: 1px solid var(--c_blue_700);
        background-color: var(--c_blue_700);
        color: var(--c_white);
      }

      .secondary-btn:focus {
        border: 1px solid var(--c_blue_700);
        background-color: var(--c_white);
        color: var(--c_blue_700);
      }

      .primary-btn {
        background-color:var(--c_blue_700) ;
        color: var(--c_white);
      }
    }
  }
}
