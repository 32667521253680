@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonTabletMobile {
  $margin-top: 40px;

  html:not(.mobile-panel-opened),
  body {
    overflow-x: hidden;
  }

  %burgermenu {
    @include bg-transition;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 0;
    backface-visibility: hidden;
    width: $menu-width;
    height: 100%;
    overflow: hidden;

    .mice-home & {
      height: 100%;
    }

    .inspiration-guide & {
      height: 100%;
    }

    .burgermenu-panel {
      @include bg-transition;
      position: absolute;
      top: 0;
      left: $menu-width;
      bottom: 0;
      z-index: 6;
      width: $menu-width;
      height: 100%;
      padding: 0;
      margin: 0;
      overflow: hidden;
      background: var(--c_white);
      list-style: none;
      -webkit-overflow-scrolling: touch;

      &.opened {
        z-index: 5;
        transform: translate3d(-$menu-width, 0, 0);
        background-color: #EFEFF8;
      }

      &:first-child {
        left: 0;
        z-index: 0;
        width: 100%;

        &.opened {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .dropdown {
      .dropdown-children {
        display: none;
      }
    }

    a {
      display: block;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }

  .burgermenu-left {
    @extend %burgermenu;
    left: 0;
    transform: translate3d(-$menu-width, 0, 0);
  }

  .burgermenu-right {
    @extend %burgermenu;
    right: 0;
    transform: translate3d($menu-width, 0, 0);
  }

  .page-wrapper {
    @include bg-transition;
    width: 100%;
    min-width: 320px;
    height: 100%;
    overflow: hidden;
  }

  #burgermenu-blocker {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99991;
    cursor: pointer;
    display: none;
    width: 100%;
    height: 100%;
  }

  .burgermenu-sticky-head {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    transform: translate3d(0, 0, 0);
    display: block;
    width: 100%;
    transition: transform 0.4s ease-out;

    &.apply-segment-border {
      &.nav-up {
        transform: translate3d(0, -69px, 0);
      }
    }

    &.nav-down {
      transform: translate3d(0, 0, 0);
    }

    &.nav-up {
      transform: translate3d(0, -65px, 0);
    }
  }

  .opened-left,
  .opened-right {
    &,
    body {
      position: fixed;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .page-wrapper {
      position: relative;
      margin-top: $margin-top;
    }

    %burgermenu {
      z-index: 1;
    }

    #burgermenu-blocker {
      display: block;
    }
  }

  .opened-left {
    #burgermenu-blocker,
    .page-wrapper,
    .burgermenu-sticky-head {
      transform: translate3d($menu-width, 0, 0);
    }

    .burgermenu-left {
      transform: translate3d(0, 0, 0);
    }

    .page-wrapper {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9000;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(58, 58, 58, 60%);
      }
    }
  }

  .opened-right {
    #burgermenu-blocker,
    .page-wrapper,
    .burgermenu-sticky-head {
      transform: translate3d(-$menu-width, 0, 0);
    }

    .burgermenu-right {
      transform: translate3d(0, 0, 0);
    }

    .page-wrapper {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(58, 58, 58, 60%);
      }
    }
  }

  /* Estilos especiales para el menú de experiencias/mice */
  .opened-left {
    .mice-home {
      #burgermenu-blocker,
      .page-wrapper,
      .burgermenu-sticky-head {
        transform: translate3d($menu-width-mice, 0, 0);
      }

      .burgermenu-left {
        width: $menu-width-mice;
      }
    }
  }

  .opened-left {
    .inspiration-guide {
      #burgermenu-blocker,
      .page-wrapper,
      .burgermenu-sticky-head {
        transform: translate3d(0%, 0, 0)
      }

      .page-wrapper {
        top:0;
        display: inline;

        &::after {
          // position: absolute;
          top: 0;
          left: 0;
          content: '';
          display: none;
          width: 100%;
          height: 100%;
          background: rgba(58, 58, 58, 60%);
        }
      }

      .burgermenu-left {
        width: $menu-width;
      }
    }
  }

  .opened-left {
    .iberostarpro-home {
      #burgermenu-blocker,
      .page-wrapper,
      .burgermenu-sticky-head {
        transform: translate3d(0%, 0, 0)
      }

      .page-wrapper {
        top:0;
        display: inline;

        &::after {
          // position: absolute;
          top: 0;
          left: 0;
          content: '';
          display: none;
          width: 100%;
          height: 100%;
          background: rgba(58, 58, 58, 60%);
        }
      }

      .burgermenu-left {
        width: $menu-width;
      }
    }
  }

}
