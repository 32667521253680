@use '../abstracts/variables';

.txt-sh {
  text-shadow: 1px 1px 10px var(--c_black), 1px 1px 10px var(--c_dark_grey);
}

.ckeditor {
  ul {
    padding-left: 10px;
    margin-top: 10px;

    li {
      margin-bottom: 10px;
      color: var(--c_dark_grey);

      &::before {
        position: relative;
        top: -2px;
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 10px;
        background: var(--c_grey_200);
      }
    }
  }
}
