@use 'header_commons' as *;
@use '../../vendors/burgermenu/burgermenu';
@use '../../shared/user_login/user_login';
@use '../../elements/user_image/user_image';
@use '../../shared/ihg_button/ihg_button';
@use '../../shared/ihg_tooltips/ihg_tooltip';
@use '../../shared/ihg_modal/ihg_modal';
@use '../../shared/accessibility_button/accessibility_button';
@use '../../vue/main_nav/panel_destinations';
@use '../../vue/hotel_header_lite/hotel_header_lite';

@include desktopStyles {
  body:not(.transparent-header) .wrapper-main-header.ibp {
    background-color: white;
  }

  .main-header {
    .wrapper-main-header {
      max-width: 1366px;
      padding: 0;
    }

    body.ada & {
      #logo-iberostar-white {
        display: none !important;
      }
    }

    .auth0-login {
      display: flex;
      align-items: center;
    }

    .logo,
    .logo-horizons {
      position: relative;
      display: flex;
      flex: 0 0 200px;
      align-items: center;
      justify-content: center;
    }

    &.ibercuba {
      .logo {
        &:not(.logo--partner) {
          padding: 0 16px 5px;
          background: var(--c_primary);
        }
      }
    }

    .logo {
      &:not(.logo--partner) {
        padding: 0 16px 5px;
      }
    }

    #logo-iberostar-white:not(.hidden) {
      display: inline;
    }

    #logo-iberostar,
    .logo-green {
      display: none;

      body.ada & {
        display: block !important;
      }
    }

    .for-user-nav {
      .user-notifications {
        display: none;
      }
    }

    .hotel-one-landing-page &.initial,
    .transparent-header &.initial {
      background: linear-gradient(to bottom, var(--c_black), transparent);

      .logo-green {
        display: none;
      }

      .logo {
        img {
          width: 204px;
        }

        .ibhotels {
          img {
            width: 225px;
          }
        }
      }
    }
  }
}

@media (width >= 1025px) and (max-width: $small-desktop) {
  .main-header {
    .wrapper-main-header {
      max-width: 1240px;
      padding-right: 20px;
    }

    .logo {
      flex: 0 0 200px;
      padding: 0 14px;
    }
  }
}

@include commonTabletDesktop {
  body {
    &.inspiration-guide {
      .main-header {
        position: relative;
        z-index: 4;
      }

      .b-home-header {
        .main-header {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 10;
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
      }
    }
  }

  .main-header {
    position: relative;
    z-index: 8051;
    opacity: 1;

    .wrapper-main-header {
      display: flex;
      flex: 0 0 100%;
      justify-content: space-between;
      margin: 0 auto;
      border-bottom: 1px solid var(--c_primary);

      &.focused-menu {
        position: relative;
        z-index: 8500;
      }
    }

    .logo-staragent-home-white {
      img {
        width: 88px;
        padding: 10px;
      }
    }

    .logo {
      position: relative;

      img {
        width: 180px;
      }
    }

    .main-header-content {
      margin: 0 20px;
    }

    .mobile-menu-toggle {
      display: none;
    }

    .for-main-nav {
      padding: 0;

      &#vue-main-nav {
        display: flex;
        align-items: flex-end;

        // Rule to avoid CLS while the menu is loaded
        min-height: 55px;
      }

      .main-nav {
        max-width: 992px;
        margin-left: auto;

        .user-image {
          &.without-name {
            .user-image-letter {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              overflow: hidden;
              border-radius: 50%;
              background-color: var(--c_blue_700);

              &::after {
                color: var(--c_white);
                font-size: 28px;
              }
            }
          }
        }

        .bottom-main-nav {
          display: flex;
          justify-content: flex-end;

          > li:not(.user-notifications) {
            position: relative;
            flex: 0 0 auto;
            padding: 10px 14px;
            margin: 0;

            &::before {
              position: absolute;
              left: 0;
              bottom: -10px;
              right: 0;
              content: '';
              height: 1px;
              transition: transform 0.4s;
            }

            &.is-displayed {
              position: relative;
              padding: 9px 14px 10px;
              border: 1px solid var(--c_primary);
              border-bottom: 0;
            }

            &[data-menu-name='my-iberostar'] {
              padding: 10px;

              &.js-user-options-dropdown-link {
                &.is-displayed:not(.without-notifications) {
                  padding: 9px 9px 10px 10px;
                  border-left: 0;
                }

                &.is-displayed {
                  padding: 9px 9px 10px;
                }
              }

              &.is-displayed:not(.js-user-options-dropdown-link) {
                padding: 10px;
                border: 0;

                &::before {
                  width: calc(100% - 15px);
                }

                > a {
                  @include icon(arrow-up-fat, after) {
                    display: none;
                  }
                }
              }
            }

            a:not(.full-link) {
              &::before {
                display: none;
              }
            }

            > a {
              @include link-base;
              display: flex;
              align-items: center;
              height: 24px;
              color: var(--c_dark_grey);

              &:hover {
                color: var(--c_primary);

                .person-icon {
                  color: var(--c_primary);
                }
              }
            }

            &.is-displayed {
              > a {
                @include icon(arrow-up-fat, after) {
                  color: var(--c_primary);
                }
                color: var(--c_primary);

                .person-icon {
                  color: var(--c_primary);
                }
              }
            }

            &.dropdown-link {
              > a {
                @include icon(arrow-down-fat, after, 'xs') {
                  margin-top: 0;
                  margin-left: 8px;
                  font-weight: bold;
                }
                cursor: pointer;
              }

              &.is-displayed {
                > a {
                  @include icon(arrow-up-fat, after) {
                    color: var(--c_primary);
                  }
                }
              }
            }

            &.has-children {
              > div {
                position: absolute;
                left: 0;
                bottom: 1px;
                z-index: 70;
                transform: translateY(100%);
                width: 100%;
                background-color: #EFEFF8;

                &:not(.js-user-menu-remote) {
                  display: none;
                }
              }

              .wrapper {
                > span {
                  @include fontSize(14);
                  display: block;
                  color: var(--c_dark_grey);
                  font-weight: var(--sans-semi-bold);
                  letter-spacing: 1.8px;
                  text-transform: uppercase;
                }
              }

              .wrapper:not(.user-options-wrapper, .b-last-searches-cards) {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }

            &.current {
              a {
                color: var(--c_primary);
              }
            }
          }

          .main-nav-submenu {
            padding: 24px 20px;

            li {
              display: inline-block;
              margin: 0 24px;
            }
          }
        }
      }

      .user-options-link-wrapper {
        position: relative;
      }

      .user-notifications {
        position: relative;
        display: none;
        padding: 7px 10px;

        &.is-displayed {
          padding: 6px 10px 10px 9px;
          border-top: 1px solid var(--c_primary);
          border-left: 1px solid var(--c_primary);

          &::before {
            position: absolute;
            left: 0;
            bottom: -1px;
            right: 0;
            content: '';
            height: 2px;
            background: var(--c_white);
          }
        }

        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          content: '';
          width: 1px;
          height: 16px;
          background-color: var(--c_grey_200);
        }
      }
    }

    .for-user-nav {
      padding: 10px 0;

      .user-nav {
        position: relative;
        display: flex;
        justify-content: flex-end;

        > ul {
          position: relative;
          flex: 0 0 auto;

          .drop-down-selector {
            font-weight: var(--sans-bold);

            .drop-down-icon {
              @include icon(arrow-down, after, 'xs') {
                margin-top: 2px;
                margin-left: 8px;
                color: var(--c_black_grey_700);
                font-weight: bold;
                font-size: 12px;
              }
              cursor: pointer;
            }

            .drop-down-link {
              position: absolute;
              top: 125%;
              left: 0;
              z-index: 9000;
              display: none;
              max-height: 150px;
              border-radius: 2px;
              box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 8%);
              background: var(--c_white);

              &.active {
                display: block;
              }

              .drop-down-item {
                position: relative;
                z-index: 1;
                cursor: pointer;
                display: block;
                padding: 12px;
                color: var(--c_black_grey_700);
                font-weight: 400;
                font-size: 14px;
                font-size: calc((12 / var(--font-base)) * 1rem);
                line-height: 18px;
                white-space: nowrap;

                &:hover {
                  background-color: var(--c_blue_green_100);
                }

                a {
                  &::before {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .drop-down-links {
          box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 8%);

          .active {
            text-decoration: underline;
          }
        }

        a,
        .fk-select,
        .language-selector__current {
          @include fontSize(12);
          color: var(--c_black_grey_700);
          line-height: 18px;

          &.fb-button,
          &.google-button {
            color: var(--c_white);
          }
        }

        a {
          &:hover {
            color: var(--c_primary);
          }
        }

        > ul {
          &:not(.top-user-nav) {
            padding-left: 10px;
          }

          &.top-user-nav {
            display: flex;
            align-items: center;

            > li {
              position: relative;
              padding: 0 16px;

              &::after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                content: '';
                width: 1px;
                height: 24px;
                background: var(--c_grey_200);
              }

              &.toggle-btn {
                padding-right: 20px;
              }
            }

            > li.user-notifications {
              display: none;
            }
          }
        }
      }

      .exit-partner {
        @include icon(ico_exit, before, 's') {
          margin-right: 8px;
          color: var(--c_primary);
          vertical-align: bottom;
        }
      }

      .link-phone-list {
        @include icon(phone, before, 's') {
          margin-right: 8px;
          color: var(--c_black_grey_700);
          vertical-align: bottom;
        }

        &:hover {
          &::before,
          a {
            color: var(--c_primary);
          }
        }
      }

      .language-selector {
        .language-selector__current {
          @include icon(world, before, 's') {
            margin-right: 8px;
            vertical-align: bottom;
          }
        }
      }

      .currency-selector {
        .fk-select {
          @include icon(EUR, before, 's') {
            margin-right: 8px;
            vertical-align: bottom;
          }

          &.currency-EUR {
            @include icon(EUR, before);
          }

          &.currency-USD {
            @include icon(USD, before);
          }

          &.currency-GBP {
            @include icon(GBP, before);
          }

          &.currency-CHF {
            @include icon(CHF, before);
          }

          &.currency-RUB {
            @include icon(RUB, before);
          }

          &.currency-DKK {
            @include icon(DKK, before);
          }

          &.currency-SEK {
            @include icon(SEK, before);
          }

          &.currency-INR {
            @include icon(INR, before);
          }

          &.currency-JPY {
            @include icon(JPY, before);
          }

          &.currency-BRL {
            @include icon(BRL, before);
          }

          &.currency-ARS {
            @include icon(USD, before);
          }

          &.currency-MXN {
            @include icon(USD, before);
          }

          &.currency-CLP {
            @include icon(USD, before);
          }

          &.currency-CAD {
            @include icon(CAD, before);
          }
        }
      }

      .language-selector,
      .currency-selector,
      .more-links {
        select,
        .language-selector__current {
          cursor: pointer;
        }

        > a,
        .fk-select,
        .language-selector__current {
          @include icon(arrow-down, after, 'xs') {
            margin-top: 2px;
            margin-left: 8px;
            color: var(--c_black_grey_700);
            font-size: 12px;
          }
          display: inline-block;
          color: var(--c_dark_grey);
        }
      }
    }

    &.hidden {
      display: none !important;
    }

    .transparent-header &.initial {
      background: linear-gradient(to bottom, var(--c_black), transparent);

      .wrapper-main-header {
        border: 0;

        &.focused-menu {
          background: transparent;
        }
      }

      .for-main-nav {
        .main-nav .bottom-main-nav {
          padding-top: 10px;

          .user-options-link-wrapper .person-icon {
            color: var(--c_white);
          }

          > li {
            &:not(.user-notifications),
            &:not(.is-displayed) {
              &::before {
                background: transparent;
              }
            }

            &:not(.user-notifications) {
              a {
                color: var(--c_white);
              }

              &.is-displayed {
                border: 0;

                > a::after {
                  color: var(--c_white);
                }
              }
            }

            &.is-displayed,
            &.menu_item:hover {
              &::before {
                animation: show-dropdown-items forwards;
                animation-duration: 0.5s;
                animation-iteration-count: 1;
              }
            }
          }
        }

        .user-notifications.is-displayed {
          border: 0;

          &::before {
            display: none;
          }
        }
      }

      .for-user-nav .language-selector > a::after,
      .for-user-nav .language-selector .language-selector__current::after,
      .for-user-nav .currency-selector > a::after,
      .for-user-nav .currency-selector .fk-select::after,
      .for-user-nav .more-links > a::after,
      .for-user-nav .more-links .fk-select::after {
        color: var(--c_white);
      }

      .for-user-nav {
        width: 100%;
        height: 36px;
        padding: 0;
        margin-top: 20px;

        .more-links .fk-select {
          color: var(--c_white);

          &::after,
          &::before {
            color: var(--c_white);
          }
        }

        .language-selector > a::after {
          color: var(--c_white);
        }

        .user-nav a:not([data-locale], .notification-link),
        .user-nav .fk-select,
        .link-phone-list::before,
        .language-selector .language-selector__current::before,
        .language-selector .language-selector__current,
        .more-links .fk-select::after,
        .currency-selector .fk-select,
        .currency-selector .fk-select::after,
        .currency-selector .fk-select::before {
          color: var(--c_white);
        }

        .user-nav > ul.top-user-nav > li {
          &.toggle-btn .accessibility-button {
            background: var(--c_white);
            color: var(--c_blue_700);
          }

          &::after {
            display: none;
          }
        }
      }

      .logo:not(.logo--partner) {
        background: transparent;
      }

      .s-ico-bell {
        color: var(--c_white);
      }
    }

    .hotel-one-landing-page &.nav-up-after-initial,
    .transparent-header &.nav-up-after-initial {
      #logo-green {
        display: block;
      }

      #logo-iberostar-white {
        display: none;
      }
    }
  }
}

@include tabletStyles {
  .main-header {
    z-index: 61;

    &.burgermenu-sticky-head {
      z-index: 10000;
      transform: none;
      background: var(--c_white);
    }

    .logo,
    .main-header-content {
      display: inline-block;
      vertical-align: middle;
    }

    .logo {
      margin: 16px 0;
    }

    .logo {
      .logo-iberostar-white,
      .logo-white {
        display: none;
      }

      img {
        width: 180px;
      }
    }

    #logo-iberostar:not(.hidden) {
      display: inline;
    }

    .for-main-nav {
      .main-nav {
        display: none;

        .bottom-main-nav {
          flex-wrap: wrap;
          justify-content: flex-end;

          > li {
            padding: 0 5px;
            margin: 0 10px;

            &:last-child {
              padding-right: 0;
              margin-right: 0;

              &.current {
                &::after {
                  width: calc(100% - 12px);
                }
              }
            }

            &:first-child {
              &.current {
                &::after {
                  width: calc(100% - 12px);
                }
              }
            }

            &:lang(ru) {
              margin-left: 10px;
            }

            &:not(.not-hide-on-tablet) {
              display: none;
            }

            a {
              font-size: 14px;
            }
          }
        }
      }
    }

    .transparent-header &.initial {
      .logo-iberostar-white,
      .logo-white {
        display: block;
      }
    }

    .hotel-one-landing-page &,
    .transparent-header & {
      .logo-green {
        display: block;
      }

      &.initial {
        .logo-green {
          display: none;
        }

        .logo-iberostar-white,
        .logo-white {
          display: block;
        }
      }
    }

    .mobile-menu-toggle {
      top: 10px;
    }
  }
}

@include commonTabletMobile {
  .main-header {
    position: relative;
    top: 0;
    z-index: 10000;
    width: 100%;

    .wrapper {
      padding: 0;
    }

    .main-header-content {
      width: calc(100% - 177px - 5px - 20px - 130px - 40px);

      .opened-left & {
        visibility: hidden;
      }
    }

    .mobile-menu-toggle {
      display: inline-flex;
      min-height: 60px;
    }

    .top-user-nav {
      > li:not(.dropdown-link, .not-hide-on-tablet, .user-notifications) {
        display: none;
      }
    }

    .hotel-one-landing-page &,
    .transparent-header & {
      &.initial {
        position: absolute;

        .mobile-menu-toggle,
        .mobile-menu-toggle span {
          color: var(--c_white);
        }

        .mobile-menu-toggle::before,
        .mobile-menu-toggle::after {
          background: var(--c_white);
        }

        .for-main-nav .main-nav .bottom-main-nav > li:not(.user-notifications) a {
          color: var(--c_primary);
        }
      }

      &.nav-down,
      &.nav-up {
        background: var(--c_white);
      }
    }
  }
}
