@use 'sass:map';
@use '../abstracts/abstracts' as *;

@include commonStyles {
  @each $segment in $segments {
    .seg-#{$segment} {
      @if $segment != grand {
        *.apply-segment,
        &.apply-segment {
          background: map.get($colors_secondary, $segment) url('../../images/patterns/#{$segment}.svg') map.get($params, $segment);
        }

        *.apply-square-segment,
        &.apply-square-segment {
          background: url('../../images/patterns/pattern_#{$segment}_301x301.svg') no-repeat center /cover;
        }

        *.apply-rectangle-segment,
        &.apply-rectangle-segment {
          background: url('../../images/patterns/pattern_#{$segment}_602x301.svg') no-repeat center /cover;
        }

        *.apply-segment-rectangle-2,
        &.apply-segment-rectangle-2 {
          background: url('../../images/patterns/pattern_#{$segment}_392x190.svg') no-repeat center /cover;
        }

        *.apply-segment-color,
        &.apply-segment-color {
          background: rgba(map.get($colors, $segment), 0.8);
        }

        *.apply-segment-color-full-opacity,
        &.apply-segment-color-full-opacity {
          background: map.get($colors, $segment);
        }

        *.apply-segment-color-secondary,
        &.apply-segment-color-secondary {
          background: map.get($colors_secondary, $segment);
        }
      }

      .apply-segment-base-color {
        color: map.get($colors, $segment);
      }
    }

    // For hotel cards
    .apply-segment-color-#{$segment} {
      color: map.get($colors, $segment);
    }
  }

  @each $segment in $segments {
    .seg-#{$segment} {
      @if $segment != grand {
        // For customer opinions but looks handy for more situations!
        *.apply-segment-hover,
        &.apply-segment-hover {
          cursor: pointer;

          &:hover,
          &:active {
            background: map.get($colors_secondary, $segment) url('../../images/patterns/#{$segment}.svg') map.get($params, $segment);
          }
        }
      }

      // For landing typekit header
      *.apply-segment-gradient,
      &.apply-segment-gradient {
        @include segment-gradient(270deg, map.get($colors_secondary, $segment), 75%, 10%);
      }
    }
  }

  // -------------------------------------
  //   Segments
  // -------------------------------------

  // Default values
  .apply-segment-color {
    background: rgba(var(--c_ocean_rgb), 0.8);
  }

  .apply-segment-color-grand {
    color: var(--c_grand);
  }

  // Default values
  .apply-segment-color-secondary {
    background: var(--c_ocean_secondary);
  }
}
