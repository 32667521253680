@use '../../../base_css/abstracts/abstracts' as *;
@use '../../../shared/ihg_button/reb/ihg_button';

.reb-header {
  $self: &;

  @keyframes slideTopToBottom {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }
  position: relative;
  top: 0;
  z-index: 20;
  background-color: var(--c_white);

  &.sticky {
    position: sticky;
    animation: slideTopToBottom 0.5s ease-out forwards;
  }

  &__content {
    box-shadow: 0 12px 17px 3px rgba(0, 0, 0, 10%);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 28px 16px;
    margin: 0 auto;

    .logo {
      z-index: 20;
      display: flex;

      .logo-white,
      .logo-iberostar-white {
        display: none;
      }

      img {
        display: block;
        width: 204px;
        height: auto;
      }
    }
  }

  &__fake {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto $space3;

    .input-fake {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 500px;
      height: 51px;
      padding: 0 $space3;
      overflow: hidden;
      border: 1px solid var(--c_grey_200);
      white-space: nowrap;
      text-overflow: ellipsis;

      & > span {
        display: inline-block;
        height: 22px;
        margin: 0 0.7rem;
        border-left: 1px solid var(--c_grey_200);
      }
    }
  }

  &__fake-fastbooking-button{
    padding: 15px 24px 16px;
  }

  &__hotel-name {
    @include fontSize(18);
    @include titleHighlighted;
    display: none;
    flex-grow: 1;
    color: var(--c_black_grey_700);

    b {
      @include titleHighlighted;
    }

    .stars {
      display: inline-block;
      margin-left: 4px;
      vertical-align: top;

      .rating-stars::before {
        color: inherit;
      }
    }
  }

  &__hotel-nav {
    .internal-nav {
      opacity: 1;
      padding: 0;
      background-color: transparent;
      transition: opacity 0.2s ease-in-out;

      &__hidden {
        opacity: 0;
        pointer-events: none;
      }

      .menu_item {
        margin: auto 2px;

        .fake-link {
          @include fontSize(12);

          // !! Needed to avoid style overwriting
          padding: 4px 8px !important;
          color: var(--interactive-text-base);

          &:hover {
            @include interactiveElement;
          }
        }
      }
    }
  }

  &__button {
    display: none;

    .btn-floating-fastbooking {
      width: var(--fastbooking-submit-button-width);
      height: var(--fastbooking-submit-button-height);
      background-color: var(--fastbooking-submit-background);
      color: var(--fastbooking-submit-label-color);

      &:hover:not(.disabled, :disabled) {
        background-color: var(--fastbooking-submit-background-hover);
        color: var(--fastbooking-submit-label-color-hover);
      }
    }

    .stays-per {
      display: none;
    }

    .price,
    .coin {
      @include fontSize(14);
      font-weight: 700;
      line-height: 20px;
    }
  }

  &__nav-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 2px;
  }

  &__nav-item {
    @include fontSize(12);
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    margin-top: 2px;
    color: var(--c_dark_grey);
    font-weight: 600;

    &.mobile-call-center {
      @include icon(phone, before) {
        @include fontSize(20);
        margin-right: 8px;
        color: var(--c_dark_grey);
        vertical-align: bottom;
      }
    }

    &.menu {
      display: flex;
      align-items: center;
      width: 32px;
      height: 32px;
      margin-top: 0;

      .menu-hamburger {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        border-bottom: 2px solid var(--c_black);
      }

      .menu-hamburger::after,
      .menu-hamburger::before {
        position: relative;
        top: 0;
        content: '';
        display: block;
        flex: 0 0 auto;
        width: 32px;
        height: 2px;
        border-radius: 2px;
        background: var(--c_black);
        transition: transform 0.2s;
      }

      .menu-hamburger::before {
        transform: translateY(-7px);
      }

      .menu-hamburger::after {
        transform: translateY(13px);
      }

      // Change icon CLOSE
      &.close {
        .menu-hamburger {
          border-bottom-color: transparent;

          &::before {
            transform: translateY(4px) rotate(-40deg);
          }

          &::after {
            transform: translateY(2px) rotate(40deg);
          }
        }
      }
    }
  }

  &__panel {
    position: absolute;
    top: 0;
    width: 100%;
    padding-bottom: 30px;
    margin-top: 4px;
    box-shadow: 0 2px 10px #0000001F;
    background-color: var(--c_white);

    .place-to-go,
    .check-in-container,
    .second-level .people,
    .second-level .discounts {
      margin-left: -1px;
      border: 1px solid var(--c_grey_200);

      &.focused {
        z-index: 1;
        border: 1px solid var(--c_primary);
      }
    }

    .place-to-go {
      border-left: 1px solid var(--c_grey_200);
    }

    .check-in-container {
      position: relative;
      height: 100%;
      margin-right: -1px;

      .check-in.sticky-fb.long-text {
        padding-top: initial;
      }
    }

    .dates.focused .check-in-container {
      z-index: 1;
      border-color: var(--c_primary) !important;
    }

    .chosen-container .chosen-results {
      top: -1px;
      left: -1px;
    }

    .fb-datepicker.date-picker-wrapper.no-topbar {
      margin-left: 0;
    }
  }

  &__panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space2 $space3;

    &.spacer {
      width: 50px;
    }

    .close-panel {
      z-index: 99;
      cursor: pointer;
      color: var(--c_black);
    }

    @include media($tablet) {
      .logo {
        position: relative;
        top: 3px;
      }
    }
  }

  &__panel-body {
    padding-top: 24px;

    .fastbooking {
      .e-fastbooking-filter-tabs,
      .items-nav {
        display: none;
      }

      & > .wrapper {
        padding: 0;
      }
    }

    .wrapper .container {
      box-shadow: none;
    }
  }

  &__tabs {
    .fb-tabs {
      height: 33px;

      &__tab {
        padding: 5px 30px;
        font-weight: 600;
        font-size: 11px;
        letter-spacing: 1.375px;
        text-transform: uppercase;

        &--active {
          height: 100%;
          border: 1px solid var(--c_primary);
        }

        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
  }

  &.sticky,
  &.fb-always-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99990; // positioning above desktop/tablet header menu always
    background: var(--c_white);

    &:not(#{ $self }--hotel) #{ $self }__content {
      #{ $self }__wrapper {
        #{ $self }__fake {
          display: flex;
        }
      }
    }

    &#{ $self }--hotel {
      background-color: var(--c_grey_100);

      #{ $self }__wrapper {
        justify-content: flex-end;
        max-width: 1200px;

        // !! Not happy putting 6px here but client wants it
        padding: 6px 16px;
      }

      .logo {
        display: none;
      }

      #{ $self }__nav-bar {
        display: none;
      }

      #{ $self }__hotel-nav {
        .internal-nav {
          .menu_item {
            .fake-link {
              // !! Needed to avoid style overwriting
              padding: 4px 8px !important;
            }

            &.active {
              @include interactiveElement;

              span {
                color: var(--interactive-text-hover);
              }
            }
          }
        }
      }
      #{ $self }__hotel-name {
        display: block;
      }

      #{ $self }__button {
        display: block;
      }
    }
  }
}

@include tabletStyles {
  .reb-header {
    $self: &;

    &__wrapper {
      display: grid;
      grid-gap: 16px;
      grid-template-areas:
        'logo   buttons'
        'center center';
      grid-template-columns: 1fr 1fr;
    }

    .logo {
      grid-area: logo;
    }

    &__fake {
      grid-area: center;
      margin: 0;

      .input-fake {
        flex-grow: 1;
        max-width: 100%;
      }
    }

    #{ $self }__hotel-nav {
      grid-area: center;
      overflow-x: scroll;

      .internal-nav {
        padding: 0;

        .menu_item .fake-link {
          padding: 4px 8px !important;
        }

        .internal-nav-list {
          justify-content: space-between;
        }
      }
    }

    &__nav-bar {
      grid-area: buttons;
    }

    &__button {
      grid-area: buttons;
      text-align: right;
    }

    &.sticky,
    &.fb-always-sticky {
      &#{ $self }__wrapper {
        justify-content: start;
      }
    }
  }
}
