@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.hotel-big-card {
  .text-container {
    text-align: center;
  }

  .text {
    background: var(--c_white);
  }

  .t-label,
  .t-p {
    color: var(--color-body-font);
  }

  .t-h2 {
    display: block;
    margin-bottom: 8px;
    color: var(--c_dark_grey);
  }

  .call-to-action {
    flex: 0 0 auto;
    align-self: flex-end;
    text-align: right;
    text-align: center;

    .price-cnt {
      display: inline-block;
    }

    .highlight {
      display: inline-block;
    }

    .call-to-action {
      flex: 0 0 auto;
      align-self: flex-end;
      text-align: right;
      text-align: center;

      .price-cnt {
        display: inline-block;
      }

      .highlight {
        display: inline-block;
      }

      > div {
        clear: right;
        white-space: nowrap;

        &.hint-cnt {
          @include fontSansSemiBold;
          @include fontSize(12);
          margin-bottom: 10px;
          line-height: 18px;
          white-space: normal;
          text-transform: capitalize;
        }
      }

      .prev-highlight,
      .highlight {
        text-align: right;
      }

      .prev-type {
        @include fontSansSemiBold;
        @include fontSize(12);
        margin-bottom: 10px;
        line-height: 18px;
        white-space: normal;
        text-transform: capitalize;
      }
    }

    .prev-highlight,
    .highlight {
      text-align: right;
    }

    .prev-type {
      @include fontSansSemiBold;
      @include fontSize(12);
      color: var(--c_carrot);
      line-height: 18px;
    }

    .prev-highlight {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;

      &::after {
        position: absolute;
        left: 0;
        bottom: 10px;
        content: '';
        width: 100%;
        height: 1px;
        background: var(--c_carrot);
      }

      .t-price,
      .t-coin {
        @include fontSansSemiBold;
        @include fontSize(12);
        color: var(--c_carrot);
        line-height: 18px;
      }
    }

    .type {
      @include fontSansSemiBold;
      @include fontSize(14);
      display: inline-block;
      color: var(--c_blue_700);
      line-height: 19px;
      letter-spacing: 1.8px;
      text-transform: uppercase;
    }

    .t-price,
    .t-coin {
      color: var(--c_blue_700);
    }
  }

  .hint-cnt {
    @include fontSansSemiBold;
    @include fontSize(12);
    line-height: 18px;
    text-align: center;
  }
}
